import React, { useState } from "react";
import Card from "../../../../components/Card";
import { Edit } from "lucide-react";
import { Button, Modal, Select } from "antd";
import { useGetSubjectsQuery } from "../../../../hooks/api/Admin/Courses/useGetSubjectsQuery";
import { useUpdateSubjectExpertise } from "../../../../hooks/api/Tutors/UpdateProfile/useUpdateSubjectExpertise";

const Options = (data: any) => {
  const [payload, setPayload] = useState({
    areaOfExpertise: [...data?.data?.areaOfExpertise],
  });

  const page = 1;
  const { data: courses } = useGetSubjectsQuery({ page });
  const { mutateAsync, isLoading } = useUpdateSubjectExpertise();

  const [availabilityModal, setAvailabilityModal] = useState(false);

  const { Option } = Select;

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setPayload({ ...payload, [name]: value });
  };

  return (
    <div>
      {data?.data?.areaOfExpertise && (
        <Card width="12" style={{ marginTop: "10px" }}>
          <div className="flex justify-between items-center mb-3">
            <h1 className="text-xl W2STMedium text-gray700 ">
              Subject Expertise
            </h1>
            <button
              className="text-primary600 flex gap-2 items-center cursor-pointer"
              onClick={() => {
                setAvailabilityModal(true);
              }}
            >
              <Edit size={20} /> Edit
            </button>
          </div>
          <div>
            <span className="blob capitalize text-gray700">
              <ul className="list-disc pl-5">
                {data?.data?.areaOfExpertise &&
                  data?.data?.areaOfExpertise.map(
                    (subject: any, index: any) => (
                      <li key={index} className="text-gray-700 mb-2">
                        {subject}
                      </li>
                    )
                  )}
              </ul>
            </span>
          </div>
        </Card>
      )}
      {data?.data?.preferredLanguage && (
        <Card width="12" style={{ marginTop: "10px" }}>
          <div className="flex justify-between items-center">
            <h1 className="text-xl W2STMedium text-gray700 mb-3">Language</h1>
          </div>
          <div>
            <span className="blob">
              <span className="text-gray700">
                {data?.data?.preferredLanguage}
              </span>
            </span>
          </div>
        </Card>
      )}

      <Modal
        width="400px"
        footer={null}
        open={availabilityModal}
        onCancel={() => setAvailabilityModal(false)}
        title="Edit Subject Expertise"
      >
        <div className="mt-4">
          <p className="">Select area of expertise</p>
          <Select
            mode="multiple"
            maxCount={4}
            onChange={(value) =>
              handleChange({ target: { name: "areaOfExpertise", value } })
            }
            value={payload?.areaOfExpertise || undefined}
            placeholder="Select Option"
            className="w-full mt-2 capitalize"
            size="large"
          >
            <Option value="" disabled>
              Select option
            </Option>
            {courses?.data?.docs?.map(({ subject }: { subject: string }) => (
              <Option key={subject} value={subject} className="capitalize">
                {subject}
              </Option>
            ))}
          </Select>
          <p className="mt-3 text-gray500">Select maximum of 4</p>
          <Button
            type="primary"
            className="mt-8 w-full"
            size="large"
            loading={isLoading}
            disabled={!availabilityModal}
            onClick={() => {
              mutateAsync(payload, {
                onSuccess: () => {
                  setAvailabilityModal(false);
                },
              });
            }}
          >
            Continue
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Options;
