import React, { useEffect, useState } from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { useGetTutorsonAdminQuery } from "../../../hooks/api/Admin/Tutors/useGetTutorsonAdminQuery";
import { useNavigate } from "react-router-dom";
import { Checkbox, Table } from "antd";
import Moment from "react-moment";
import { Dot } from "lucide-react";

const AdminTutor = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const { data: tutors, isLoading } = useGetTutorsonAdminQuery({
    search,
    isDeleted,
    isAccountVerified,
    isEmailVerified,
    page,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setSearch(searchTerm);
    }, 3000);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Email Verified",
      dataIndex: "isEmailVerified",
      key: "isEmailVerified",
      render: (isEmailVerified: boolean) =>
        isEmailVerified ? (
          <span className="inline-flex items-center space-x-1 text-[#027A48] p-1 text-[12px] rounded-md bg-[#ECFDF3]">
            <Dot size={20} className="" />
            <span className="">Yes</span>
          </span>
        ) : (
          <span className="inline-flex items-center space-x-1 text-[#B42318] p-1 text-[12px] rounded-md bg-[#FEF3F2]">
            <Dot size={20} className="" />
            <span className="">No</span>
          </span>
        ),
    },
    {
      title: "Account Verified",
      dataIndex: "isAccountVerified",
      key: "isAccountVerified",
      render: (isAccountVerified: boolean) =>
        isAccountVerified ? (
          <span className="inline-flex items-center space-x-1 text-[#027A48] p-1 text-[12px] rounded-md bg-[#ECFDF3]">
            <Dot size={20} className="" />
            <span className="">Verified</span>
          </span>
        ) : (
          <span className="inline-flex items-center space-x-1 text-[#B42318] p-1 text-[12px] rounded-md bg-[#FEF3F2]">
            <Dot size={20} className="" />
            <span className="">Unverified</span>
          </span>
        ),
    },
    {
      title: "Joined",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => (
        <>
          <Moment format="DD/MM/YYYY">{createdAt}</Moment> -{" "}
          <Moment format="h:mm a">{createdAt}</Moment>
          <br />{" "}
          <Moment fromNow className="text-gray-500">
            {createdAt}
          </Moment>
        </>
      ),
    },
    {
      title: "Area of Expertise",
      dataIndex: "areaOfExpertise",
      key: "areaOfExpertise",
      render: (areas: string[]) =>
        Array.isArray(areas) ? areas.join(", ") : "N/A",
    },
  ];

  const handleRowClick = (record: any) => {
    navigate(`/admin/tutors/${record.id}`);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: tutors?.data?.meta?.totalDocs || 0,
    current: tutors?.data?.meta?.page || 1,
    pageSize: tutors?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };

  return (
    <AdminDashboardLayout
      right={
        <input
          type="text"
          className="py-2 px-4 bg-gray-200 border-1 rounded-md"
          placeholder="Search Tutor"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      }
    >
      <h3 className="W2STBold text-[20px]">
        Tutors ({tutors?.data?.meta?.totalDocs || 0})
      </h3>

      <div className="flex justify-end py-4 gap-4">
        <div className="flex items-center gap-1">
          <Checkbox
            value={isDeleted}
            onChange={() => {
              setIsDeleted(!isDeleted);
            }}
          />
          <span>Is Deleted</span>
        </div>
        <div className="flex items-center gap-1">
          <Checkbox
            value={isAccountVerified}
            onChange={() => {
              setIsAccountVerified(!isAccountVerified);
            }}
          />
          <span>Verified Account</span>
        </div>
        <div className="flex items-center gap-1">
          <Checkbox
            value={isEmailVerified}
            onChange={() => {
              setIsEmailVerified(!isEmailVerified);
            }}
          />
          <span>Verified Email</span>
        </div>
        <input
          name="search"
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e?.target?.value)}
          className={"p-[10px] rounded-md border-[1px] border-[#00000033]"}
        />
        {/* <RangePicker
          showTime
          onCalendarChange={(dates) => {
            if (dates && dates[0] && dates[1]) {
              const startDate = dates[0].toISOString();
              const endDate = dates[1].toISOString();
              setTiming({ startDate, endDate });
            }
          }}
          disabledDate={(current) => {
            return current && current > moment().endOf("day");
          }}
        /> */}
      </div>

      <Table
        dataSource={tutors?.data?.docs}
        columns={columns}
        rowKey="id"
        loading={isLoading}
        className="cursor-pointer"
        scroll={{ y: "60vh" }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={paginationConfig}
      />
    </AdminDashboardLayout>
  );
};

export default AdminTutor;
