import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetBalanceQuery = () => {
  const token = localStorage.getItem("W2ST_ADMIN_TOKEN");

  return useQuery({
    queryKey: ["useGetBalanceQuery"],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");

      const response = await axiosInstance.get(`misc/stripe-balance`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onSuccess: (data) => {},
    onError: (error: unknown) => {},
    enabled: !!token,
  });
};
