import Layout from "../Components/Layout";
import TutorTestimonials from "../Components/TutorTestimonials";
import TutorFAQ from "../Components/TutorFAQ";
import Button from "../Components/Button";
import { Link } from "react-router-dom";
import { Calendar, DollarSign, Key, Map } from "lucide-react";

const About = () => {
  const LargeBanner = require("../../../assets/images/large_joint.webp");

  const data = [
    {
      id: 1,
      icon: <Map />,
      title: "Simplified Process to Hire Private Tutors",
      desc: "Get access to top tutors and subject specialists near you  Our extensive database helps you find an educator that addresses your academic needs.",
    },
    {
      id: 2,
      icon: <Calendar />,
      title: "Advanced Digital Classrooms",
      desc: "From school tests, exams, SATs, ACTs to college admissions, receive on-demand educational support from professionals.",
    },
    {
      id: 3,
      icon: <Key />,
      title: "Learn Anywhere, Anytime.",
      desc: "Our customized tutoring services allow you to create lesson goals and arrange flexible tutoring hours. Learn Anywhere, Anytime.",
    },
    {
      id: 4,
      icon: <DollarSign />,
      title: "Personalized Tuition Plans",
      desc: "We screen test tutors and verify qualifications beforehand. We also monitor and regulate communication to ensure that nothing goes amiss.",
    },
  ];

  const faq = [
    {
      id: 1,
      header: "What Is Way 2 Success Tutors?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos velit aliquam explicabo molestiae adipisci repellat, excepturi deserunt cum quae, veritatis cumque incidunt quam laboriosam, rerum in esse veniam fuga exercitationem",
    },
    {
      id: 2,
      header: "What Qualifications Should Tutors Have?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos velit aliquam explicabo molestiae adipisci repellat, excepturi deserunt cum quae, veritatis cumque incidunt quam laboriosam, rerum in esse veniam fuga exercitationem",
    },
    {
      id: 3,
      header: "How to Sign up for Your Tutoring Service?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos velit aliquam explicabo molestiae adipisci repellat, excepturi deserunt cum quae, veritatis cumque incidunt quam laboriosam, rerum in esse veniam fuga exercitationem",
    },
    {
      id: 4,
      header: "How to Create a Tutor Profile?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos velit aliquam explicabo molestiae adipisci repellat, excepturi deserunt cum quae, veritatis cumque incidunt quam laboriosam, rerum in esse veniam fuga exercitationem",
    },
    {
      id: 5,
      header: "Do Tutors Need Any Training for Online Tutoring Jobs?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos velit aliquam explicabo molestiae adipisci repellat, excepturi deserunt cum quae, veritatis cumque incidunt quam laboriosam, rerum in esse veniam fuga exercitationem",
    },
  ];

  return (
    <Layout>
      <section className="pt-[63px] md:pt-[126px] md:pb-[102px] pb-[52px] px-10">
        <p className="text-center text-sm uppercase text-gray500 mb-[24px]">
          Welcome to WAY2SUCCESSTUTORS
        </p>
        <h3 className="text-dsm md:text-dlg text-gray700 mb-[24px] W2STMedium text-center">
          Facilitating the connection of
          <br className="md:block hidden" /> parents and students with{" "}
          <br className="md:block hidden" />
          highly qualified educators.
        </h3>
        <center className="text-gray500 max-w-[90%] md:max-w-[50%] mx-auto text-tmd md:text-txl">
          We believe that every child deserves access to educational tools and
          resources. They also have the right to seek assistance whenever they
          face learning obstacles. We collaborate with like-minded educators
          eager to teach learners regardless of race, gender, and religious
          affiliations.
        </center>
        <img
          src={LargeBanner}
          alt=""
          className="md:mb-[54px] mb-[27px] mx-auto mt-[52px] md:mt-[102px] w-[90%]"
        />

        {/* <div className="container mx-auto grid md:grid-cols-3 grid-cols-1 gap-4">
          {testimonials.map(({ id, name, school, content }) => (
            <div
              key={id}
              className="p-4 rounded-md bg-white border-primary300 border-[1px]"
            >
              <div className="md:text-6xl text-3xl text-gray700">
                <BsQuote />
              </div>
              <div className="md:mt-4 W2STThin text-gray700 md:mb-10 md:text-2xl text-lg">
                {content}
              </div>
              <div className="flex mt-3 gap-3">
                <Image
                  src={Avatar}
                  alt="avatar"
                  style={{ height: '40px', width: '40px' }}
                />
                <div>
                  <div className="W2STMedium text-gray900">{name}</div>
                  <span className="text-gray700">Student - {school}</span>
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </section>
      <section>
        <div className="container mx-auto">
          <div className="block md:flex gap-[90px]">
            <div className="w-full md:w-5/12">
              {/* <Image src={TutorImage} alt="testimonial" className="w-full" /> */}
              <p className="text-gray500 text-tlg">WHY CHOOSE US?</p>
              <h3 className="text-gray700 text-txs md:text-dmd W2STMedium pt-[17px] md:pb-[49px] pb-[25px]">
                Elevating the Tutoring Experience One Lesson at a Time
              </h3>
              <div className="text-gray500 w-11/12 md:w-10/12 md:text-txl text-tmd md:mb-0 mb-10 ">
                Education is the stepping stone to a prosperous and better
                society. We pave the way for better academic and professional
                opportunities by assisting learners everywhere. That way,
                everyone has a fair chance of building a better future for
                themselves and their community.
              </div>
            </div>
            <div className="md:w-7/12 w-full">
              <div className="grid grid-cols-2 gap-4">
                {data.map(({ title, desc, id, icon }) => (
                  <div key={id} className="mb-[32px]">
                    <div className="rounded-full p-3 border-purple-200 border-[1px] inline-block text-purple-700 W2STMedium">
                      {icon}
                    </div>
                    <h3 className="W2STBold text-gray700 my-[16px] text-tlg">
                      {title}
                    </h3>
                    <p className="text-gray500 text-tsm w-10/12">{desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <TutorTestimonials />
      <TutorFAQ />
      <div className="container mx-auto my-[120px] embed-background">
        <p className="text-center text-gray200 W2STRegular text-lg mt-[64px] mb-[32px]">
          Contact us today to get started!!
        </p>
        <p className="text-center W2STBold text-white text-dlg mb-[32px]">
          Your quest for compatible
          <br />
          tutors ends here.
        </p>
        <div className="text-center mb-[64px]">
          <Link
            to="/student/signin"
            className="bg-dark text-white py-5 px-6 rounded-md tlg"
          >
            <Button title="Get a Tutor" />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default About;
