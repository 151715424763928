import React, { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const StudentFAQ = () => {
  const Model = require("../../../assets/images/model.webp");

  const [active, setActive] = useState("search");

  const searchFaq = [
    {
      id: 1,
      header: "What Is Way 2 Success Tutors?",
      answer: (
        <div>
          Way 2 Success Tutors is an online platform that gives students (and
          parents) complete access to the best tutors. Our tutoring service
          bridges the communication between students and tutors. It also makes
          it easier for parents (and students) to find subject specialists from
          a single source.
          <div className="mt-3 W2STBold">
            Top 3 Reasons to find the right tutor from our platform:
          </div>
          <div> - Qualified educators within your reach.</div>
          <div> - Flexible schedules and customized lesson plans.</div>
          <div> - Safety and security are guaranteed.</div>
        </div>
      ),
    },
    {
      id: 2,
      header: "What Subjects & Grades Do You Teach?",
      answer:
        "We offer a wide variety of tutoring services starting from elementary school to college-level courses. We also provide college test preparation classes for high school graduates.",
    },
    {
      id: 3,
      header: "How to Sign up for Your Tutoring Service?",
      answer: (
        <div>
          <div>1. Select the Sign Up button.</div>
          <div>2. Fill the online registration form.</div>
          <div>
            3. Complete the registration process via a password authentication
            process.
          </div>
          <div>4. Create a student profile.</div>
        </div>
      ),
    },
    {
      id: 4,
      header: "How Are Tutors Verified?",
      answer: (
        <div>
          <div>
            We have developed a three-step verification process to ensure the
            safety and privacy of our learners.
          </div>
          <div>
            1. We shortlist accepting applications based on qualification,
            professional experience, and background check.
          </div>
          <div>
            2. We take personal interview and ask for referrals to learn more
            about their teaching style.
          </div>
          <div>
            3. Students (or parents) can request a demo session to assess
            tutors. That way, you get more clarity on whether or not a specific
            tutor will work for you.
          </div>
        </div>
      ),
    },
    {
      id: 5,
      header: "What to Expect from Each Session? ",
      answer: (
        <div>
          Our qualified tutors create lesson plans based on student needs and
          coursework. They can explain basic concepts and review the topics
          already covered in class through different activities. You also have
          the option to consult them for homework help and proofreading whenever
          required.
        </div>
      ),
    },
  ];

  const processFaq = [
    {
      id: 6,
      header: "How to Schedule a Tutoring Session? ",
      answer: (
        <div>
          Sign up for a student account and then browse through our tutor
          profiles. Once you find a compatible match, you can set an
          appointment. After the initial meet up, you can schedule a
          weekly/monthly tutoring plan customized according to individual
          requirements.
        </div>
      ),
    },
    {
      id: 7,
      header: "How Do I Prepare for these Tutoring Sessions?",
      answer: (
        <div>
          Learners should actively participate in each lesson by asking
          questions for clarity and taking lecture notes. Don’t hesitate to
          follow-up on previous classes or request tutors to review old topics.{" "}
          <br />
          Online students should follow the same steps, making the most of the
          advanced digital classroom. They should ask questions and engage in
          assigned activities. Additionally, virtual learners must have a strong
          internet connection, webcam, speaker/headphones, and microphone. These
          tools encourage engagement and enhance the learning experience.
        </div>
      ),
    },
    {
      id: 8,
      header: "How to Reschedule a Class?",
      answer: (
        <div>
          We value your time and understand that other priorities can get in the
          way of tutoring sessions. If something comes up, you may request the
          tutor to reschedule the next session.
          <br />
          However, parents/students are advised to send their requests 24 hours
          in advance as cancellation cannot happen 2 hours before a tutoring
          session. That way, the tutor can get notified in time.
        </div>
      ),
    },
    {
      id: 9,
      header: "What If I Don’t Like the Tutor? ",
      answer: (
        <div>
          Sometimes it takes time to find the right tutor.
          <br />
          We appreciate constructive feedback from students and parents. Don’t
          hesitate to share your concerns and problems with us. We can assign a
          new tutor for you or send a list of substitutes for the remaining
          sessions.
        </div>
      ),
    },
    {
      id: 10,
      header: "What Payment Methods Do You Accept? ",
      answer: (
        <div>
          You can pay for our tutoring services via credit card, debit card, or
          Paypal. The different payment methods provide convenience and security
          as the transactions occur through proper channels.
          <br />
          We do not accept cash and advise you to address all financial
          transactions to Way 2 Success Tutors.
          <br /> Notify the customer support team if a tutor asks for payment in
          cash or wants direct checks.
          <div>
            <span className="W2STBold">Disclaimer: </span>
            <i>
              Way2SucceessTutors would not be responsible for any transaction(s)
              that happens outside out platform
            </i>
          </div>
        </div>
      ),
    },
  ];

  const activetab =
    "flex-1 text-center text-primary700 bg-primary50 rounded-full p-3";
  const inActiveTab =
    "flex-1 text-center bg-white rounded-full text-gray900 p-3";

  return (
    <section className="py-10" id="faq">
      <div className="text-center text-sm uppercase text-gray500 md:mt-[141px] mt-[50px] mb-[12px] md:mb-[24px]">
        FREQUENT QUESTIONS
      </div>
      <center className="text-dsm md:text-dlg text-gray700 W2STMedium mb-[12px] md:mb-[24px]">
        You have questions and we
        <br className="hidden md:block" /> have answers
      </center>
      <div className="text-gray500 text-tmd md:text-txl text-center mb-[40px] md:mb-[80px] px-5">
        Experience the ease of connecting with highly qualified{" "}
        <br className="md:block hidden" />
        educators for both parents and students.
      </div>
      <div className="container mx-auto mt-4">
        <div className="md:flex gap-[40px]">
          <div className="md:w-6/12 w-full">
            <div className="continer mx-auto md:w-[450px] w-full bg-white rounded-full border-[1px] border-primary200 p-1">
              <span className="flex">
                <span
                  className={`transition ease-in-out duration-300 cursor-pointer ${
                    active === "search" ? activetab : inActiveTab
                  }`}
                  onClick={() => setActive("search")}
                >
                  Search
                </span>
                <span
                  className={`transition ease-in-out duration-300 cursor-pointer ${
                    active === "process" ? activetab : inActiveTab
                  }`}
                  onClick={() => setActive("process")}
                >
                  Our Process
                </span>
              </span>
            </div>
            <div className="mt-10 md:container mx-aut0 px-0">
              {active === "search" ? (
                <Accordion>
                  {searchFaq.map(({ id, header, answer }) => (
                    <AccordionItem key={id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="text-gray700">{answer}</div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              ) : (
                <Accordion>
                  {processFaq.map(({ id, header, answer }) => (
                    <AccordionItem key={id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="text-gray700">{answer}</div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </div>
          </div>
          <div className="w-6/12">
            <img
              src={Model}
              alt=""
              className="md:block hidden mx-auto h-[500px] object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudentFAQ;
