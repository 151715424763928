/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";

import Layout from "../Components/Layout";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import StudentTestimonials from "../Components/StudentTestimonials";
import StudentFAQ from "../Components/StudentFAQ";
import {
  BookOpen,
  Calendar,
  Check,
  Clipboard,
  Map,
  Shield,
  Star,
  Zap,
} from "lucide-react";

const Home = () => {
  const [active, setActive] = useState("online");
  const Home = require("../../../assets/images/mobileheroimage.webp");
  const Home2 = require("../../../assets/images/largeheroimage.webp");
  const TutorCard = require("../../../assets/images/tutorcard1.webp");
  const TutorCard2 = require("../../../assets/images/tutorcard2.webp");
  const TestAvatar = require("../../../assets/images/avatar.webp");
  const Largebanner = require("../../../assets/images/largebanner.webp");
  const Online = require("../../../assets/images/online.webp");
  const Offline = require("../../../assets/images/offline.webp");
  const Joint = require("../../../assets/images/joint.webp");
  const ParentAndChild = require("../../../assets/images/parents.png");

  const data = [
    {
      id: 1,
      icon: <Star />,
      title: "Find the Right Tutor",
      desc: "Get access to top tutors and subject specialists near you Our extensive database helps you find an educator that addresses your academic needs.",
    },
    {
      id: 2,
      icon: <Map />,
      title: "Learn Anywhere, Anytime.",
      desc: "Our customized tutoring services allow you to create lesson goals and arrange flexible tutoring hours. Learn Anywhere, Anytime.",
    },
    {
      id: 3,
      icon: <Zap />,
      title: "Seek Academic Advice",
      desc: "From school tests, exams, SATs, ACTs to college admissions, receive on-demand educational support from professionals.",
    },
    {
      id: 4,
      icon: <Shield />,
      title: "Secure Learning Experience",
      desc: "We screen test tutors and verify qualifications beforehand. We also monitor and regulate communication to ensure that nothing goes amiss.",
    },
  ];

  const more = [
    {
      id: 1,
      icon: <Clipboard />,
      title: "Homework help",
      body: "Our tutors can help with everything from math problems, reading, comprehension and other academic areas. Schedule daily/weekly tutoring sessions to provide them with extra help.",
    },
    {
      id: 2,
      icon: <Calendar />,
      title: "Set a Schedule for a Class",
      body: "Does your child struggle with some science concepts? Do they need better grades to qualify for their sports team? Contact subject specialists to improve their class performance and report cards.",
    },
    {
      id: 3,
      icon: <BookOpen />,
      title: "Start learning",
      body: "Exam time means crunch time for many students. Our tutors are willing and ready to assist. Prepare for ACT and SAT exams, along with reviewing their dissertations and college application essays.",
    },
  ];

  const steps = [
    {
      id: 1,
      title: "Register to Our Tutoring Service",
      desc: "Fill our online registration form to kick-start the application process. We need some basic information about your interests, education and professional experience.",
    },
    {
      id: 2,
      title: "Build Your Tutoring Profile",
      desc: "We take great pride in providing a diverse range of tutoring jobs for every grade-level and subject. Create your profile by selecting the subjects you’d like to teach and a tentative tutoring schedule.",
    },
    {
      id: 3,
      title: "Start Teaching",
      desc: "We provide interactive teaching tools and a well-designed online classroom for online classes. Take advantage of these features to elevate your lessons.",
    },
    {
      id: 4,
      desc: "On the whole, our quick and easy process makes tutoring immensely gratifying. Once you get through these steps, you can generate a steady supply of tutoring income.",
      title: "Finally",
    },
  ];

  const studeentSteps = [
    {
      id: "first",
      title: "Choose the Best Tutors",
      desc: "We shortlist top-rated tutors that fit your profile. Use our platform to connect with them and learn more about their teaching style.",
    },
    {
      id: "next",
      title: "Set a Schedule for a Class",
      desc: "Unlock the power of our online platform, where we source the finest tutors across all age groups and academic levels.",
    },
    {
      id: "then",
      title: "Start learning",
      desc: "Enjoy your sessions, Either online or in-person. You can also set the time and date of each session according to your availability",
    },
  ];

  const activetab =
    "flex-1 text-center text-primary700 bg-primary50 rounded-full p-3";
  const inActiveTab =
    "flex-1 text-center bg-white rounded-full text-gray900 p-3";

  return (
    <Layout>
      {/* <section className="container mx-auto py-20">
        <Calendar />
      </section> */}
      <section className="container mx-auto py-20">
        <center className="text-black text-tlg md:text-txs md:mt-[30px]">
          Let’s Find You An Expert Tutor!!
        </center>
        <p className="text-gray700 md:text-dxl text-dmd text-center mt-3 W2STMedium">
          We connect students
          <br className="md:block hidden" /> with professional tutors.
        </p>
        <div className="block md:flex mx-auto justify-center gap-4 mb-[20px] md:mb-[94px] mt-10">
          <Link
            to="/tutor/signup"
            target="_black"
            className="
              bg-primary700 text-white py-4 px-6 rounded-md cursor-pointer border-[1px] border-primary700 text-tsm md:text-tlg block md:inline mb-3 text-center md:text-left"
          >
            Become a Tutor
          </Link>
          <Link
            to="/student/signup"
            target="_black"
            className="
              bg-white text-primary700 py-4 px-6 rounded-md cursor-pointer border-[1px] border-primary700 text-tsm md:text-tlg block md:inline mb-3 text-center"
          >
            Join as a Student
          </Link>
        </div>
        <img src={Home} alt="" className="mt-[35px] block md:hidden" />
        <img src={Home2} alt="" className=" hidden md:block" />
      </section>
      <section className="container mx-auto py-20">
        <div className="block md:flex items-center md:gap-[5vw]">
          <div className="flex-1  text-black">
            <h3 className="md:text-dmd text-txs text-gray700 W2STMedium w-10/12">
              Trusted source for
              <br className="hidden md:block" /> tutoring services
            </h3>
            <p className="text-gray500 my-[24px] text-txl md:w-10/12 w-full">
              We offer private online and in-person sessions for individuals
              that prefer one-on-one lessons.
            </p>
            <div className="flex gap-2">
              <div className="flex-1 md:block hidden">
                <img src={TutorCard2} alt="testimonials" className="w-full" />
              </div>

              <div>
                <div className="text-gray900 italic W2STMedium w-full md:w-8/12">
                  "W2ST tutor's approach helped me tackle tough concepts,
                  boosting my confidence in the subject."
                </div>
                <span className="italic text-gray500">
                  Alfred Shittu - Student
                </span>
              </div>
            </div>
          </div>

          <div className="flex-1 mt-3 md:mt-0">
            <img
              src={TutorCard}
              alt="W2ST Tutor Banner"
              // className="h-[70px] w-[70px]"
            />
          </div>
        </div>
      </section>
      <section className="container mx-auto pt-0 md:py-20">
        <div>
          <div className="block md:flex items-center md:gap-[5vw]">
            <div className="flex-1 md:block hidden">
              <img src={TutorCard} alt="W2ST Tutor Banner" />
            </div>

            <div className="flex-1  text-black mt-10 md:mt-0">
              <h3 className="md:text-dmd text-txs text-gray700 W2STMedium w-10/12">
                Connect with the right tutors
                <br className="md:block hidden" /> for your educational success
              </h3>

              <p className="text-gray500 my-[24px] text-txl md:w-10/12 w-full">
                Unlock the power of our online platform, where we source the
                finest tutors across all age groups and academic levels.{" "}
              </p>
              <div className="flex gap-2">
                <img
                  src={TestAvatar}
                  alt="testimonials"
                  className="h-[70px] w-[70px]"
                />
                <div>
                  <div className="text-gray900 italic W2STMedium w-full md:w-8/12">
                    "W2ST tutor's approach helped me tackle tough concepts,
                    boosting my confidence in the subject."
                  </div>
                  <span className="italic text-gray500">
                    Alfred Shittu - Student
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto py-20">
        <div>
          <div className="block md:flex items-center gap-4">
            <div className="flex-1 mb-10 md:mb-0">
              <p className="text-gray500 text-tlg">WHY CHOOSE US?</p>
              <h3 className="text-gray700 text-txs md:text-dmd W2STMedium pt-[17px] md:pb-[49px] pb-[25px]">
                Study with tutors who truly care about your academic growth.{" "}
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {data.map(({ title, desc, id, icon }) => (
                  <div key={id} className="md:mb-[32px] mb-[16px]">
                    <div className="rounded-full p-3 border-purple-200 border-[1px] inline-block text-purple-700 W2STMedium">
                      {icon}
                    </div>
                    <h3 className="W2STBold text-gray700 my-[16px] text-tlg">
                      {title}
                    </h3>
                    <p className="text-gray500 text-tsm w-10/12">{desc}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-1">
              <div
                style={{
                  width: "100%",
                  height: "600px",
                  position: "relative",
                }}
              >
                <img
                  src={Largebanner}
                  alt="W2ST Tutor Banner"
                  className="object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 bg-white">
        <p className="text-center text-tlg uppercase text-gray500 mb-3">
          TUTORING SERVICE TO SUIT YOUR NEEDS
        </p>
        <center className="text-dsm md:text-dlg text-gray700 my-[23px] md:my-[46px] W2STMedium px-10 md:px-0">
          Providing premium learning solutions with
          <br className="md:block hidden" /> a wide variety of education
          services.
        </center>
        <div className="continer mx-auto md:w-[450px] w-11/12 bg-white rounded-full border-[1px] border-primary200 p-1">
          <span className="flex">
            <span
              className={`transition ease-in-out duration-300 cursor-pointer ${
                active === "online" ? activetab : inActiveTab
              }`}
              onClick={() => setActive("online")}
            >
              Online Tutoring
            </span>
            <span
              className={`transition ease-in-out duration-300 cursor-pointer ${
                active === "physical" ? activetab : inActiveTab
              }`}
              onClick={() => setActive("physical")}
            >
              One-on-One Tutoring
            </span>
          </span>
        </div>
        <div className="mt-10 container mx-auto">
          {active === "online" && (
            <div className="block md:flex gap-4 items-center md:px-0 px-5">
              <div className="w-1/12 md:block hidden"></div>
              <div className="w-full md:w-4/12">
                <img
                  src={Online}
                  alt=""
                  className="md:block hidden h-[450px]"
                />
              </div>
              <div className="w-full md:w-6/12 ">
                <div>
                  <h3 className="text-gray700 text-txs md:text-dmd W2STMedium mb-[12px] md:mb-[24px]">
                    Online Tutoring Classes
                  </h3>
                  <p className="text-gray500 mb-[16px] md:mb-[32px]">
                    {/* Unlock your learning potential with our Real-Time <br />{' '}
                      Tutoring Classes. Experience live, interactive lessons
                      <br /> from expert educators. */}
                    We offer convenience, flexibility, personalized learning,
                    <br />
                    and access to a wide range of resources, promoting
                    <br />
                    effective learning from anywhere.
                  </p>
                  <Link to="/tutors">
                    <Button type="primary" title="Book a Tutor" />
                  </Link>
                </div>
              </div>
              <div className="w-1/12"></div>
            </div>
          )}
          {active === "physical" && (
            <div className="block md:flex gap-4 items-center md:px-0 px-5">
              <div className="w-1/12 md:block hidden"></div>
              <div className="w-full md:w-6/12 ">
                <div>
                  <h3 className="text-gray700 text-txs md:text-dmd W2STMedium mb-[12px] md:mb-[24px]">
                    One-on-One Tutoring Classes
                  </h3>
                  <p className="text-gray500 mb-[16px] md:mb-[32px]">
                    Physical classes provide personalized attention,
                    <br />
                    real-time feedback, and social interaction, fostering
                    <br />
                    deeper understanding and motivation for improved academic
                    <br />
                    performance.
                  </p>
                  <Link to="/tutors">
                    <Button type="primary" title="Book a Tutor" />
                  </Link>
                </div>
              </div>
              <div className="w-full md:w-4/12">
                <img
                  src={Offline}
                  alt=""
                  className="md:block hidden h-[450px]"
                />
              </div>
              <div className="w-1/12"></div>
            </div>
          )}
          {/* {active === 'online' && (
              <section className="md:flex gap-[20px] container md:mx-auto md:w-8/12 w-full items-center">
                <Image
                  src={StudentView}
                  alt=""
                  className="md:block hidden"
                  height={450}
                />
                <div className="text-gray700">
                  <h3 className="text-gray-700 md:text-4xl text-2xl  W2STMedium mb-4">
                    Real-Time Tutoring Classes
                  </h3>
                  <p className="text-gray500 mb-3">
                    Unlock your learning potential with our Real-Time <br />{' '}
                    Tutoring Classes. Experience live, interactive lessons
                    <br /> from expert educators.
                  </p>
                  <Link to="/tutors">
                    <Button type="primary" title="Book a Tutor" />
                  </Link>
                </div>
              </section>
            )}
            {active === 'physical' && (
              <section className="md:flex gap-[20px] container md:mx-auto md:w-8/12 w-full items-center">
                <div className="text-gray700">
                  <h3 className="text-gray-700 md:text-4xl text-[20px]  W2STMedium mb-4">
                    Real-Time Tutoring Classes
                  </h3>
                  <p className="text-gray500 mb-3">
                    Unlock your learning potential with our Real-Time <br />{' '}
                    Tutoring Classes. Experience live, interactive lessons
                    <br /> from expert educators.
                  </p>
                  <Link to="/tutors">
                    <Button title="Book a Tutor" />
                  </Link>
                </div>
                <Image
                  src={StudentView2}
                  alt=""
                  className="md:block hidden"
                  height={450}
                />
              </section>
            )} */}
        </div>
      </section>
      <section className="bg-primary700 py-20" id="parents">
        <div className="container mx-auto">
          <center className="text-white mb-[24px] text-tlg">FOR PARENTS</center>
          <center className="text-dmd md:text-dxl text-white W2STMedium mb-[12px]">
            Get Personalized Tutoring
            <br className="md:block hidden" /> Services for Your Child
          </center>
          <center className="text-tmd md:text-txl text-white md:mb-[24px] mb-[12px]">
            Experience the ease of connecting with highly qualified
            <br className="md:block hidden" /> educators for both parents and
            students.
          </center>
          <div className="flex mx-auto justify-center gap-4 mb-[47px] md:mb-[94px] mt-10">
            <Link
              to="/student/tutors"
              className="
              bg-white text-gray700 py-4 px-6 rounded-md cursor-pointer border-[1px] border-white text-tsm md:text-tlg "
            >
              Book a Tutor
            </Link>
            <Link
              to="mailto:info@w2st.net"
              target="_black"
              className="
              bg-primary700 text-white py-4 px-6 rounded-md cursor-pointer border-[1px] border-white text-tsm md:text-tlg"
            >
              Request a Tutor
            </Link>
          </div>
          <div className="block md:flex mx-auto justify-center gap-4 mt-10 md:mt-20 mb-[27px] md:mb-[54px]">
            <span className="mb-[10px] md:mb-0 flex text-primary100 items-center rounded-full py-2 px-4 border-[1px] border-primary100 gap-3 text-tmd">
              <Check />
              Competitive Rates to fit your budget
            </span>
            <span className="mb-[10px] md:mb-0 flex text-primary100 items-center rounded-full py-2 px-4 border-[1px] border-primary100 gap-3 text-tmd">
              <Check />
              Flexible Tutoring Hours
            </span>
            <span className="mb-[10px] md:mb-0 flex text-primary100 items-center rounded-full py-2 px-4 border-[1px] border-primary100 gap-3 text-tmd">
              <Check />
              100% Satisfactioon Guaranteed
            </span>
          </div>

          <img src={Joint} alt="" className="mb-[54px]" />
          <div className="grid md:grid-cols-3 grid-cols-1 gap-[40px] mt-[40px]">
            {more.map(({ id, icon, title, body }) => (
              <div key={id} className="border-b-[1px] pb-[24px]">
                <span className="rounded-full p-3 border-primary200 border-[1px] inline-block text-white mb-[12px]">
                  {icon}
                </span>
                <p className="W2STMedium text-white mb-[12px] text-tlg">
                  {title}
                </p>
                <p className="text-white text-tsm">{body}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="container mx-auto py-20">
        <div>
          <div className="block md:flex gap-[300px]">
            <div className="md:w-6/12 w-full">
              <p className="text-gray500 mb-[12px] md:mb-[24px] text-tsm">
                HOW IT WORKS
              </p>
              <h3 className="text-gray700 W2STMedium text-dsm md:text-dlg mb-[12px] md:mb-[24px]">
                How to Find the Best Tutor?
              </h3>
              <p className="text-gray500 mb-[12px] md:mb-[24px]">
                Unlock the power of our online platform, where we source the
                finest tutors across all age groups and academic levels.
              </p>
              <Link to="/tutor/signup">
                <Button title="Book a tutor" />
              </Link>
              <img src={ParentAndChild} alt="" className="mt-[50px]" />
            </div>
            <div className="md:w-6/12 w-full mt-[20px] md:mt-0">
              <div className="rounded-md bg-gray-100 border-[1px] p-4">
                {studeentSteps.map(({ id, title, desc }) => (
                  <div className="mb-5" key={id}>
                    <p className="uppercase text-primary700">{id}</p>
                    <h3 className="text-gray700 text-tlg W2STMedium mb-[12px]">
                      {title}
                    </h3>
                    <div className="text-gray500 my-3 text-tsm mb-[24px] border-b-[1px] border-gray300 pb-[24px]">
                      {desc}
                    </div>
                  </div>
                ))}
                <div className="text-gray500 my-3 text-tsm mb-[24px] pb-[24px]">
                  That’s it! Once you complete these steps, you unlock better
                  and greater academic opportunities. When in doubt, get in
                  touch with our support team for help.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StudentTestimonials />
      <StudentFAQ />
      <div className="container mx-auto md:my-[120px] mb-[80px] embed-background">
        <p className="text-center text-gray200 W2STRegular text-lg text-[20px] mt-[64px] mb-[32px]">
          Contact us today to get started!!
        </p>
        <p className="text-center W2STBold text-white md:text-dlg text-[35px] mb-[32px]">
          Your quest for compatible
          <br className="md:block hidden" /> tutors ends here.
        </p>
        <div className="text-center mb-[64px]">
          <Link
            to="/student/signup"
            className="bg-dark text-white py-5 px-6 rounded-md tlg"
          >
            <Button title="Get a Tutor" />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
