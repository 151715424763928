import React, { useState } from "react";
import { LocationIcon } from "./Icon/LocationIcon";
import { VirtualIcon } from "./Icon/VirtualIcon";
import { useUpdateTutorProfile } from "../hooks/api/Tutors/UpdateProfile/useUpdateTutorProfile";
import { Button, Select, Modal } from "antd";
import { Edit } from "lucide-react";

const AvailabilityCard = ({ data }: any) => {
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [availabilityType, setAvailabilityType] = useState(
    data?.data?.availabilityType
  );

  const { Option } = Select;

  const { mutate, isLoading: loadingTutor } = useUpdateTutorProfile();

  const handleAvailabilityChange = (value: any) => {
    setAvailabilityType(value);
  };
  return (
    <div className="mt-5 bg-white rounded-lg p-5">
      <Modal
        width="400px"
        footer={null}
        open={availabilityModal}
        onCancel={() => setAvailabilityModal(false)}
        title="Edit Availability"
      >
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700 mb-3">
            Availability Type
          </label>
          <Select
            value={availabilityType}
            onChange={handleAvailabilityChange}
            className="w-full"
            size="large"
          >
            <Option value="Virtual">Virtual Tutoring</Option>
            <Option value="One-on-one">One-on-One Tutoring</Option>
            <Option value="Both">Both</Option>
          </Select>
          <Button
            type="primary"
            className="mt-8 w-full"
            size="large"
            loading={loadingTutor}
            disabled={!availabilityType}
            onClick={() => {
              mutate(availabilityType, {
                onSuccess: () => {
                  setAvailabilityModal(false);
                },
              });
            }}
          >
            Continue
          </Button>
        </div>
      </Modal>

      <div className="flex justify-between items-center">
        <h3 className="W2STMedium text-gray900">Available for</h3>
        <button
          className="text-primary600 flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setAvailabilityModal(true);
          }}
        >
          <Edit size={20} /> Edit
        </button>
      </div>

      {data && data?.data?.availabilityType === "Both" && (
        <div className="mt-3 flex gap-3 overflow-scroll">
          <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 text-sm flex items-center gap-2">
            <LocationIcon />
            <span className="text-[12px]">One-on-One Tutoring</span>
          </span>
          <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 text-sm flex items-center gap-2">
            <VirtualIcon />
            <span className="text-[12px]">Virtual Tutoring</span>
          </span>
        </div>
      )}

      {(data?.data?.availabilityType === "One-on-one" ||
        data?.data?.availabilityType === "Virtual") && (
        <div className="mt-3 flex justify-start gap-3">
          {data?.data?.availabilityType === "One-on-one" && (
            <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 text-sm flex items-center gap-2">
              <LocationIcon />
              <span className="text-[12px]">One-on-One Tutoring</span>
            </span>
          )}
          {data?.data?.availabilityType === "Virtual" && (
            <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 text-sm flex items-center gap-2">
              <VirtualIcon />
              <span className="text-[12px]">Virtual Tutoring</span>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default AvailabilityCard;
