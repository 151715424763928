import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetSingleBookingQuery = ({ id }: { id?: string }) => {
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useQuery({
    queryKey: ["getSingleBookingQuery", id],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");
      if (!id) throw new Error("Booking ID is required");

      const response = await axiosInstance.get(`users/bookings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    enabled: !!token && !!id,
    onError: (error) => {
      console.error("Error fetching booking:", error);
    },
  });
};
