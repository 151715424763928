import React, { useState } from "react";
import { useLoginAdmin } from "../../../hooks/api/Admin/Auth/useLoginAdmin";
import { useVerifyAdmin } from "../../../hooks/api/Admin/Auth/useVerifyAdmin";
import Button from "../../../components/Button";

const AdminLogin = () => {
  const [level, setLevel] = useState(0);
  const [payload, setPayload] = useState({ id: "", otp: "" });

  const [email, setEmail] = useState("");
  const Logo = require("../../../assets/images/logotransparent.png");

  const { mutateAsync, isLoading } = useLoginAdmin();
  const { mutateAsync: verify, isLoading: isVerifying } = useVerifyAdmin();

  const handleSubmit = () => {
    mutateAsync(email, {
      onSuccess: (data) => {
        setLevel(1);
        setPayload({ ...payload, id: data?.data?.id });
      },
    });
  };

  const handleVerify = () => {
    verify(payload);
  };

  return (
    <div className="authscreen">
      <div>
        <div className="w-[500px] relative top-[50vh] left-[50vw] -translate-y-[50%] -translate-x-[50%] p-[40px] bg-white rounded-md shadow-md">
          <img src={Logo} alt="" className="h-12 mb-5" />

          <div>
            <span className="absolute text-purple900 W2STMedium -top-4 -right-4 bg-primary200 p-2 rounded-md rotate-[20deg] shadow-md">
              ADMIN
            </span>
          </div>
          {level === 0 && (
            <div>
              <label htmlFor="propertytype" className="font-gray">
                Email
              </label>
              <input
                name="email"
                type="email"
                placeholder="Email"
                autoFocus
                value={email}
                disabled={isLoading}
                className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
                onChange={(e) => setEmail(e.target.value)}
              />

              {/* <button
                type="submit"
                onClick={handleSubmit}
                disabled={!email}
                className={`px-4 block mx-auto w-full  text-white py-5 rounded-md  mt-4 ${
                  !email
                    ? "bg-primary300 cursor-not-allowed"
                    : "bg-primary800 cursor-pointer shadow-md"
                }`}
              >
                Login
              </button> */}
              <Button
                isFormValid={email ? true : false}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                label={"Login"}
              />
            </div>
          )}
          {level === 1 && (
            <div>
              <label htmlFor="otp" className="font-gray">
                Email
              </label>
              <input
                name="otp"
                type="string"
                placeholder="OTP"
                autoFocus
                value={payload?.otp}
                disabled={isVerifying}
                className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
                onChange={(e) =>
                  setPayload({ ...payload, otp: e?.target?.value })
                }
              />
              <Button
                isFormValid={payload?.otp ? true : false}
                isLoading={isVerifying}
                handleSubmit={handleVerify}
                label={"Login"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
