import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

export const useUpdateTutorProfile = () => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useMutation(
    (availabilityType: any) =>
      axiosInstance
        .patch(
          "tutors/other-profile",
          {
            availabilityType: availabilityType,
            country: "United States of America",
            hourlyRate: 20,
            bio: "",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        queryClient.invalidateQueries("gettutor");
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
