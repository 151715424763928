import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

interface GetTutorsParams {
  search?: string;
  isDeleted?: boolean;
  isEmailVerified?: boolean;
  isAccountVerified?: boolean;
  page: number;
}

export const useGetTutorsonAdminQuery = ({
  search,
  isDeleted,
  isEmailVerified,
  isAccountVerified,
  page,
}: GetTutorsParams) => {
  const token = localStorage.getItem("W2ST_ADMIN_TOKEN");

  return useQuery({
    queryKey: [
      "getTutorsonAdmin",
      { search, isDeleted, isEmailVerified, isAccountVerified, page },
    ],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");

      const params = {
        ...(search && { search }),
        ...(isDeleted && { isDeleted }),
        ...(isEmailVerified && { isEmailVerified }),
        ...(isAccountVerified && { isAccountVerified }),
        page,
      };

      const response = await axiosInstance.get("admins/tutors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      return response.data;
    },
    onSuccess: (data) => {
      console.log("Success Getting Tutors ==>", data);
    },
    onError: (error: unknown) => {
      console.error("Failed Getting Tutors ==>", error);
    },
    enabled: !!token,
  });
};
