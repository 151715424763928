import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  isFormValid?: boolean;
  isLoading: boolean;
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label: string;
};

const Button = ({ isFormValid, isLoading, handleSubmit, label }: Props) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  return (
    <button
      className="block W2STMedium text-center mt-4 py-5 w-full rounded-md text-white text-md bg-primary500"
      disabled={!isFormValid || isLoading}
      onClick={handleSubmit}
    >
      {isLoading ? <Spin size="default" indicator={antIcon} /> : label}
    </button>
  );
};

export default Button;
