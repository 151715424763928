import React from "react";
import { Form, Input, Button, DatePicker, Select, Space, Card } from "antd";
import moment from "moment";
import { useAddEducation } from "../../../../hooks/api/Tutors/UpdateProfile/useAddEducation";

const EditEducation = ({ resetForm }: any) => {
  const [form] = Form.useForm();
  const { mutate, isLoading } = useAddEducation();

  const onFinish = (values: any) => {
    const formattedValues = {
      ...values,
      enrolled: values.enrolled
        ? moment(values.enrolled).format("YYYY-MM-DD")
        : null,
      graduated: values.graduated
        ? moment(values.graduated).format("YYYY-MM-DD")
        : null,
    };

    mutate(formattedValues, {
      onSuccess: () => {
        form.resetFields();
        resetForm();
      },
    });
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Space
        direction="vertical"
        style={{ display: "flex", width: "100%", padding: 0 }}
      >
        <Card size="small">
          <Form.Item name="college" label="College">
            <Input placeholder="Enter College Name" size="large" />
          </Form.Item>

          <Form.Item name="majors" label="Majors">
            <Input placeholder="Enter Majors" size="large" />
          </Form.Item>

          <Form.Item name="degree" label="Degree">
            <Select placeholder="Select Degree" size="large">
              <Select.Option value="Bachelor's">Bachelor's</Select.Option>
              <Select.Option value="Master's">Master's</Select.Option>
              <Select.Option value="PhD">PhD</Select.Option>
            </Select>
          </Form.Item>

          <div className="flex flex-row gap-2">
            <div className="w-6/12">
              <Form.Item name="enrolled" label="Enrolled Date">
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </div>
            <div className="w-6/12">
              <Form.Item name="graduated" label="Graduation Date">
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Card>
      </Space>
    </Form>
  );
};

export default EditEducation;
