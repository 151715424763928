// import React, { useState } from "react";
// import { useLevel3Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel3Onboarding";
// import { Plus } from "lucide-react";

// const LevelThree = () => {
//   const [payload, setPayload] = useState({
//     education: [
//       {
//         college: "",
//         majors: "",
//         degree: "",
//         enrolled: "",
//         graduated: null,
//       },
//     ],
//     workExperience: [
//       {
//         title: "",
//         company: "",
//         startDate: "",
//         endDate: "",
//         description: "",
//       },
//     ],
//     stillAStudent: false,
//   });

//   const { mutateAsync } = useLevel3Onboarding();

//   const handleSubmit = () => {
//     mutateAsync(payload);
//   };

//   const handleEducationChange = (e: any, index: number) => {
//     const { name, value } = e.target;
//     const updatedEducation = [...payload.education];
//     updatedEducation[index] = { ...updatedEducation[index], [name]: value };

//     setPayload((prev) => ({
//       ...prev,
//       education: updatedEducation,
//     }));
//   };

//   const addNewEducation = () => {
//     setPayload((prev) => ({
//       ...prev,
//       education: [
//         ...prev.education,
//         {
//           college: "",
//           majors: "",
//           degree: "",
//           enrolled: "",
//           graduated: null,
//         },
//       ],
//     }));
//   };
//   const deleteEducation = (index: number) => {
//     setPayload((prev) => ({
//       ...prev,
//       education: prev.education.filter((_, idx) => idx !== index),
//     }));
//   };
//   const addNewWorkExperience = () => {
//     setPayload((prev) => ({
//       ...prev,
//       workExperience: [
//         ...prev.workExperience,
//         {
//           title: "",
//           company: "",
//           startDate: "",
//           endDate: "",
//           description: "",
//         },
//       ],
//     }));
//   };

//   const deleteWorkExperience = (index: number) => {
//     setPayload((prev) => ({
//       ...prev,
//       workExperience: prev.workExperience.filter((_, idx) => idx !== index),
//     }));
//   };

//   const handleChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
//     index: number
//   ) => {
//     const { name, value } = e.target;

//     setPayload((prev) => ({
//       ...prev,
//       workExperience: prev.workExperience.map((experience, idx) =>
//         idx === index ? { ...experience, [name]: value } : experience
//       ),
//     }));
//   };

//   return (
//     <div>
//       <div>
//         <h3 className="W2STMedium mb-4 text-2xl mt-2">Education</h3>
//         {payload.education.map((edu, index) => (
//           <div key={index} className="mb-6 border p-4 rounded-md relative">
//             {index !== 0 && (
//               <button
//                 onClick={() => deleteEducation(index)}
//                 className="absolute top-2 right-2 text-red-500 border border-red-500 rounded-md px-2 py-1 text-sm"
//               >
//                 Delete
//               </button>
//             )}
//             <label htmlFor={`college-${index}`} className="text-gray700">
//               College
//             </label>
//             <input
//               id={`college-${index}`}
//               name="college"
//               type="text"
//               value={edu.college}
//               onChange={(e) => handleEducationChange(e, index)}
//               placeholder="Enter College Name"
//               className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
//             />
//             <label
//               htmlFor={`majors-${index}`}
//               className="text-gray700 mt-3 block"
//             >
//               Majors
//             </label>
//             <input
//               id={`majors-${index}`}
//               name="majors"
//               type="text"
//               value={edu.majors}
//               onChange={(e) => handleEducationChange(e, index)}
//               placeholder="Enter Majors"
//               className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
//             />
//             <label
//               htmlFor={`degree-${index}`}
//               className="text-gray700 mt-3 block"
//             >
//               Degree
//             </label>
//             <select
//               name="degree"
//               onChange={(e) => handleEducationChange(e, index)}
//               className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
//             >
//               <option value="">--Select Option--</option>
//               {degrees.map(({ degree, professional_title }) => (
//                 <option key={professional_title}>{degree}</option>
//               ))}
//             </select>
//             {/* <input
//               id={`degree-${index}`}
//               name="degree"
//               type="text"
//               value={edu.degree}
//               onChange={(e) => handleEducationChange(e, index)}
//               placeholder="Enter Degree"
//               className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
//             /> */}
//             <label
//               htmlFor={`dates-${index}`}
//               className="text-gray700 mt-3 block"
//             >
//               Duration
//             </label>
//             <div className="flex gap-2 mt-2 justify-between">
//               <input
//                 id={`enrolled-${index}`}
//                 name="enrolled"
//                 type="date"
//                 value={edu.enrolled}
//                 onChange={(e) => handleEducationChange(e, index)}
//                 className="border-[1px] rounded-md border-gray300 p-4 block outline-none w-6/12"
//               />
//               <input
//                 id={`graduated-${index}`}
//                 name="graduated"
//                 type="date"
//                 value={edu.graduated || ""} // Ensure that graduated is handled properly as either string or null
//                 onChange={(e) => handleEducationChange(e, index)}
//                 className="border-[1px] rounded-md border-gray300 p-4 block outline-none w-6/12"
//               />
//             </div>
//           </div>
//         ))}
//         <button
//           onClick={addNewEducation}
//           className="text-gray700 flex gap-2 items-center rounded-md border-[1px] border-gray300 px-4 py-2"
//         >
//           <Plus size={20} />
//           Add Education
//         </button>
//       </div>{" "}
//       <div>
//         <h3 className="W2STMedium mb-4 text-2xl mt-2">Work Experience</h3>
//         {payload.workExperience.map((experience, index) => (
//           <div key={index} className="mb-6 border p-4 rounded-md relative">
//             {index !== 0 && (
//               <button
//                 onClick={() => deleteWorkExperience(index)}
//                 className="absolute top-2 right-2 text-red-500 border border-red-500 rounded-md px-2 py-1 text-sm"
//               >
//                 Delete
//               </button>
//             )}
//             <label htmlFor={`title-${index}`} className="text-gray700">
//               Title
//             </label>
//             <input
//               id={`title-${index}`}
//               name="title"
//               type="text"
//               value={experience.title}
//               onChange={(e) => handleChange(e, index)}
//               placeholder="Enter Job Title"
//               className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
//             />
//             <label
//               htmlFor={`company-${index}`}
//               className="text-gray700 mt-3 block"
//             >
//               Company
//             </label>
//             <input
//               id={`company-${index}`}
//               name="company"
//               type="text"
//               value={experience.company}
//               onChange={(e) => handleChange(e, index)}
//               placeholder="Enter Company Name"
//               className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
//             />

//             <label
//               htmlFor={`dates-${index}`}
//               className="text-gray700 mt-3 block"
//             >
//               Duration
//             </label>
//             <div className="flex gap-2 mt-2 justify-between">
//               <input
//                 id={`startDate-${index}`}
//                 name="startDate"
//                 type="date"
//                 value={experience.startDate}
//                 onChange={(e) => handleChange(e, index)}
//                 className="border-[1px] rounded-md border-gray300 p-4 block outline-none w-6/12"
//               />
//               <input
//                 id={`endDate-${index}`}
//                 name="endDate"
//                 type="date"
//                 value={experience.endDate}
//                 onChange={(e) => handleChange(e, index)}
//                 className="border-[1px] rounded-md border-gray300 p-4 block outline-none w-6/12"
//               />
//             </div>

//             <label
//               htmlFor={`description-${index}`}
//               className="text-gray700 mt-3 block"
//             >
//               Description
//             </label>
//             <textarea
//               id={`description-${index}`}
//               name="description"
//               value={experience.description}
//               onChange={(e) => handleChange(e, index)}
//               placeholder="Describe your role"
//               className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none resize-none"
//             />
//           </div>
//         ))}
//         <button
//           onClick={addNewWorkExperience}
//           className="text-gray700 flex gap-2 items-center rounded-md border-[1px] border-gray300 px-4 py-2"
//         >
//           <Plus size={20} />
//           Add work experience
//         </button>
//       </div>
//       <button
//         // disabled={
//         //   !payload?.areaOfExpertise ||
//         //   !payload?.professionalTitle ||
//         //   !payload?.profilePicture
//         // }
//         className={`mt-8 w-full py-3 mb-4 bg-primary900 rounded-md text-white text-md float-right`}
//         onClick={handleSubmit}
//         // onClick={() => setLevel(level + 1)}
//       >
//         Submit
//       </button>
//     </div>
//   );
// };

// export default LevelThree;

import React, { useState } from "react";
import { Button, Form, Input, DatePicker, Select, Card, Space } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useLevel3Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel3Onboarding";

interface Education {
  college: string;
  majors: string;
  degree: string;
  enrolled: string;
  graduated: string | null;
}

interface WorkExperience {
  title: string;
  company: string;
  startDate: string;
  endDate: string;
  description: string;
}

interface Payload {
  education: Education[];
  workExperience: WorkExperience[];
  stillAStudent: boolean;
}

const LevelThree: React.FC = () => {
  const [form] = Form.useForm();
  const { mutateAsync } = useLevel3Onboarding();

  const handleSubmit = async (values: Payload) => {
    await mutateAsync(values);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        education: [
          {
            college: "",
            majors: "",
            degree: "",
            enrolled: "",
            graduated: null,
          },
        ],
        workExperience: [
          {
            title: "",
            company: "",
            startDate: "",
            endDate: "",
            description: "",
          },
        ],
        stillAStudent: false,
      }}
    >
      {/* Education Section */}
      {/* <Card title="Education" bordered={false}> */}
      <p className="my-4 W2STMedium text-[20px]">Education</p>
      <Form.List name="education">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                direction="vertical"
                style={{ display: "flex", width: "100%", padding: 0 }}
              >
                <Card
                  size="small"
                  extra={
                    fields.length > 1 && (
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{ color: "red" }}
                      />
                    )
                  }
                >
                  <Form.Item
                    {...restField}
                    name={[name, "college"]}
                    label="College"
                    rules={[{ required: true, message: "Enter College Name" }]}
                  >
                    <Input placeholder="Enter College Name" size="large" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "majors"]}
                    label="Majors"
                    rules={[{ required: true, message: "Enter Majors" }]}
                  >
                    <Input placeholder="Enter Majors" size="large" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "degree"]}
                    label="Degree"
                    rules={[{ required: true, message: "Select Degree" }]}
                  >
                    <Select placeholder="Select Degree" size="large">
                      <Select.Option value="BSc">Bachelor's</Select.Option>
                      <Select.Option value="MSc">Master's</Select.Option>
                      <Select.Option value="PhD">PhD</Select.Option>
                    </Select>
                  </Form.Item>

                  <div className="flex flex-row gap-2">
                    <div className="w-6/12">
                      <Form.Item
                        {...restField}
                        name={[name, "enrolled"]}
                        label="Enrolled Date"
                        rules={[{ required: true }]}
                      >
                        <DatePicker style={{ width: "100%" }} size="large" />
                      </Form.Item>
                    </div>
                    <div className="w-6/12">
                      <Form.Item
                        {...restField}
                        name={[name, "graduated"]}
                        label="Graduation Date"
                        rules={[{ required: true }]}
                      >
                        <DatePicker style={{ width: "100%" }} size="large" />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </Space>
            ))}
            <Button
              type="default"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              className="mt-2"
              size="large"
            >
              Add Education
            </Button>
          </>
        )}
      </Form.List>
      {/* </Card> */}

      {/* Work Experience Section */}
      {/* <Card title="Work Experience" bordered={false}> */}
      <p className="mb-4 mt-[40px] W2STMedium text-[20px]">Work Experience</p>

      <Form.List name="workExperience">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                direction="vertical"
                style={{ display: "flex", width: "100%" }}
              >
                <Card
                  size="small"
                  extra={
                    fields.length > 1 && (
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{ color: "red" }}
                      />
                    )
                  }
                >
                  <Form.Item
                    {...restField}
                    name={[name, "title"]}
                    label="Job Title"
                    rules={[{ required: true, message: "Enter Job Title" }]}
                  >
                    <Input placeholder="Enter Job Title" size="large" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "company"]}
                    label="Company"
                    rules={[{ required: true, message: "Enter Company Name" }]}
                  >
                    <Input placeholder="Enter Company Name" size="large" />
                  </Form.Item>
                  <div className="flex gap-2">
                    <div className="w-6/12">
                      <Form.Item
                        {...restField}
                        name={[name, "startDate"]}
                        label="Start Date"
                        rules={[{ required: true }]}
                      >
                        <DatePicker style={{ width: "100%" }} size="large" />
                      </Form.Item>
                    </div>
                    <div className="w-6/12">
                      <Form.Item
                        {...restField}
                        name={[name, "endDate"]}
                        label="End Date"
                      >
                        <DatePicker style={{ width: "100%" }} size="large" />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item
                    {...restField}
                    name={[name, "description"]}
                    label="Description"
                  >
                    <Input.TextArea placeholder="Describe your role" />
                  </Form.Item>
                </Card>
              </Space>
            ))}
            <Button
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              type="default"
              className="mt-2"
              size="large"
            >
              Add Work Experience
            </Button>
          </>
        )}
      </Form.List>
      {/* </Card> */}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          className="mt-4"
          size="large"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LevelThree;

const degrees = [
  {
    degree: "Bachelor of Arts (BA)",
    professional_title: "B.A. Graduate",
  },
  {
    degree: "Bachelor of Science (BS)",
    professional_title: "B.S. Graduate",
  },
  {
    degree: "Bachelor of Fine Arts (BFA)",
    professional_title: "Bachelor of Fine Arts",
  },
  {
    degree: "Bachelor of Business Administration (BBA)",
    professional_title: "BBA Graduate, Business Administrator",
  },
  {
    degree: "Bachelor of Engineering (BEng)",
    professional_title: "Engineer",
  },
  {
    degree: "Bachelor of Computer Science (BCS)",
    professional_title: "Computer Scientist, Software Developer",
  },
  {
    degree: "Associate of Arts (AA)",
    professional_title: "Associate",
  },
  {
    degree: "Associate of Science (AS)",
    professional_title: "Associate",
  },
  {
    degree: "Associate of Applied Science (AAS)",
    professional_title: "Technician, Technologist",
  },
  {
    degree: "Master of Arts (MA)",
    professional_title: "Master of Arts",
  },
  {
    degree: "Master of Science (MS)",
    professional_title: "Master of Science",
  },
  {
    degree: "Master of Business Administration (MBA)",
    professional_title: "MBA Graduate, Business Executive, Business Manager",
  },
  {
    degree: "Master of Fine Arts (MFA)",
    professional_title: "Master of Fine Arts",
  },
  {
    degree: "Master of Engineering (MEng)",
    professional_title: "Engineer",
  },
  {
    degree: "Master of Education (MEd)",
    professional_title: "Educator, Teacher, School Administrator",
  },
  {
    degree: "Master of Public Health (MPH)",
    professional_title: "Public Health Professional, Epidemiologist",
  },
  {
    degree: "Master of Social Work (MSW)",
    professional_title: "Social Worker",
  },
  {
    degree: "Master of Laws (LLM)",
    professional_title: "Attorney, Lawyer",
  },
  {
    degree: "Master of Library Science (MLS)",
    professional_title: "Librarian, Archivist",
  },
  {
    degree: "Doctor of Philosophy (PhD)",
    professional_title: "Doctor, Professor, Researcher",
  },
  {
    degree: "Doctor of Medicine (MD)",
    professional_title: "Physician, Doctor",
  },
  {
    degree: "Doctor of Dental Surgery (DDS)",
    professional_title: "Dentist",
  },
  {
    degree: "Doctor of Pharmacy (PharmD)",
    professional_title: "Pharmacist",
  },
  {
    degree: "Doctor of Jurisprudence (JD)",
    professional_title: "Attorney, Lawyer, Judge",
  },
  {
    degree: "Doctor of Education (EdD)",
    professional_title:
      "Educational Administrator, School Superintendent, Professor",
  },
  {
    degree: "Doctor of Nursing Practice (DNP)",
    professional_title: "Nurse Practitioner (NP), Nursing Educator",
  },
  {
    degree: "Doctor of Physical Therapy (DPT)",
    professional_title: "Physical Therapist",
  },
  {
    degree: "Master of Architecture (MArch)",
    professional_title: "Architect",
  },
  {
    degree: "Doctor of Veterinary Medicine (DVM)",
    professional_title: "Veterinarian",
  },
  {
    degree: "Master of Public Administration (MPA)",
    professional_title: "Public Administrator, Government Official",
  },
];
