import React, { useState } from "react";
import AuthLayout from "../../../../layout/AuthLayout";
import Button from "../../../../components/Button";
import { useResetTutorPassword } from "../../../../hooks/api/Tutors/Auth/useResetTutorPassword";
import { useConfirmTutorPassword } from "../../../../hooks/api/Tutors/Auth/useConfirmTutorPassword";
import {
  CloseEyes,
  OpenEyes,
} from "../../../Students/Auth/SignIn/StudentSignin";
import { useNavigate } from "react-router-dom";

const TutorReset = () => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [password, setPassword] = useState("");
  const [step, setStep] = useState(0);
  const { mutateAsync, isLoading } = useResetTutorPassword();

  const { mutateAsync: verify, isLoading: isVerifying } =
    useConfirmTutorPassword();

  const handleSubmit = () => {
    mutateAsync(
      { email },
      {
        onSuccess: () => {
          setStep(1);
        },
      }
    );
  };

  const navigate = useNavigate();

  const handleVerification = () => {
    verify(
      { token: token, password: password, email: email },
      {
        onSuccess: () => {
          navigate("/tutor/signin");
        },
      }
    );
  };

  return (
    <AuthLayout
      heading="Find the Best Tutor."
      desc="Use our customized tutoring services to arrange in-person or online classes with the best tutors in the country."
    >
      <div className="md:w-8/12 w-11/12 mx-auto overflow-y-scroll">
        <div className="mx-auto">
          <h3 className="text-gray900 text-txs W2STMedium mb-3">
            Reset Password
          </h3>
          {step === 0 ? (
            <>
              <div className="w-full mb-5">
                <label htmlFor="email" className="text-gray700">
                  Email
                </label>
                <input
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
                  value={email}
                  disabled={isLoading}
                  onChange={(e) => setEmail(e?.target?.value)}
                />
              </div>
              <Button
                label="Submit"
                isFormValid={email !== ""}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
              />
            </>
          ) : (
            <>
              <div className="w-full mb-5">
                <label htmlFor="token" className="text-gray700">
                  OTP
                </label>
                <input
                  name="token"
                  type="token"
                  maxLength={6}
                  placeholder="Enter the OTP"
                  className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
                  value={token}
                  disabled={isVerifying}
                  onChange={(e) => setToken(e?.target?.value)}
                />
              </div>
              <div className="w-full">
                <label htmlFor="password" className="text-gray700">
                  Password
                </label>
                <div className="relative">
                  <input
                    name="password"
                    type={passwordVisibility === true ? "text" : "password"}
                    placeholder="Enter New Password"
                    className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
                    value={password}
                    disabled={isVerifying}
                    onChange={(e) => setPassword(e?.target?.value)}
                  />
                  <span
                    className="absolute right-6 top-[17px] flex text-gray400 items-center text-sm"
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    {passwordVisibility ? <OpenEyes /> : <CloseEyes />}
                  </span>
                </div>
              </div>
              <Button
                label="Submit"
                isFormValid={true}
                isLoading={isVerifying}
                handleSubmit={handleVerification}
              />
            </>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default TutorReset;
