import React, { useState } from "react";
import AuthLayout from "../../../../layout/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import { useLoginTutorMutation } from "../../../../hooks/api/Tutors/Auth/useLoginTutorMutation";
import {
  CloseEyes,
  OpenEyes,
} from "../../../Students/Auth/SignIn/StudentSignin";
import Button from "../../../../components/Button";
import ToggleSwitch from "../../../../components/ToggleSwitch";

const TutorSignin = () => {
  const navigate = useNavigate();
  const [payload, setPayload] = useState({ email: "", password: "" });

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { mutateAsync: signin, isLoading } = useLoginTutorMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const isFormValid =
    payload.email.trim() !== "" && payload.password.trim() !== "";

  const handleSubmit = async () => {
    try {
      await signin(payload);
      navigate("/tutor/overview");
    } catch (error) {
      console.error("Sign-in failed:", error);
    }
  };

  return (
    <AuthLayout
      heading="The only Tutoring profile you'll ever need."
      desc="Whether you are a full-time tutor or just interested in earning extra cash, we have a job that fits your goals. Join our tutoring service to create new teaching opportunities that align with your expertise and schedule."
    >
      {/* <ToggleSwitch /> */}
      <div className="md:w-8/12 w-11/12 mx-auto overflow-y-scroll">
        <div className="mx-auto">
          <h3 className="text-gray900 text-txs W2STMedium mb-3">Sign in</h3>
          <div className="w-full mb-5">
            <label htmlFor="email" className="text-gray700">
              Email
            </label>
            <input
              name="email"
              type="email"
              placeholder="Enter your email"
              className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
              value={payload?.email}
              //   disabled={isLoading}
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <label htmlFor="password" className="text-gray700">
              Password
            </label>
            <div className="relative">
              <input
                name="password"
                type={passwordVisibility === true ? "text" : "password"}
                placeholder="Enter your password"
                className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
                value={payload?.password}
                // disabled={isLoading}
                onChange={handleChange}
              />
              <span
                className="absolute right-6 top-[17px] flex text-gray400 items-center text-sm"
                onClick={() => setPasswordVisibility(!passwordVisibility)}
              >
                {passwordVisibility ? <OpenEyes /> : <CloseEyes />}
              </span>
            </div>
          </div>
          <div className="text-right mt-2">
            <Link to="/tutor/reset" className="text-gray500">
              <span className="text-primary700 W2STMedium">
                Forgot Password?
              </span>
            </Link>
          </div>
          <Button
            label="Sign In"
            isFormValid={isFormValid}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
          />
          <p className="mt-10 text-gray500">
            By continuing, you agree to the Terms of use, Privacy Policy, and
            Community Standards of Way2sucesstutors.
          </p>
          <div className="text-center mt-10">
            <Link to="/tutor/signup" className="text-gray500">
              Do not have an account?{" "}
              <span className="text-primary700 W2STMedium ">Signup</span>
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default TutorSignin;
