import { Tag } from "antd";
import React from "react";

interface StatusTagProps {
  status: string;
}

const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  const getColor = (status: string) => {
    switch (status) {
      case "pending":
        return "orange";
      case "completed":
        return "green";
      case "confirmed":
        return "blue";
      default:
        return "gray";
    }
  };

  return <Tag color={getColor(status)}>{status.toUpperCase()}</Tag>;
};

export default StatusTag;
