import { useEffect, useState } from "react";
import { CheckCircle, Mail, Clock2, ShieldCheck, Wallet } from "lucide-react";
import { Link } from "react-router-dom";
import { useGetConnectedAccount } from "../../../hooks/api/Tutors/Stripe/useGetConnectedAccount";

const VerificationProgress = ({ tutor }: { tutor: any }) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const hasAvailability =
      Array.isArray(tutor?.data?.weeklyAvailability) &&
      tutor.data?.weeklyAvailability.some(
        (day: any) => Array.isArray(day.timeSlots) && day.timeSlots.length > 0
      );
    if (
      tutor?.data?.isEmailVerified &&
      hasAvailability &&
      tutor?.data?.isAccountVerified === true &&
      tutor?.data?.isStripeOnboarded === true
    )
      setCurrentStep(4);
    else if (
      tutor?.data?.isEmailVerified &&
      hasAvailability &&
      tutor?.data?.isAccountVerified === true
    ) {
      setCurrentStep(3);
    } else if (tutor?.data?.isEmailVerified && hasAvailability) {
      setCurrentStep(2);
    } else if (tutor?.data?.isEmailVerified) {
      setCurrentStep(1);
    }
  }, [tutor]);

  const [verify, setVerify] = useState(false);
  const [isOpening, setIsOpening] = useState(false);

  const { isLoading } = useGetConnectedAccount({
    trigger: verify,
  });

  const handleClick = () => {
    if (!isOpening) {
      setIsOpening(true);
      setVerify(true);

      setTimeout(() => {
        setIsOpening(false);
      }, 5000);
    }
  };

  const steps = [
    {
      title: "Complete Profile Setup",
      description: "Verify your email",
      icon: <Mail />,
    },
    {
      title: "Set Availability",
      description: "Set your availability for tutoring",
      icon: <Clock2 />,
    },
    {
      title: "Admin Verification",
      description: "An admin personnel will verify your account",
      icon: <ShieldCheck />,
    },
    {
      title: "Payout Verification",
      description:
        "You'll be redirected to Stripe to complete the process and the linked account will receive booking payouts",
      icon: <Wallet />,
    },
  ];

  const progressWidth = `${(currentStep / 4) * 100}%`;

  return (
    <div className="p-5 bg-white rounded-md border border-primary500 mt-2">
      <h3 className="text-gray900 W2STMedium">
        Profile Completeness: {Math.round((currentStep / 4) * 100)}%
      </h3>
      <p className="text-gray500 mt-2">
        Complete the process to fully enjoy all the benefits of your tutor
        profile.
      </p>

      {/* Progress Bar */}
      <div className="bg-gray-200 rounded-md mt-5 h-2 w-full">
        <div
          className="bg-primary600 h-2 rounded-md transition-all"
          style={{ width: progressWidth }}
        ></div>
      </div>

      {/* Steps */}
      {steps.map((step, index) => (
        <div
          key={index}
          className={`flex items-center justify-between py-4 border-b ${
            index < currentStep ? "opacity-50 line-through" : ""
          }`}
        >
          <div className="flex items-center gap-3">
            <div
              className={`p-2 rounded-md ${
                index < currentStep
                  ? "bg-green-500 text-white"
                  : "bg-gray-100 text-gray-500"
              }`}
            >
              {index < currentStep ? <CheckCircle size={20} /> : step.icon}
            </div>
            <div>
              <h3 className="W2STMedium text-gray-900">{step.title}</h3>
              <p className="text-sm text-gray-500">{step.description}</p>
            </div>
          </div>
          {index === 1 && (
            <Link
              to={index === currentStep ? "/tutor/availability" : "#"}
              onClick={(e) => {
                if (index !== currentStep) e.preventDefault();
              }}
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                index === currentStep
                  ? "bg-black text-white"
                  : "bg-gray-200 text-gray-500 cursor-not-allowed pointer-events-none"
              }`}
            >
              Start
            </Link>
          )}
          {index === 3 && (
            <button
              disabled={currentStep !== 3 || isLoading || isOpening}
              onClick={handleClick}
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                index === currentStep
                  ? "bg-black text-white"
                  : "bg-gray-200 text-gray-500 cursor-not-allowed pointer-events-none"
              }`}
            >
              Start
            </button>
          )}

          {/* {index !== 2 && (
            <button
              disabled={index !== currentStep}
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                index === currentStep
                  ? "bg-black text-white"
                  : "bg-gray-200 text-gray-500 cursor-not-allowed"
              }`}
            >
              Start
            </button>
          )} */}
        </div>
      ))}
    </div>
  );
};

export default VerificationProgress;
