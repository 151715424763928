import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetTutorAvailabilityQuery = ({ tutorId, month }: any) => {
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useQuery({
    queryKey: ["getTutorAvailability", tutorId, month],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");

      const response = await axiosInstance.get(
        `tutors/${tutorId}/availability`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { month },
        }
      );

      return response.data;
    },
    onSuccess: (data) => {
      console.log("Success Getting Tutors ==>", data);
    },
    onError: (error: unknown) => {
      console.error("Failed Getting Tutors ==>", error);
    },
    enabled: !!token,
  });
};
