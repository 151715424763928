import { message } from "antd";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

axiosInstance.interceptors.request.use(
  async (config: any) => {
    try {
      const stringifyToken = localStorage.getItem("W2ST_STUDENT_TOKEN");

      const tokenParsed = stringifyToken
        ? JSON.parse(stringifyToken).accessToken
        : null;

      if (tokenParsed && typeof tokenParsed === "string" && config.headers) {
        config.headers.authorization = `Bearer ${tokenParsed}`;
      }
    } catch (error) {
      console.error("Error parsing token:", error);
    }
    return config;
  },
  async (error) => {
    message.error("Request error: " + error.message);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401 || status === 440) {
        localStorage.removeItem("W2ST_STUDENT_TOKEN");
        localStorage.removeItem("hasSeenWelcomeLoader");
        message.success("Session expired, proceed to login");
        window.location.href = "/student/signin";
      }
    }
    return Promise.reject(error);
  }
);
