import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

export const useCheckStripeStatus = (setStatus: any) => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useQuery({
    queryKey: "gettutor",
    queryFn: async () => {
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await axiosInstance.get("/tutors/check-payout-enabled", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onSuccess: (data) => {
      message.success(data?.message);
      setStatus(true);
    },
    onError: (error: { message: string }) => {
      message.error(error.message);
      console.error("Error fetching user data:", error);
    },
  });
};
