import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";

export const useConfirmTutorPassword = () => {
  return useMutation(
    (validation: any) =>
      axiosInstance
        .post("/tutors/confirm-reset", validation)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Login failed. Please try again."
        );
      },
    }
  );
};
