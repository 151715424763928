import React from "react";
import StudentDashboard from "../../../layout/StudentDashboard";
import Back from "../../../components/Back";
import { useParams } from "react-router-dom";

const StudentTutorDisplayPage = () => {
  const { id } = useParams();

  return (
    <StudentDashboard>
      <Back />
      {/* <div className="mt-3">{JSON.stringify(data)} </div> */}
      <div></div>
      {/* StudentTutorDisplayPage <p>{JSON.stringify(id)}</p> */}
      {/* <p></p> */}
    </StudentDashboard>
  );
};

export default StudentTutorDisplayPage;
