import React from "react";
import Layout from "./Public/Components/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <section className="container mx-auto py-20 px-4 text-gray-800">
        <h1 className="text-[20px] W2STMedium mb-8">Privacy Policy</h1>

        <div className="space-y-6">
          <p>
            Way2SuccessTutors ("we", "us", "our") respects your privacy and is
            committed to protecting your personal information. By using our
            services, you agree to this Privacy Policy.
          </p>

          <h2 className="text-2xl font-semibold">
            Privacy Policy for Parents of Children under the Age of 16
          </h2>
          <p>
            Learners under the age of 16 must have parental or guardian consent
            to use our services. We may collect some information automatically
            (via cookies), such as IP addresses and website activity. Consent is
            required for all data collection.
          </p>

          <h2 className="text-2xl font-semibold">
            Types of Information We Collect
          </h2>
          <ul className="list-disc pl-6">
            <li>
              Personal Identifiers (name, email, phone number, address, etc.)
            </li>
            <li>Background information (transcripts, certifications, etc.)</li>
            <li>
              User history (search history, chat sessions, scheduled sessions)
            </li>
            <li>Credit card history for financial transactions</li>
            <li>
              Customer support calls may be recorded for quality assurance.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold">
            How We Use Your Information
          </h2>
          <ul className="list-disc pl-6">
            <li>
              To improve user experience and customize our tutoring services
            </li>
            <li>To track and manage user activity</li>
            <li>To create and manage accounts</li>
            <li>To manage financial records and transactions</li>
            <li>To detect and prevent illegal activities or misuse</li>
          </ul>

          <p>
            We do not share or sell personal information to third parties. Any
            external links on our website are not covered by this privacy
            policy.
          </p>

          <h2 className="text-2xl font-semibold">Disclaimer</h2>
          <p>
            Our privacy policy may change over time. You will be notified via
            email of any updates, and it's your responsibility to review the
            changes.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
