import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../../../layout/AuthLayout";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useRegisterTutorMutation } from "../../../../hooks/api/Tutors/Auth/useRegisterTutorMutation";
import { Eye, EyeOff } from "lucide-react";
import Button from "../../../../components/Button";

const TutorSignUp: React.FC = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { mutateAsync, isLoading } = useRegisterTutorMutation();

  const handleSubmit = async () => {
    try {
      await mutateAsync(payload);
    } catch (error) {
      console.error("Error registering tutor:", error);
    }
  };

  const isFormComplete = (formData: Record<string, string>): boolean => {
    return Object.values(formData).every((value) => value.trim() !== "");
  };

  return (
    <AuthLayout
      heading="The only Tutoring profile you'll ever need."
      desc="Whether you are a full-time tutor or just interested in earning extra cash, we have a job that fits your goals. Join our tutoring service to create new teaching opportunities that align with your expertise and schedule."
    >
      <div className="md:w-8/12 w-11/12 mx-auto overflow-y-scroll">
        <div className="mx-auto">
          <h3 className="text-gray900 text-txs W2STMedium mb-3">
            Create an account
          </h3>

          <div className="flex gap-3 mb-5">
            <div className="w-full">
              <label htmlFor="firstname" className="text-gray700">
                First Name
              </label>
              <input
                id="firstname"
                name="firstName"
                type="text"
                disabled={isLoading}
                value={payload.firstName}
                placeholder="Enter first name"
                className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label htmlFor="lastname" className="text-gray700">
                Last Name
              </label>
              <input
                id="lastname"
                name="lastName"
                type="text"
                disabled={isLoading}
                value={payload.lastName}
                placeholder="Enter last name"
                className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full mb-5">
            <label htmlFor="email" className="text-gray700">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              disabled={isLoading}
              value={payload.email}
              placeholder="Enter your email"
              className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
              onChange={handleChange}
            />
          </div>
          <div className="w-full mb-5">
            <label htmlFor="phone" className="text-gray700">
              Phone
            </label>
            {/* <input
              id="phone"
              name="phoneNumber"
              type="tel"
              value={payload.phoneNumber}
              placeholder="Enter your phone number"
              className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
              onChange={handleChange}
            /> */}
            <PhoneInput
              defaultCountry="us"
              className="mt-[13px]"
              name="phoneNumber"
              disabled={isLoading}
              value={payload?.phoneNumber}
              onChange={(phone) => {
                setPayload({
                  ...payload,
                  phoneNumber: phone,
                });
              }}
            />
          </div>
          <div className="w-full">
            <label htmlFor="password" className="text-gray700">
              Create password
            </label>
            <div className="relative">
              <input
                id="password"
                name="password"
                type={passwordVisibility ? "text" : "password"}
                value={payload.password}
                disabled={isLoading}
                placeholder="Enter your password"
                className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
                onChange={handleChange}
              />
              <span
                className="absolute right-4 top-5 flex text-gray400 items-center cursor-pointer"
                onClick={() => setPasswordVisibility(!passwordVisibility)}
              >
                {passwordVisibility ? <EyeOff size={20} /> : <Eye size={20} />}
              </span>
            </div>
          </div>
          <div className="block">
            {/* <button
              onClick={handleSubmit}
              className={`block text-center mt-4 py-5 w-full mb-4 rounded-md text-white text-md bg-primary500 ${
                isLoading && "opacity-50"
              }`}
              disabled={isLoading || !Object.values(payload).every(Boolean)}
            >
              {isLoading ? "Creating Account..." : "Create Account"}
            </button> */}
            <Button
              label="Create Account"
              isFormValid={isFormComplete(payload)}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
            />
          </div>
          <p className="mt-10 text-gray500">
            By continuing, you agree to the{" "}
            <Link
              to="/terms"
              target="_blank"
              className="text-primary400 underline"
            >
              Terms of use
            </Link>
            ,{" "}
            <Link
              to="/privacy"
              target="_blank"
              className="text-primary400 underline"
            >
              Privacy Policy
            </Link>
            , and Community Standards of Way2sucesstutors.
          </p>
          <div className="text-center mt-10">
            <Link to="/tutor/signin" className="text-gray500">
              Already have an account?{" "}
              <span className="text-primary700 W2STMedium">Log in</span>
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default TutorSignUp;
