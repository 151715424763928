import { useMutation } from "react-query";
import axios from "axios";
import { message } from "antd";

interface Props {
  email: string;
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const useResetStudentPassword = (setStep: any) => {
  return useMutation(
    (payload: Props) =>
      apiClient.post("/auth/reset-password", payload).then((res) => res.data),
    {
      onSuccess: () => {
        setStep(1);
        message.success("An OTP has been sent to your email");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Reset failed. Please try again."
        );
      },
    }
  );
};
