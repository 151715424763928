import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

export const useSendConversationsMutation = ({
  id,
  setMessageInput,
}: {
  id: string;
  setMessageInput: any;
}) => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useMutation(
    (payload: { message: string; sender: string; type: string }) =>
      axiosInstance
        .patch(`conversations/bookings/${id}/send-message-tutor`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries("getConversationsQueryTutor");
        setMessageInput("");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Failed... Please try again."
        );
      },
    }
  );
};
