import React, { useState } from "react";
import StudentDashboard from "../../../layout/StudentDashboard";
import { useGetBookingsQuery } from "../../../hooks/api/Students/Bookings/useGetBookingsQuery";
import TutorShipTypeTag from "../../../components/TutorshipTypeTag";
import { ColumnsType } from "antd/es/table";
import StatusTag from "../../../components/StatusTag";
import Loader from "../../../components/Loader";
import { Button, Drawer, Input } from "antd";
import ChatBox from "./ChatBox";
import { useSendConversationsMutation } from "../../../hooks/api/Students/Conversations/useSendConversationsMutation";
import SessionCard from "./SessionCard";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../components/PrimaryButton";

const StudentBookings = () => {
  const { data, isLoading } = useGetBookingsQuery();
  const [chatVisible, setChatVisible] = useState(false);
  const [tutor, setTutor] = useState("");

  const [messageInput, setMessageInput] = useState("");

  interface Booking {
    id: string;
    subject: string;
    tutorShipType: string;
    student: string;
    tutor: any;
    date: string;
    timeSlots: string[];
    status: string;
    price: number;
    duration: number;
    notes: string[];
  }

  const isSessionEnded = (date: string, duration: number) => {
    const sessionEndTime = new Date(date).getTime() + duration * 60000;
    return Date.now() >= sessionEndTime;
  };

  const isChatEnabled = (date: string) => {
    const sessionStartTime = new Date(date).getTime();
    return Date.now() >= sessionStartTime - 60 * 60000;
  };
  const [selectedId, setSelectedId] = useState("");
  const handleChatClick = (record: Booking) => {
    // if (!isChatEnabled(record.date)) {
    //   message.info("It'll be available 1 hour to the session");
    // } else {
    setSelectedId(record.id);
    setChatVisible(true);
    // }
  };

  const { mutateAsync: sendMessage, isLoading: isSendingMessage } =
    useSendConversationsMutation({
      id: selectedId,
      setMessageInput,
    });

  const handleSendMessage = () => {
    sendMessage({ message: messageInput, sender: "Student", type: "text" });
  };

  const columns: ColumnsType<Booking> = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text: string) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Tutorship Type",
      dataIndex: "tutorShipType",
      key: "tutorShipType",
      render: (type: "Virtual" | "One-on-one" | "Both") => (
        <TutorShipTypeTag type={type} />
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: "Time Slots",
      dataIndex: "timeSlots",
      key: "timeSlots",
      render: (slots) => slots.join(", "),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => <StatusTag status={status} />,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: string) => <span>${price}</span>,
    },
    {
      title: "Duration (mins)",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (notes) => notes.join(", "),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: Booking) => {
        const sessionEnded = isSessionEnded(record.date, record.duration);
        return (
          <div className="flex items-center gap-2">
            <Button type="primary" disabled={!sessionEnded}>
              Mark As Completed
            </Button>
            <Button
              type="primary"
              disabled={record?.status !== "confirmed"}
              onClick={() => {
                handleChatClick(record);
                setTutor(record?.tutor?.firstName);
              }}
            >
              Chat
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <StudentDashboard>
      {isLoading && !data && <Loader />}
      {!isLoading && data && (
        <div>
          <div className="flex justify-between items-center">
            <h3 className="W2STBold text-[20px] my-4">
              Bookings ({data?.data?.meta?.totalDocs || 0})
            </h3>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-[10px]">
            {data?.data?.docs?.map(
              ({
                id,
                subject,
                tutorShipType,
                date,
                timeSlots,
                tutor,
                status,
                price,
                notes,
                duration,
              }: any) => (
                <div key={notes} className="">
                  <SessionCard
                    id={id}
                    subject={subject}
                    tutorShipType={tutorShipType}
                    date={date}
                    tutor={tutor}
                    duration={duration}
                    timeSlots={timeSlots}
                    status={status}
                    price={price}
                    notes={notes}
                  />
                </div>
              )
            )}
          </div>
        </div>
      )}

      {!isLoading && data && data?.data?.meta?.totalDocs === 0 && (
        <div className="text-center bg-white rounded-md p-20">
          <h3 className="font-bold">You don’t have any bookings yet.</h3>
          <div className="mb-3">Proceed to book a tutor</div>
          <Link to="/student/tutors">
            <PrimaryButton title={"Get a tutor"} loading={false} />
          </Link>
        </div>
      )}

      <Drawer
        title={`Chat with ${tutor}`}
        placement="right"
        width={380}
        onClose={() => {
          setChatVisible(false);
          setTutor("");
        }}
        open={chatVisible}
      >
        <ChatBox id={selectedId} />
        <div className="mt-4 flex gap-2">
          <Input
            value={messageInput}
            size="large"
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="Type a message"
            disabled={isSendingMessage}
          />
          <Button
            type="primary"
            onClick={handleSendMessage}
            disabled={isSendingMessage}
          >
            Send
          </Button>
        </div>
      </Drawer>
    </StudentDashboard>
  );
};

export default StudentBookings;
