import { Calendar, Clock2, Hourglass } from "lucide-react";
import { Button, Divider, Drawer, Modal } from "antd";
import clsx from "clsx";
import { useState } from "react";
import Input from "antd/es/input/Input";
import { useGetSingleBookingQuery } from "../../../hooks/api/Students/Bookings/useGetSingleBookingQuery";
import { useAdminSendConversationsMutation } from "../../../hooks/api/Admin/Bookings/useAdminSendConversationsMutation";
import TutorShipTypeTag from "../../../components/TutorshipTypeTag";
import StatusTag from "../../../components/StatusTag";
import { Link } from "react-router-dom";
import AdminChatBox from "./AdminChatBox";
import { MessageOutlined } from "@ant-design/icons";
import { useIntiatePayout } from "../../../hooks/api/Admin/Stripe/useIntiatePayout";
import Moment from "react-moment";

type Props = {
  id: string;
  subject?: any;
  tutorShipType?: any;
  date?: any;
  tutor: any;
  timeSlots?: any;
  status?: "confirmed" | "pending" | "cancelled" | string;
  price?: any;
  duration: any;
  notes?: any;
  student?: any;
  isPaidOut?: any;
};

const STATUS_COLORS: Record<string, string> = {
  confirmed: "bg-green-100 text-green-700",
  pending: "bg-yellow-100 text-yellow-700",
  cancelled: "bg-red-100 text-red-700",
  default: "bg-gray-100 text-gray-700",
};

const AdminBookingSessionCard = ({
  id,
  subject,
  tutorShipType,
  date,
  timeSlots,
  tutor,
  status = "default",
  duration,
  student,
  isPaidOut,
}: Props) => {
  const isSessionEnded = (date: string, duration: number) => {
    const sessionEndTime = new Date(date).getTime() + duration * 60000;
    return Date.now() >= sessionEndTime;
  };

  const [chatVisible, setChatVisible] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<any>(null);

  const [selectedId, setSelectedId] = useState("");

  const { mutateAsync: sendMessage, isLoading: isSendingMessage } =
    useAdminSendConversationsMutation({
      id: selectedId,
      setMessageInput,
    });

  const handleChatClick = (id: string) => {
    setSelectedId(id);
    setChatVisible(true);
  };

  const handleSendMessage = () => {
    sendMessage({ message: messageInput, sender: "Admin", type: "text" });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedBooking(null);
  };

  const { mutate } = useIntiatePayout();

  const handleSubmit = () => {
    mutate(id);
  };

  const handleInitiatePayout = (id: any) => {
    console.log("ID ===>", id);
    Modal.confirm({
      title: "Are you sure?",
      content: "Kindly note that this is an irreversible process",
      onOk: () => handleSubmit(),
    });
  };

  return (
    <div className="relative p-6 rounded-lg border border-gray-200 bg-white shadow-sm">
      <div
        className={clsx(
          "absolute top-3 right-3 flex items-center gap-2 px-3 py-1 rounded-full text-[12px] font-semibold",
          STATUS_COLORS[status] || STATUS_COLORS.default
        )}
      >
        {status === "confirmed" && (
          <span className="h-2 w-2 bg-green-500 rounded-full animate-ping" />
        )}
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </div>
      <h3 className="text-[18px] W2STBold mb-3">Session Details</h3>
      <h3 className="text-lg mb-1">
        Tutor:{" "}
        <Link
          to={`/admin/tutors/${tutor?.id}`}
          className="underline text-purple-500"
        >
          {tutor?.firstName}
        </Link>
      </h3>
      <h3 className="text-lg mb-3">
        Student:{" "}
        <Link
          to={`/admin/students/${student?.id}`}
          className="underline text-purple-500"
        >
          {student?.firstName}
        </Link>
      </h3>
      <div className="text-gray-500 mt-4">
        <span className="capitalize">{subject}</span> |{" "}
        <TutorShipTypeTag type={tutorShipType} /> | Duration:{" "}
        {timeSlots.join(", ")}
      </div>
      <div className="flex gap-5 mt-5">
        <span className="flex gap-1.5 items-center">
          <Calendar color="#344054" size={20} />
          <span className="text-gray-500">
            {new Date(date).toLocaleDateString("en-US", {
              weekday: "short",
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </span>
        </span>
        <span className="flex gap-1.5 items-center">
          <Clock2 color="#344054" size={20} />
          <span className="text-gray-500">
            {new Date(date).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </span>
        </span>
        <span className="flex gap-1.5 items-center">
          <Hourglass color="#344054" size={20} />
          <Moment fromNow className="text-gray-500">
            {date}
          </Moment>
        </span>
      </div>
      <div className="mt-3"></div>
      <Divider />
      <div className="flex gap-2 mt-4">
        <Button
          type="primary"
          icon={<MessageOutlined />}
          disabled={status === "pending"}
          onClick={() => handleChatClick(id)}
        >
          Chat Tutor
        </Button>
        {status === "completed" && (
          <Button
            type="default"
            disabled={isPaidOut}
            onClick={handleInitiatePayout}
          >
            Initiate Payout
          </Button>
        )}
      </div>
      <Modal
        title="Booking Confirmation"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        {selectedBooking && (
          <div className="mt-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Subject:</strong>
                  <span className="capitalize text-gray-700">
                    {selectedBooking.subject}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Tutorship Type:</strong>{" "}
                  <TutorShipTypeTag type={selectedBooking.tutorShipType} />
                </p>
              </div>
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Booking ID:</strong>{" "}
                  {selectedBooking.id}
                </p>
              </div>
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Date:</strong>
                  {new Date(selectedBooking.date).toLocaleString()}
                </p>
              </div>
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Time Slots:</strong>
                  {selectedBooking.timeSlots.join(", ")}
                </p>
              </div>
              <div>
                <p className="text-gray-600 flex items-center">
                  <strong className="text-gray-800 mr-3">Status:</strong>
                  <StatusTag status={selectedBooking.status} />
                </p>
              </div>
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Price:</strong>
                  <span className="text-green-600 font-semibold">
                    ${selectedBooking.price}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Duration:</strong>{" "}
                  {selectedBooking.duration} mins
                </p>
              </div>
              <div className="col-span-2">
                <p className="text-gray-600">
                  <strong className="text-gray-800">Notes:</strong>{" "}
                  {selectedBooking?.notes || "n/a"}
                </p>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Drawer
        title={`Chat between ${tutor?.firstName} and ${student?.firstName}`}
        placement="right"
        width={350}
        onClose={() => setChatVisible(false)}
        open={chatVisible}
      >
        <AdminChatBox id={selectedId} />
        <div className="mt-4 flex gap-2">
          <Input
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="Type a message..."
            disabled={isSendingMessage}
          />
          <Button
            type="primary"
            onClick={handleSendMessage}
            disabled={isSendingMessage}
          >
            Send
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default AdminBookingSessionCard;
