import React, { useState } from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { useGetSingleTutorsonAdmin } from "../../../hooks/api/Admin/Tutors/useGetSingleTutoronAdmin";
import { useParams } from "react-router-dom";
import Back from "../../../components/Back";
import Card from "../../../components/Card";
import ProfileCard from "./components/ProfileCard";
import Options from "./components/Options";
import EducationCard from "./components/EducationCard";
import ExperienceCard from "./components/ExperienceCard";
import { useVerifyTutoronAdmin } from "../../../hooks/api/Admin/Tutors/useVerifyTutoronAdmin";
import Loader from "../../../components/Loader";
import { Empty, message, Modal, Tabs, TabsProps } from "antd";
import { LocationIcon } from "../../../components/Icon/LocationIcon";
import { VirtualIcon } from "../../../components/Icon/VirtualIcon";
import { Award, BriefcaseBusiness, Pencil } from "lucide-react";
import AvailabilityCard from "../../../components/AvailabilityCard";
import { useSuspendTutoronAdmin } from "../../../hooks/api/Admin/Tutors/useSuspendTutoronAdmin";
import EmptyState from "../../../components/EmptyState";

const TutorDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSingleTutorsonAdmin(`${id}`);
  const [open, setOpen] = useState(false);

  const empty = require("../../../assets/images/empty.png");

  const { mutateAsync, isLoading: isVerifying } = useVerifyTutoronAdmin(
    `${id}`
  );
  const { mutateAsync: suspend, isLoading: isSuspending } =
    useSuspendTutoronAdmin(`${id}`);

  const handleVerification = () => {
    mutateAsync(data, {
      onSuccess: () => {
        setOpen(false);
      },
      onError: (error) => {
        console.error("Verification failed:", error);
      },
    });
  };

  const handleSuspension = () => {
    suspend();
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Profile",
      children: (
        <section className="w2STLight">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold text-gray900 ">Tutor Profile</h1>
            {data?.data?.isEmailVerified && !data?.data?.isAccountVerified ? (
              <button
                onClick={() => {
                  if (data?.data?.isEmailVerified === false) {
                    message.warning(
                      "Tutor has to verify their email before an admin can verify them"
                    );
                  } else setOpen(true);
                }}
                className="py-2 px-6 rounded-md bg-green-500 text-white"
              >
                Verify
              </button>
            ) : (
              <button
                onClick={handleSuspension}
                className="py-2 px-6 rounded-md bg-red-500 text-white"
              >
                Suspend
              </button>
            )}
          </div>
          <Modal
            open={open}
            width={400}
            footer={null}
            closeIcon={null}
            centered
          >
            <div className="">
              <h2 className="text-xl font-medium mb-4">Verify Tutor</h2>
              <p className="text-gray-600 mb-6">
                Confirm if you would like to verify this tutor.
              </p>
              <div className="flex gap-4">
                <button
                  className="py-2 px-4 rounded-md border border-[#B42318] w-1/2 text-[#B42318] hover:bg-[#FEE2E2] transition-colors"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button
                  onClick={() => handleVerification()}
                  className={`py-2 px-4 rounded-md bg-[#027A48] w-1/2 text-white hover:bg-[#046C3E] transition-colors ${
                    isVerifying ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isVerifying}
                >
                  {isVerifying ? "Verifying..." : "Verify"}
                </button>
              </div>
            </div>
          </Modal>
          {isLoading && <Loader />}
          {!isLoading && data && (
            <section className="mt-10 flex gap-4">
              <section className="w-[30%]">
                <ProfileCard data={data} />
                <Options data={data?.data} />
              </section>
              <section className="w-[70%]">
                <Card width="12" style={{ marginBottom: "10px" }}>
                  <div className="flex justify-between items-center">
                    <h1 className="text-[20px] W2STMedium text-gray700 mb-3">
                      {data?.data?.firstName + " " + data?.data?.lastName}{" "}
                    </h1>
                    {/* <span className="text-primary600 flex gap-3 items-center cursor-pointer">
                      <Pencil size={20} /> Edit
                    </span> */}
                  </div>
                  <div className="mt-2">
                    <div className="flex gap-3 items-center">
                      <BriefcaseBusiness
                        size={20}
                        style={{ color: "text-gray700" }}
                      />{" "}
                      <span className="text-gray500 text-sm">
                        {data && data?.data?.professionalTitle}
                      </span>
                    </div>
                  </div>
                  {/* <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                  <Award />
                  <span className="text-gray500">15 sessions (5 reviews)</span>
                </div> */}
                  <div className="mt-3">
                    <p className="font-medium text-gray700">Available for:</p>
                    {data?.data?.availabilityType && (
                      <div className="mt-3 flex flex-wrap gap-3 overflow-auto">
                        {["Both", "One-on-one"].includes(
                          data.data.availabilityType
                        ) && (
                          <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 text-sm flex items-center gap-2">
                            <LocationIcon />
                            <span className="text-[12px]">
                              One-on-One Tutoring
                            </span>
                          </span>
                        )}
                        {["Both", "Virtual"].includes(
                          data.data.availabilityType
                        ) && (
                          <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 text-sm flex items-center gap-2">
                            <VirtualIcon />
                            <span className="text-[12px]">
                              Virtual Tutoring
                            </span>
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    <p className="text-gray700 text-sm font-medium">
                      Hourly rate
                    </p>
                    <h3 className="W2STMedium text-md text-gray900">
                      ${data && data?.data?.hourlyRate}/hr
                    </h3>
                  </div>
                </Card>
                {/* <Card width="12">
              <div className="flex justify-between items-center">
                <h1 className="text-xl W2STBold text-gray700 mb-3">About</h1>
                <span className="text-primary600 flex gap-3 items-center cursor-pointer">
                  <Pencil size={20} /> Edit
                </span>
              </div>
              <p className="text-gray500 my-3">
                Working as a tutor at Kumon Centers and teaching students
                privatelt for over 13 years. I have learned to adapt to a
                student's learning to not only help you understand hoe to do
                your homework but to work independently with confidence after
                your lesson. I have recieved numerous positive comments and
                feedback from students of all ages at different stages of their
                career as a student.
              </p>
            </Card> */}
                {data?.data?.education && (
                  <Card width="12" style={{ marginTop: "10px" }}>
                    <div className="flex justify-between items-center">
                      <h1 className="text-xl W2STBold text-gray700 mb-3">
                        Education
                      </h1>
                    </div>
                    {data &&
                    data?.data?.education &&
                    data?.data?.education.length > 0 ? (
                      data?.data?.education.map(
                        ({
                          college,
                          degree,
                          enrolled,
                          graduated,
                          _id,
                        }: any) => (
                          <div key={_id}>
                            <EducationCard
                              id={_id}
                              degree={degree}
                              college={college}
                              enrolled={enrolled}
                              graduated={graduated}
                              educationCount={data?.data?.education.length}
                            />
                          </div>
                        )
                      )
                    ) : (
                      <EmptyState title="No Educational Qualification Data" />
                    )}
                  </Card>
                )}
                <Card width="12" style={{ marginTop: "10px" }}>
                  <div className="flex justify-between items-center">
                    <h1 className="text-xl W2STBold text-gray700 mb-3">
                      Work Experience
                    </h1>
                  </div>
                  <div>
                    {data &&
                    data?.data?.workExperience &&
                    data?.data?.workExperience.length > 0 ? (
                      data?.data?.workExperience.map(
                        ({ title, company, startDate, endDate, _id }: any) => (
                          <div key={_id}>
                            <ExperienceCard
                              title={title}
                              company={company}
                              startDate={startDate}
                              endDate={endDate}
                            />
                          </div>
                        )
                      )
                    ) : (
                      <Empty />
                    )}
                  </div>
                </Card>
              </section>
            </section>
          )}
        </section>
      ),
      // <pre className="text-[5px] w-[200px]">
      //   {JSON.stringify(selectedTutor?.selectedTutor?.weeklyAvailability)}
      // </pre>
    },
    {
      key: "4",
      label: "Availability",
      children: (
        <Card width="12" style={{ marginTop: "10px" }}>
          <div className="space-y-4">
            {data?.data?.weeklyAvailability?.length > 0 ? (
              data.data.weeklyAvailability.map(({ day, timeSlots }: any) => (
                <div key={day} className="p-4 border rounded-lg shadow-sm">
                  <h3 className="text-lg font-semibold text-gray-800">{day}</h3>
                  {timeSlots.length > 0 ? (
                    <div className="mt-2 flex flex-wrap gap-2">
                      {timeSlots.map(
                        ({ startTime, endTime }: any, index: number) => (
                          <span
                            key={index}
                            className="px-3 py-1 bg-primary500 text-white text-sm rounded-md"
                          >
                            {startTime} - {endTime}
                          </span>
                        )
                      )}
                    </div>
                  ) : (
                    <p className="text-gray-500 text-sm mt-2">
                      No availability
                    </p>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500">
                <Empty />
                <p className="mt-2 text-sm">No availability set yet.</p>
              </div>
            )}
          </div>
        </Card>
      ),
    },
    {
      key: "2",
      label: "Reviews",
      children: (
        <div className="bg-white rounded-md py-[50px] mb-3 flex flex-col items-center justify-center">
          <img src={empty} alt="" className="h-[50px] w-[50px] mb-3" />
          <h3 className="W2STBold">Coming Soon</h3>
        </div>
      ),
    },
  ];

  return (
    <AdminDashboardLayout>
      <Back />

      <Tabs defaultActiveKey="1" items={items} />
    </AdminDashboardLayout>
  );
};

export default TutorDetails;
