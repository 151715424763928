const DisplaySocials = (data: any) => {
  const Twitter = require("../assets/images/twitter.png");
  const LinkedIn = require("../assets/images/linkedin.png");
  const Web = require("../assets/images/web.png");
  const Facebook = require("../assets/socials/fb.png");

  const profileLinks = data?.data?.data?.data?.profileLinks || {};

  const hasLinks = Object.values(profileLinks).some(
    (link) => link && link !== ""
  );

  return (
    <div className="w-[50%] mx-auto flex justify-center mt-3 items-center">
      {hasLinks ? (
        <div className="flex justify-between w-full">
          {profileLinks.x && (
            <a
              href={profileLinks.x}
              target="_blank"
              className="p-1 rounded-full cursor-pointer"
              rel="noreferrer"
            >
              <img src={Twitter} alt="Twitter" className="h-[30px] w-[30px]" />
            </a>
          )}
          {profileLinks.linkedIn && (
            <a
              href={profileLinks.linkedIn}
              target="_blank"
              className="p-1 rounded-full cursor-pointer"
              rel="noreferrer"
            >
              <img
                src={LinkedIn}
                alt="LinkedIn"
                className="h-[30px] w-[30px]"
              />
            </a>
          )}
          {profileLinks.facebook && (
            <a
              href={profileLinks.facebook}
              target="_blank"
              className="flex items-center justify-center h-8 w-8 rounded-full cursor-pointer border border-[#ddd] overflow-hidden p-1"
              rel="noreferrer"
            >
              <img src={Facebook} alt="Facebook" className="h-4 w-4" />
            </a>
          )}
          {profileLinks.personalWebsite && (
            <a
              href={profileLinks.personalWebsite}
              target="_blank"
              className="p-1 rounded-full cursor-pointer"
              rel="noreferrer"
            >
              <img src={Web} alt="Website" className="h-[30px] w-[30px]" />
            </a>
          )}
        </div>
      ) : (
        <p className="text-gray-500 text-sm text-center">
          No social media links
        </p>
      )}
    </div>
  );
};

export default DisplaySocials;
