import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

export const useSuspendTutoronAdmin = (id: string) => {
  const token = localStorage.getItem("W2ST_ADMIN_TOKEN");

  return useMutation(
    () =>
      axiosInstance
        .patch(
          `admins/tutors/${id}/toggle-suspend`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries("useGetSingleTutorsonAdmin");
        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
