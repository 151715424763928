import { Modal } from "antd";
import { Trash2Icon } from "lucide-react";
import React, { useState } from "react";
import Moment from "react-moment";
import { useRemoveEducation } from "../../../../hooks/api/Tutors/UpdateProfile/useRemoveEducation";

type Props = {
  id?: string;
  degree: string;
  college: string;
  enrolled: string;
  graduated: string;
  educationCount: number;
};

const EducationCard = ({
  college,
  degree,
  enrolled,
  graduated,
  id,
  educationCount,
}: Props) => {
  const [active, setActive] = useState(false);

  const { mutate } = useRemoveEducation();

  return (
    <div className="mb-[30px]">
      <p className="text-primary900 mb-1 W2STMedium">{college}</p>
      <p className="text-gray700 mb-1">{degree}</p>
      <div className="text-gray700">
        <Moment format="YYYY" interval={0}>
          {enrolled}
        </Moment>{" "}
        -{" "}
        <Moment format="YYYY" interval={0}>
          {graduated}
        </Moment>
      </div>
      {educationCount > 1 && (
        <div className="mt-3">
          <button
            onClick={() => setActive(true)}
            className="flex gap-2 items-center text-red-700 text-[12px] cursor-pointer rounded-md"
          >
            <Trash2Icon size={15} />
            <span>Delete</span>
          </button>
        </div>
      )}
      {/* <div className="flex gap-5 mt-3">
        <span className="flex gap-2 items-center text-primary600 cursor-pointer">
          <AiOutlineEdit className="text-2xl" />
          <span>Edit</span>
        </span>
        <span className="flex gap-2 items-center text-error500 cursor-pointer">
          <FaRegTrashCan />
          <span>Delete</span>
        </span>
      </div> */}
      <Modal
        width="400px"
        footer={null}
        open={active}
        onCancel={() => setActive(false)}
        title={`Delete Education from ${college}`}
      >
        <p className="text-gray700">
          Confirm that you'd like to delete this educational qualification. Note
          that this process is irreversible
        </p>
        <div className="flex gap-2 mt-4">
          <button
            onClick={() => {
              mutate(id, {
                onSuccess: () => {
                  setActive(false);
                },
              });
            }}
            className="p-3 rounded-md bg-red-600 text-white flex-1"
          >
            Yes
          </button>
          <button
            onClick={() => setActive(false)}
            className="p-3 rounded-md bg-primary100 text-primary700 flex-1"
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EducationCard;
