// import React, { useEffect, useState } from "react";
// import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
// import { Switch } from "antd";
// import { useCreateAvailability } from "../../../hooks/api/Tutors/Availability/useCreateAvailability";
// import { useGetTutorQuery } from "../../../hooks/api/Tutors/Auth/useGetTutorQuery";

// interface TimeSlot {
//   startTime: string;
//   endTime: string;
// }

// interface DayAvailability {
//   day: string;
//   timeSlots: TimeSlot[];
// }

// interface Payload {
//   weeklyAvailability: DayAvailability[];
// }

// const initialState = [
//   { day: "Sunday", timeSlots: [] },
//   { day: "Monday", timeSlots: [] },
//   { day: "Tuesday", timeSlots: [] },
//   { day: "Wednesday", timeSlots: [] },
//   { day: "Thursday", timeSlots: [] },
//   { day: "Friday", timeSlots: [] },
//   { day: "Saturday", timeSlots: [] },
// ];

// const TutorAvailability: React.FC = () => {
//   const { data: tutor } = useGetTutorQuery();
//   const [payload, setPayload] = useState<Payload>({
//     weeklyAvailability: initialState,
//   });

//   useEffect(() => {
//     if (tutor?.data?.weeklyAvailability?.length > 0) {
//       setPayload({ weeklyAvailability: tutor.data.weeklyAvailability });
//     }
//   }, [tutor?.data?.weeklyAvailability]);

//   const { mutateAsync } = useCreateAvailability();

//   const handleSubmit = () => {
//     mutateAsync(payload);
//   };

//   const handleAddTimeSlot = (day: string) => {
//     setPayload((prev) => ({
//       ...prev,
//       weeklyAvailability: prev.weeklyAvailability.map((availability) =>
//         availability.day === day
//           ? {
//               ...availability,
//               timeSlots: [
//                 ...availability.timeSlots,
//                 { startTime: "00:00", endTime: "01:00" }, // Default values
//               ],
//             }
//           : availability
//       ),
//     }));
//   };

//   const handleUpdateTimeSlot = (
//     day: string,
//     index: number,
//     field: "startTime" | "endTime",
//     value: string
//   ) => {
//     setPayload((prev) => ({
//       ...prev,
//       weeklyAvailability: prev.weeklyAvailability.map((availability) =>
//         availability.day === day
//           ? {
//               ...availability,
//               timeSlots: availability.timeSlots.map((slot, i) =>
//                 i === index
//                   ? {
//                       ...slot,
//                       [field]: value,
//                       ...(field === "startTime" && {
//                         endTime: calculateEndTime(value),
//                       }),
//                     }
//                   : slot
//               ),
//             }
//           : availability
//       ),
//     }));
//   };

//   const handleRemoveTimeSlot = (day: string, index: number) => {
//     setPayload((prev) => ({
//       ...prev,
//       weeklyAvailability: prev.weeklyAvailability.map((availability) =>
//         availability.day === day
//           ? {
//               ...availability,
//               timeSlots: availability.timeSlots.filter((_, i) => i !== index),
//             }
//           : availability
//       ),
//     }));
//   };

//   const calculateEndTime = (startTime: string): string => {
//     const [hours, minutes] = startTime.split(":").map(Number);
//     const endHours = (hours + 1) % 24; // Ensure it wraps around after 23
//     return `${String(endHours).padStart(2, "0")}:${String(minutes).padStart(
//       2,
//       "0"
//     )}`;
//   };

//   return (
//     <TutorDashboardLayout>
//       <section className="md:p-4 bg-white rounded-lg my-4 mt-4">
//         <h3 className="text-[20px] W2STMedium">Set Availability</h3>
//         <div className="text-gray500 mt-2">
//           We ensure that sessions are scheduled at your convenience. You have
//           the freedom to personalize your availability, offering complete
//           flexibility.
//         </div>
//         <div className="flex justify-end mt-6">
//           <button
//             onClick={handleSubmit}
//             className="text-white bg-purple-600 px-4 py-3 rounded-md W2STMedium"
//           >
//             Save
//           </button>
//         </div>

//         <div className="section mt-4 p-3">
//           {payload.weeklyAvailability.map((availability) => (
//             <div
//               className="md:flex block items-center py-3 border-b-[1px] border-gray-300"
//               key={availability.day}
//             >
//               <div className="md:w-4/12 flex items-center gap-2 W2STMedium">
//                 <Switch
//                   checked={availability.timeSlots.length > 0}
//                   onChange={(checked) => {
//                     setPayload((prev) => ({
//                       ...prev,
//                       weeklyAvailability: prev.weeklyAvailability.map((item) =>
//                         item.day === availability.day
//                           ? {
//                               ...item,
//                               timeSlots: checked
//                                 ? [{ startTime: "00:00", endTime: "01:00" }]
//                                 : [],
//                             }
//                           : item
//                       ),
//                     }));
//                   }}
//                 />
//                 <span>{availability.day.toUpperCase()}</span>
//               </div>
//               <div className="md:w-8/12">
//                 {availability.timeSlots.map((slot, index) => (
//                   <Scheduler
//                     key={index}
//                     day={availability.day}
//                     index={index}
//                     startTime={slot.startTime}
//                     endTime={slot.endTime}
//                     onUpdateTimeSlot={handleUpdateTimeSlot}
//                     onRemoveTimeSlot={handleRemoveTimeSlot}
//                   />
//                 ))}
//                 <button
//                   className="mt-2 text-blue-500 hover:underline"
//                   onClick={() => handleAddTimeSlot(availability.day)}
//                 >
//                   + Add Time Slot
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>
//     </TutorDashboardLayout>
//   );
// };

// interface SchedulerProps {
//   day: string;
//   index: number;
//   startTime: string;
//   endTime: string;
//   onUpdateTimeSlot: (
//     day: string,
//     index: number,
//     field: "startTime" | "endTime",
//     value: string
//   ) => void;
//   onRemoveTimeSlot: (day: string, index: number) => void;
// }

// const Scheduler: React.FC<SchedulerProps> = ({
//   day,
//   index,
//   startTime,
//   endTime,
//   onUpdateTimeSlot,
//   onRemoveTimeSlot,
// }) => {
//   const times = Array.from(
//     { length: 24 },
//     (_, i) => `${String(i).padStart(2, "0")}:00`
//   );

//   return (
//     <div className="flex items-center justify-between gap-3 mb-2">
//       <div className="md:flex items-center gap-6">
//         <span>Start Time</span>
//         <select
//           name="startTime"
//           className="mt-1 w-[100px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
//           value={startTime}
//           onChange={(e) =>
//             onUpdateTimeSlot(day, index, "startTime", e.target.value)
//           }
//         >
//           {times.map((time) => (
//             <option key={time} value={time}>
//               {time}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div className="md:flex items-center gap-6">
//         <span>End Time</span>
//         <select
//           name="endTime"
//           disabled
//           className="mt-1 w-[100px] bg-gray-200 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
//           value={endTime}
//           onChange={(e) =>
//             onUpdateTimeSlot(day, index, "endTime", e.target.value)
//           }
//         >
//           {times.map((time) => (
//             <option key={time} value={time}>
//               {time}
//             </option>
//           ))}
//         </select>
//       </div>
//       <button
//         className="text-red-500 hover:underline"
//         onClick={() => onRemoveTimeSlot(day, index)}
//       >
//         Remove
//       </button>
//     </div>
//   );
// };

// export default TutorAvailability;

// import React, { useEffect, useState } from "react";
// import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
// import { Switch } from "antd";
// import { useCreateAvailability } from "../../../hooks/api/Tutors/Availability/useCreateAvailability";
// import { useGetTutorQuery } from "../../../hooks/api/Tutors/Auth/useGetTutorQuery";

// interface TimeSlot {
//   startTime: string;
//   endTime: string;
// }

// interface DayAvailability {
//   day: string;
//   timeSlots: TimeSlot[];
// }

// interface Payload {
//   weeklyAvailability: DayAvailability[];
// }

// const initialState = [
//   { day: "Sunday", timeSlots: [] },
//   { day: "Monday", timeSlots: [] },
//   { day: "Tuesday", timeSlots: [] },
//   { day: "Wednesday", timeSlots: [] },
//   { day: "Thursday", timeSlots: [] },
//   { day: "Friday", timeSlots: [] },
//   { day: "Saturday", timeSlots: [] },
// ];

// const TutorAvailability: React.FC = () => {
//   const { data: tutor } = useGetTutorQuery();
//   const [payload, setPayload] = useState<Payload>({
//     weeklyAvailability: initialState,
//   });

//   useEffect(() => {
//     if (tutor?.data?.weeklyAvailability?.length > 0) {
//       setPayload({ weeklyAvailability: tutor.data.weeklyAvailability });
//     }
//   }, [tutor?.data?.weeklyAvailability]);

//   const { mutateAsync } = useCreateAvailability();

//   const handleSubmit = () => {
//     mutateAsync(payload);
//   };

//   const handleAddTimeSlot = (day: string) => {
//     setPayload((prev) => ({
//       ...prev,
//       weeklyAvailability: prev.weeklyAvailability.map((availability) =>
//         availability.day === day
//           ? {
//               ...availability,
//               timeSlots: [
//                 ...availability.timeSlots,
//                 { startTime: "00:00", endTime: "01:00" }, // Default values
//               ],
//             }
//           : availability
//       ),
//     }));
//   };

//   const handleUpdateTimeSlot = (
//     day: string,
//     index: number,
//     field: "startTime" | "endTime",
//     value: string
//   ) => {
//     setPayload((prev) => ({
//       ...prev,
//       weeklyAvailability: prev.weeklyAvailability.map((availability) =>
//         availability.day === day
//           ? {
//               ...availability,
//               timeSlots: availability.timeSlots.map((slot, i) =>
//                 i === index
//                   ? {
//                       ...slot,
//                       [field]: value,
//                       ...(field === "startTime" && {
//                         endTime: calculateEndTime(value),
//                       }),
//                     }
//                   : slot
//               ),
//             }
//           : availability
//       ),
//     }));
//   };

//   const handleRemoveTimeSlot = (day: string, index: number) => {
//     setPayload((prev) => ({
//       ...prev,
//       weeklyAvailability: prev.weeklyAvailability.map((availability) =>
//         availability.day === day
//           ? {
//               ...availability,
//               timeSlots: availability.timeSlots.filter((_, i) => i !== index),
//             }
//           : availability
//       ),
//     }));
//   };

//   const calculateEndTime = (startTime: string): string => {
//     const [hours, minutes] = startTime.split(":").map(Number);
//     const endHours = (hours + 1) % 24; // Ensure it wraps around after 23
//     return `${String(endHours).padStart(2, "0")}:${String(minutes).padStart(
//       2,
//       "0"
//     )}`;
//   };

//   return (
//     <TutorDashboardLayout>
//       <section className="md:p-4 bg-white rounded-lg my-4 mt-4">
//         <h3 className="text-[20px] W2STMedium">Set Availability</h3>
//         <div className="text-gray500 mt-2">
//           We ensure that sessions are scheduled at your convenience. You have
//           the freedom to personalize your availability, offering complete
//           flexibility.
//         </div>
//         <div className="flex justify-end mt-6">
//           <button
//             onClick={handleSubmit}
//             className="text-white bg-purple-600 px-4 py-3 rounded-md W2STMedium"
//           >
//             Save
//           </button>
//         </div>

//         <div className="section mt-4 p-3">
//           {payload.weeklyAvailability.map((availability) => (
//             <div
//               className="md:flex block items-center py-3 border-b-[1px] border-gray-300"
//               key={availability.day}
//             >
//               <div className="md:w-4/12 flex items-center gap-2 W2STMedium">
//                 <Switch
//                   checked={availability.timeSlots.length > 0}
//                   onChange={(checked) => {
//                     setPayload((prev) => ({
//                       ...prev,
//                       weeklyAvailability: prev.weeklyAvailability.map((item) =>
//                         item.day === availability.day
//                           ? {
//                               ...item,
//                               timeSlots: checked
//                                 ? [{ startTime: "00:00", endTime: "01:00" }]
//                                 : [],
//                             }
//                           : item
//                       ),
//                     }));
//                   }}
//                 />
//                 <span>{availability.day.toUpperCase()}</span>
//               </div>
//               <div className="md:w-8/12">
//                 {availability.timeSlots.map((slot, index) => (
//                   <Scheduler
//                     key={index}
//                     day={availability.day}
//                     index={index}
//                     startTime={slot.startTime}
//                     endTime={slot.endTime}
//                     onUpdateTimeSlot={handleUpdateTimeSlot}
//                     onRemoveTimeSlot={handleRemoveTimeSlot}
//                   />
//                 ))}
//                 <button
//                   className="mt-2 text-blue-500 hover:underline"
//                   onClick={() => handleAddTimeSlot(availability.day)}
//                 >
//                   + Add Time Slot
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>
//     </TutorDashboardLayout>
//   );
// };

// interface SchedulerProps {
//   day: string;
//   index: number;
//   startTime: string;
//   endTime: string;
//   onUpdateTimeSlot: (
//     day: string,
//     index: number,
//     field: "startTime" | "endTime",
//     value: string
//   ) => void;
//   onRemoveTimeSlot: (day: string, index: number) => void;
// }

// const Scheduler: React.FC<SchedulerProps> = ({
//   day,
//   index,
//   startTime,
//   endTime,
//   onUpdateTimeSlot,
//   onRemoveTimeSlot,
// }) => {
//   const times = Array.from(
//     { length: 24 },
//     (_, i) => `${String(i).padStart(2, "0")}:00`
//   );

//   return (
//     <div className="flex items-center justify-between gap-3 mb-2">
//       <div className="md:flex items-center gap-6">
//         <span>Start Time</span>
//         <select
//           name="startTime"
//           className="mt-1 w-[100px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
//           value={startTime}
//           onChange={(e) =>
//             onUpdateTimeSlot(day, index, "startTime", e.target.value)
//           }
//         >
//           {times.map((time) => (
//             <option key={time} value={time}>
//               {time}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div className="md:flex items-center gap-6">
//         <span>End Time</span>
//         <select
//           name="endTime"
//           disabled
//           className="mt-1 w-[100px] bg-gray-200 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
//           value={endTime}
//           onChange={(e) =>
//             onUpdateTimeSlot(day, index, "endTime", e.target.value)
//           }
//         >
//           {times.map((time) => (
//             <option key={time} value={time}>
//               {time}
//             </option>
//           ))}
//         </select>
//       </div>
//       <button
//         className="text-red-500 hover:underline"
//         onClick={() => onRemoveTimeSlot(day, index)}
//       >
//         Remove
//       </button>
//     </div>
//   );
// };

// export default TutorAvailability;

import React, { useEffect, useState } from "react";
import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
import { Switch, Modal, message, Button, TimePicker } from "antd";
import { useCreateAvailability } from "../../../hooks/api/Tutors/Availability/useCreateAvailability";
import { useGetTutorQuery } from "../../../hooks/api/Tutors/Auth/useGetTutorQuery";
import dayjs from "dayjs";
import Loader from "../../../components/Loader";

interface TimeSlot {
  startTime: string;
  endTime: string;
}

interface DayAvailability {
  day: string;
  timeSlots: TimeSlot[];
}

interface Payload {
  weeklyAvailability: DayAvailability[];
}

const initialState = [
  { day: "Sunday", timeSlots: [] },
  { day: "Monday", timeSlots: [] },
  { day: "Tuesday", timeSlots: [] },
  { day: "Wednesday", timeSlots: [] },
  { day: "Thursday", timeSlots: [] },
  { day: "Friday", timeSlots: [] },
  { day: "Saturday", timeSlots: [] },
];

const TutorAvailability = () => {
  const { data: tutor } = useGetTutorQuery();
  const [payload, setPayload] = useState<Payload>({
    weeklyAvailability: initialState,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tutor?.data?.weeklyAvailability?.length) {
      setPayload({ weeklyAvailability: tutor.data.weeklyAvailability });
    }
  }, [tutor]);

  const { mutateAsync, isLoading } = useCreateAvailability();

  const handleSubmit = async () => {
    setLoading(true);
    await mutateAsync(payload);
    message.success("Availability saved successfully");
    setLoading(false);
  };

  const handleToggleAvailability = (day: string, checked: boolean) => {
    if (!checked) {
      Modal.confirm({
        title: "Are you sure?",
        content: "Turning this off will remove all time slots for this day.",
        onOk: () => updateAvailability(day, checked),
      });
    } else {
      updateAvailability(day, checked);
    }
  };

  const updateAvailability = (day: string, checked: boolean) => {
    setPayload((prev) => ({
      ...prev,
      weeklyAvailability: prev.weeklyAvailability.map((item) =>
        item.day === day
          ? {
              ...item,
              timeSlots: checked
                ? [{ startTime: "08:00", endTime: "09:00" }]
                : [],
            }
          : item
      ),
    }));
  };

  const handleTimeChange = (day: string, index: number, value: any) => {
    if (value) {
      setPayload((prev) => ({
        ...prev,
        weeklyAvailability: prev.weeklyAvailability.map((item) =>
          item.day === day
            ? {
                ...item,
                timeSlots: item.timeSlots.map((slot, i) =>
                  i === index
                    ? {
                        ...slot,
                        startTime: value.format("HH:00"),
                        endTime: dayjs(value).add(1, "hour").format("HH:00"),
                      }
                    : slot
                ),
              }
            : item
        ),
      }));
    }
  };

  const addTimeSlot = (day: string) => {
    setPayload((prev) => ({
      ...prev,
      weeklyAvailability: prev.weeklyAvailability.map((item) =>
        item.day === day
          ? {
              ...item,
              timeSlots: [
                ...item.timeSlots,
                { startTime: "08:00", endTime: "09:00" },
              ],
            }
          : item
      ),
    }));
  };

  const removeTimeSlot = (day: string, index: number) => {
    setPayload((prev) => ({
      ...prev,
      weeklyAvailability: prev.weeklyAvailability.map((item) =>
        item.day === day
          ? { ...item, timeSlots: item.timeSlots.filter((_, i) => i !== index) }
          : item
      ),
    }));
  };

  return (
    <TutorDashboardLayout>
      <section className="p-6 bg-white rounded-lg shadow-md my-4">
        <h3 className="text-[20px] W2STBold">Set Availability</h3>
        <p className="text-gray-500 mt-2">
          Customize your weekly availability for tutoring sessions.
        </p>

        <div className="flex justify-end mt-6">
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-black shadow-md text-white px-6 py-3 rounded-md font-medium hover:bg-purple-700 transition disabled:opacity-50"
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>

        {isLoading && <Loader />}

        {/* {!isLoading && payload && ( */}
        <div className="mt-4 space-y-4">
          {!isLoading &&
            payload &&
            payload.weeklyAvailability
              // .filter((day) => day.timeSlots.length > 0)
              .map((availability) => (
                <div className="p-4 border rounded-md" key={availability.day}>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center gap-4 font-medium">
                      <Switch
                        checked={availability.timeSlots.length > 0}
                        onChange={(checked) =>
                          handleToggleAvailability(availability.day, checked)
                        }
                      />
                      <span>{availability.day}</span>
                    </div>
                    <Button
                      onClick={() => addTimeSlot(availability.day)}
                      type="primary"
                      size="small"
                    >
                      Add Time Slot
                    </Button>
                  </div>
                  {availability.timeSlots.map((slot, index) => (
                    <div key={index} className="flex items-center gap-4 mt-2">
                      <div className="w-6/12">
                        <div>Start Time:</div>
                        <TimePicker
                          format="HH:00"
                          minuteStep={30}
                          value={dayjs(slot.startTime, "HH:mm")}
                          onChange={(value) =>
                            handleTimeChange(availability.day, index, value)
                          }
                        />
                      </div>
                      <div className="w-6/12">
                        <div>End Time:</div>
                        <TimePicker
                          format="HH:00"
                          value={dayjs(slot.endTime, "HH:mm")}
                          disabled
                        />
                      </div>
                      <Button
                        danger
                        size="small"
                        onClick={() => removeTimeSlot(availability.day, index)}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              ))}
        </div>
      </section>
    </TutorDashboardLayout>
  );
};

export default TutorAvailability;
