import { Empty } from "antd";
import Loader from "../../../components/Loader";
import { useGetInifiniteConversation } from "../../../hooks/api/Tutors/Conversation/useGetInifiniteConversation";
import { useEffect, useMemo, useRef } from "react";

const highlightLinks = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part, index) =>
    urlRegex.test(part) ? (
      <a
        key={index}
        href={part}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-400 underline"
      >
        {part}
      </a>
    ) : (
      part
    )
  );
};

const ChatBox = ({ id }: { id: string }) => {
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useGetInifiniteConversation({ id });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const messages = useMemo(
    () => data?.pages.flatMap((page) => page.data.docs) || [],
    [data]
  );
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex flex-col space-y-3 p-4 h-[80vh] overflow-y-auto bg-[#1E1B30] border rounded-lg text-white scrollbar-none">
      {isLoading && <Loader />}
      {!isLoading && messages.length === 0 && (
        <Empty description="">
          <span className="text-white">No messages yet</span>
        </Empty>
      )}
      <div className="overflow-y-auto min-h-[60vh]">
        {messages
          .slice()
          .reverse()
          .map(({ id, message, sender, deliveredAt }) => {
            const formattedDate = deliveredAt
              ? new Date(deliveredAt).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                  month: "short",
                  day: "numeric",
                })
              : "";

            const isTutor = sender === "Tutor";
            const isAdmin = sender === "Admin";
            const isStudent = sender === "Student";

            return (
              <div
                key={id}
                className={`flex ${
                  isTutor
                    ? "justify-start"
                    : isStudent
                    ? "justify-end"
                    : "justify-center"
                } mb-3`}
              >
                <div
                  className={`rounded-2xl p-3 text-sm shadow-md max-w-[70%] ${
                    isAdmin ? "w-full bg-black text-white" : ""
                  }
                    ${isTutor ? "bg-[#6A4C93] text-white" : ""}
                    ${isStudent ? "bg-[#9B5DE5] text-white self-end" : ""}
                    ${isAdmin ? "bg-[#333333] text-white" : ""}`}
                >
                  <p className="font-bold capitalize">
                    {isAdmin ? "Admin" : isTutor ? "Tutor" : "Student"}
                  </p>
                  <p>{highlightLinks(message)}</p>
                  {formattedDate && (
                    <p className="text-gray-300 mt-1 text-right text-xs">
                      {formattedDate}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ChatBox;
