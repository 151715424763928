import React, { useState } from "react";
import {
  Award,
  BriefcaseBusiness,
  Calendar,
  Clock2,
  MoveRight,
  Star,
  X,
} from "lucide-react";
import StudentDashboard from "../../../layout/StudentDashboard";
import { Link } from "react-router-dom";
import { useGetTutorsQuery } from "../../../hooks/api/Students/Tutors/useGetTutorsQuery";
import { useGetStudentQuery } from "../../../hooks/api/Students/Auth/useGetStudentQuery";
import AvailabilityBadge from "../Tutors/Components/AvailabilityBadge";
import Loader from "../../../components/Loader";
import { Drawer } from "antd";
import { useGetTutorQuery } from "../../../hooks/api/Students/Tutors/useGetTutorQuery";
import TutorBookingForm from "../Tutors/Components/TutorBookingForm";
import EmptyState from "../../../components/EmptyState";

const StudentOverview = () => {
  const { data: student } = useGetStudentQuery();
  const { data: tutors, isLoading } = useGetTutorsQuery({});
  const [id, setId] = useState("");
  const [visible, setVisible] = useState(false);

  const { data: tutorsData, isLoading: tutorsLoading } = useGetTutorQuery({
    id,
  });

  return (
    <StudentDashboard>
      <div className="container md:px-6 px-0">
        <p className="text-txs text-gray900 W2STBold md:mt-0 mt-3">
          Welcome
          {student?.data?.firstName && ", " + student?.data?.firstName}
        </p>
        <div className="pb-6 border-b-[1px] border-b-gray300 text-tmd">
          <span className="text-gray600">No upcoming sessions, </span>
          <Link
            to="/student/tutors"
            className="text-primary500 underline underline-offset-2 cursor-pointer"
          >
            let's find you a tutor
          </Link>
        </div>
        <div className="md:flex grid gap-4 md:mt-10 mt-3 mb-10">
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-primary600 mb-2">
              <span className="rounded-full bg-primary50 p-4 md:text-[30px] text-[20px]]">
                <Calendar />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Sessions booked
              </span>
            </div>
            <p className="text-gray900 W2STBold ml-2 md:text-3xl text-lg">0</p>
          </div>
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-bluelight600 mb-2">
              <span className="rounded-full bg-bluelight50 p-4 md:text-[30px] text-[20px]">
                <Clock2 />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Total session time
              </span>
            </div>
            <p className="text-gray900 W2STBold ml-2 md:text-3xl text-lg">
              0 Mins
            </p>
          </div>
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-warning600 mb-2">
              <span className="rounded-full bg-warning50 p-4 md:text-[30px] text-[20px]">
                <Star />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Average rating
              </span>
            </div>
            <p className="text-gray900 W2STBold ml-2 md:text-3xl text-lg">0</p>
          </div>
        </div>
        <p className="text-txl text-gray900 W2STBold">Suggested Tutors</p>
        <div className="pb-6 border-b-[1px] border-b-gray300 md:flex justify-between">
          <div>
            <span className="text-gray600">
              The suggestions are based on your location{" "}
            </span>
          </div>
          <Link
            to="/student/tutors"
            className="flex gap-3 text-gray500 items-center"
          >
            See more <MoveRight size={20} />
          </Link>
        </div>
        <div className="mb-[100px]">
          {isLoading && <Loader />}
          {tutors && tutors?.data?.docs.length === 0 && (
            <EmptyState
              title="There are no tutors yet."
              desc="Verified tutors would display here soon"
            />
          )}
          <div className="md:grid md:grid-cols-3 gap-4 mt-5">
            {tutors &&
              tutors?.data?.docs.length > 0 &&
              !isLoading &&
              tutors?.data?.docs
                .slice(0, 3)
                .map(
                  ({
                    firstName,
                    lastName,
                    professionalTitle,
                    availabilityType,
                    id,
                    hourlyRate,
                    profilePicture,
                  }: any) => (
                    <div
                      key={id}
                      className="bg-white rounded-md shadow-sm overflow-hidden cursor-pointer"
                      onClick={() => {
                        setId(id);
                        setVisible(true);
                      }}
                    >
                      <img
                        src={profilePicture ? profilePicture : ""}
                        alt="tutor avatar"
                        className="h-[218px] rounded-t-lg w-full"
                      />
                      <div className="p-[16px]">
                        <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                          {firstName + " " + lastName}
                        </h3>
                        <div className="mt-2">
                          <div className="flex gap-3 items-center">
                            <BriefcaseBusiness
                              style={{ color: "text-gray700" }}
                            />
                            <span className="text-gray500 text-sm">
                              {professionalTitle}
                            </span>
                          </div>
                        </div>

                        {/* <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                          <Award />
                          <span className="text-gray500">
                            15 sessions (5 reviews)
                          </span>
                        </div> */}
                        <AvailabilityBadge
                          availabilityType={availabilityType}
                        />
                        <div className="flex justify-between mt-[16px]">
                          <div>
                            <p className="W2STMedium text-gray900">
                              ${hourlyRate}/hr
                            </p>
                            <h3 className="text-gray500">Hourly rate</h3>
                          </div>
                          <button className="text-white px-[27px] py-[10px] rounded-md bg-primary900 W2STMedium">
                            Book Tutor
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                )}
          </div>
          <Drawer
            title={
              <div className="flex justify-between items-center">
                <h3>Tutor Details</h3>
                <X
                  onClick={() => setVisible(false)}
                  className="cursor-pointer"
                />
              </div>
            }
            open={visible}
            closable={false}
            maskClosable={false}
          >
            {tutorsLoading && <Loader />}
            {!tutorsLoading && tutorsData && (
              <div>
                <div className="flex gap-2 mb-3">
                  <img
                    src={tutorsData?.data?.profilePicture}
                    className="h-[60px] rounded-full w-[60px]"
                    alt="avatar"
                  />
                  <div className="flex-1">
                    <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                      {tutorsData?.data?.firstName +
                        " " +
                        tutorsData?.data?.lastName}
                    </h3>
                    <div className="mt-2">
                      <div className="flex gap-3 items-center">
                        <BriefcaseBusiness
                          size={20}
                          style={{ color: "text-gray700" }}
                        />
                        <span className="text-gray500">
                          {tutorsData?.data?.professionalTitle}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <TutorBookingForm id={id} setVisible={setVisible} />
                </div>
              </div>
            )}
          </Drawer>
        </div>
      </div>
    </StudentDashboard>
  );
};

export default StudentOverview;
