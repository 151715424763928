import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

interface OnboardingPayload {
  professionalTitle: string;
  profilePicture: string;
  areaOfExpertise: string[] | string;
}

export const useLevel1Onboarding = () => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useMutation(
    (payload: OnboardingPayload) =>
      axiosInstance
        .post(
          "tutors/onboarding-step-one",
          {
            ...payload,
            areaOfExpertise: payload.areaOfExpertise,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (data: { message: string }) => {
        message.success(data.message || "Onboarding step one completed.");
        queryClient.invalidateQueries("gettutor");
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message ||
          "Onboarding failed. Please try again.";
        message.error(errorMessage);
      },
    }
  );
};
