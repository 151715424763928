import { useState } from "react";
import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetConnectedAccount = ({ trigger }: { trigger: boolean }) => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");
  const [hasOpened, setHasOpened] = useState(false); // Prevent multiple openings

  return useQuery({
    queryKey: ["getConnectedAccount"],
    queryFn: async () => {
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await axiosInstance.get("/tutors/stripe/connect", {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    },
    onSuccess: async () => {
      if (hasOpened) return; // Prevent multiple openings

      try {
        if (!token) {
          throw new Error("Token not found");
        }

        const accountLinkResponse = await axiosInstance.get(
          "/tutors/stripe/account-link",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const url = accountLinkResponse?.data?.data?.url;
        if (url) {
          window.open(url, "_blank");
          setHasOpened(true); // Mark as opened
        }
      } catch (error) {
        console.error("Error handling success flow:", error);
      }
    },
    onError: (error) => {
      console.error("Error fetching user data:", error);
    },
    enabled: trigger,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
