import { Dropdown, MenuProps } from "antd";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <a href="/tutor/signin" rel="noopener noreferrer">
        Sign In as a Tutor
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a href="/student/signin" rel="noopener noreferrer">
        Sign In as a Student
      </a>
    ),
  },
];

const SignInDropdown = () => {
  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      trigger={["click"]}
    >
      <span className="w2st-light-button p-3">Sign In</span>
    </Dropdown>
  );
};

export default SignInDropdown;
