import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

export const useConfirmTutorBooking = ({
  id,
  trigger,
  closeModal,
}: {
  id: any;
  trigger: boolean;
  closeModal: any;
}) => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useQuery({
    queryKey: ["confirmTutorBooking", id, trigger],
    queryFn: async () => {
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await axiosInstance.patch(
        `tutors/bookings/${id}/confirm`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: (data: any) => {
      closeModal();
      queryClient.invalidateQueries(["getBookingsTutor"]);
      message.success(data?.message);
    },
    onError: (error) => {
      console.error("Error confirming booking:", error);
    },
    enabled: !!id && trigger,
  });
};
