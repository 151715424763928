import React, { useCallback, useState } from "react";
import StudentDashboard from "../../../layout/StudentDashboard";
import { useGetTutorsQuery } from "../../../hooks/api/Students/Tutors/useGetTutorsQuery";
import {
  Award,
  BriefcaseBusiness,
  ChevronDown,
  RefreshCcw,
  X,
} from "lucide-react";
import { Button, Drawer, Empty, Input, Modal, Select } from "antd";
import AvailabilityBadge from "./Components/AvailabilityBadge";
import TutorBookingForm from "./Components/TutorBookingForm";
import Loader from "../../../components/Loader";
import { useGetTutorQuery } from "../../../hooks/api/Students/Tutors/useGetTutorQuery";
import { useGetSubjectsQuery } from "../../../hooks/api/Admin/Courses/useGetSubjectsQuery";
import { debounce } from "lodash";
import SearchFilters from "./Components/SearchFilters";
import EmptyState from "../../../components/EmptyState";

const initialState = {
  subject: "",
  search: "",
  days: "",
  from: "",
  to: "",
};

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const StudentTutors = () => {
  const [id, setId] = useState("");
  const [visible, setVisible] = useState(false);
  const [payload, setPayload] = useState(initialState);

  const { isLoading, data } = useGetTutorsQuery({ payload });
  const { data: tutorsData, isLoading: tutorsLoading } = useGetTutorQuery({
    id,
    payload,
  });

  const pfp = require("../../../assets/images/pfpavatar.jpg");

  const tutorsList = data?.data?.docs;

  const { data: subjects } = useGetSubjectsQuery({ page: 1 });
  const [openModal, setOpenModal] = useState(false);

  const handleSearch = useCallback(
    debounce((value: string) => {
      setPayload((prev) => ({ ...prev, search: value }));
    }, 1000),
    [setPayload]
  );

  // if (isLoading && !data) {
  //   return (
  //     <StudentDashboard>
  //       <Loader />
  //     </StudentDashboard>
  //   );
  // }

  return (
    <StudentDashboard>
      <div className="md:flex block gap-5">
        <div className="md:w-[300px] w-full">
          <div className="md:mb-0 mb-[20px]">
            <div className="fixed bg-white shadow-sm rounded-md w-[93%] md:w-[300px]">
              <div className="px-[18px] py-[20px] flex items-center justify-between">
                <span className="W2STMedium">Filter Options</span>
                <span className="flex gap-3 items-center">
                  <button
                    className="p-2 bg-white rounded-full shadow-md"
                    onClick={() => {
                      setPayload(initialState);
                    }}
                  >
                    <RefreshCcw />
                  </button>{" "}
                  <button
                    className="p-2 bg-white rounded-full shadow-md md:hidden block"
                    onClick={() => {
                      setOpenModal(!openModal);
                    }}
                  >
                    <ChevronDown />
                  </button>
                </span>
              </div>
              <Modal
                onCancel={() => setOpenModal(false)}
                title={"Filter Options"}
                open={openModal}
                footer={<Button variant="filled">Confirm</Button>}
              >
                <SearchFilters
                  handleSearch={handleSearch}
                  setPayload={setPayload}
                  payload={payload}
                  subjects={subjects}
                  daysOfWeek={daysOfWeek}
                />
              </Modal>

              <div className="md:block hidden">
                <SearchFilters
                  handleSearch={handleSearch}
                  setPayload={setPayload}
                  payload={payload}
                  subjects={subjects}
                  daysOfWeek={daysOfWeek}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          {isLoading && (
            <div className="flex mx-auto">
              <Loader />
            </div>
          )}
          {!isLoading && tutorsList && tutorsList.length === 0 && (
            <EmptyState
              title="There are no tutors that match your search."
              desc="Please try adjusting your search keywords or filter"
            />
          )}

          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-3 md:pt-0 pt-[100px]">
            {!isLoading &&
              tutorsList &&
              tutorsList.map(
                ({
                  firstName,
                  lastName,
                  professionalTitle,
                  availabilityType,
                  id,
                  hourlyRate,
                  profilePicture,
                  subject,
                }: any) => (
                  <div
                    key={id}
                    className="bg-white rounded-md shadow-sm overflow-hidden cursor-pointer"
                    onClick={() => {
                      setId(id);
                      setVisible(true);
                    }}
                  >
                    <img
                      src={profilePicture ? profilePicture : pfp}
                      alt="tutor avatar"
                      className="h-[218px] rounded-t-lg w-full bg-[#ddd]"
                    />
                    <div className="p-[16px]">
                      <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                        {firstName + " " + lastName}
                      </h3>
                      <div className="mt-2">
                        <div className="flex gap-3 items-center">
                          <BriefcaseBusiness
                            style={{ color: "text-gray700" }}
                          />
                          <span className="text-gray500 text-sm">
                            {professionalTitle}
                          </span>
                        </div>
                      </div>

                      {/* <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                        <Award size={20} />
                        <span className="text-gray500">
                          15 sessions (5 reviews)
                        </span>
                      </div> */}
                      <AvailabilityBadge availabilityType={availabilityType} />
                      <div className="flex justify-between mt-[16px]">
                        <div>
                          <p className="W2STMedium text-gray900">
                            ${hourlyRate}/hr
                          </p>
                          <h3 className="text-gray500">Hourly rate</h3>
                        </div>
                        <button className="text-white px-[27px] py-[10px] rounded-md bg-primary900 W2STMedium">
                          Book Tutor
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}
            {/* {tutorsList &&
              tutorsList.map(
                ({
                  firstName,
                  lastName,
                  professionalTitle,
                  availabilityType,
                  id,
                  hourlyRate,
                  profilePicture,
                }: any) => (
                  <div
                    key={id}
                    className="bg-white rounded-md shadow-sm overflow-hidden cursor-pointer"
                    onClick={() => {
                      setId(id);
                      setVisible(true);
                    }}
                  >
                    <img
                      src={profilePicture ? profilePicture : pfp}
                      alt="tutor avatar"
                      className="h-[218px] rounded-t-lg w-full bg-[#ddd]"
                    />
                    <div className="p-[16px]">
                      <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                        {firstName + " " + lastName}
                      </h3>
                      <div className="mt-2">
                        <div className="flex gap-3 items-center">
                          <BriefcaseBusiness
                            style={{ color: "text-gray700" }}
                          />
                          <span className="text-gray500 text-sm">
                            {professionalTitle}
                          </span>
                        </div>
                      </div>

                      <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                        <Award size={20} />
                        <span className="text-gray500">
                          15 sessions (5 reviews)
                        </span>
                      </div>
                      <AvailabilityBadge availabilityType={availabilityType} />
                      <div className="flex justify-between mt-[16px]">
                        <div>
                          <p className="W2STMedium text-gray900">
                            ${hourlyRate}/hr
                          </p>
                          <h3 className="text-gray500">Hourly rate</h3>
                        </div>
                        <button className="text-white px-[27px] py-[10px] rounded-md bg-primary900 W2STMedium">
                          Book Tutor
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )} */}
            <Drawer
              title={
                <div className="flex justify-between items-center">
                  <h3>Tutor Details</h3>
                  <X
                    size={20}
                    onClick={() => setVisible(false)}
                    className="cursor-pointer"
                  />
                </div>
              }
              open={visible}
              closable={false}
              maskClosable={false}
            >
              {tutorsLoading && <Loader />}
              {!tutorsLoading && tutorsData && (
                <div>
                  <div className="flex gap-2 mb-3">
                    <img
                      src={tutorsData?.data?.profilePicture}
                      className="h-[60px] rounded-full w-[60px]"
                      alt="avatar"
                    />
                    <div className="flex-1">
                      <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                        {tutorsData?.data?.firstName +
                          " " +
                          tutorsData?.data?.lastName}
                      </h3>
                      <div className="mt-2">
                        <div className="flex gap-3 items-center">
                          <BriefcaseBusiness
                            style={{ color: "text-gray700" }}
                          />
                          <span className="text-gray500">
                            {tutorsData?.data?.professionalTitle}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <TutorBookingForm id={id} setVisible={setVisible} />
                  </div>
                </div>
              )}
            </Drawer>
          </div>
        </div>
      </div>
    </StudentDashboard>
  );
};

export default StudentTutors;
