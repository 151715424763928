import { ArrowLeft } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Back = () => {
  const navigate = useNavigate();
  return (
    <button
      className="rounded-full p-2 bg-slate-100 mb-3 flex items-center gap-2 text-[12px]"
      onClick={() => {
        navigate(-1);
      }}
    >
      <ArrowLeft size={15} />
      <span>back</span>
    </button>
  );
};

export default Back;
