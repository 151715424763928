import React from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import Back from "../../../components/Back";
import { useGetSingleStudentonAdmin } from "../../../hooks/api/Admin/Students/useGetSingleStudentonAdmin";
import { useParams } from "react-router-dom";

const StudentDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSingleStudentonAdmin(`${id}`);

  return (
    <AdminDashboardLayout>
      <Back />
      <div className="mt-3">{JSON.stringify(data?.data)}</div>
    </AdminDashboardLayout>
  );
};

export default StudentDetails;
