import { Card, Typography, Space } from "antd";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import TutorShipTypeTag from "../../../components/TutorshipTypeTag";

const { Title, Text } = Typography;

interface Booking {
  subject: string;
  tutorShipType: "Virtual" | "One-on-one" | "Both";
  tutor: {
    firstName: string;
    lastName: string;
    professionalTitle: string;
    hourlyRate: number;
  };
  date: string;
  timeSlots: string[];
  duration: string;
  notes: string[];
  createdAt: string;
}

const BookingDetails = ({ booking }: { booking: Booking }) => {
  return (
    <Card style={{ maxWidth: 500 }}>
      <Title level={5}>
        <span className="capitalize">{booking.subject}</span> |{" "}
        <TutorShipTypeTag type={booking.tutorShipType} />| Duration:{" "}
        {booking.duration} mins
      </Title>

      <Space>
        <CalendarOutlined />
        <Text>{dayjs(booking.date).format("ddd MMM D, YYYY")}</Text>
        <ClockCircleOutlined />
        <Text>
          {booking.timeSlots[0]}{" "}
          {booking.timeSlots[1] ? "-" + booking.timeSlots[1] : ""}{" "}
        </Text>
      </Space>

      <div style={{ marginTop: 12 }}>
        <Text type="secondary">Hourly rate (Non-binding)</Text>
        <Title level={5}>${booking.tutor.hourlyRate}/hr</Title>
      </div>

      <div>
        <Text type="secondary">Duration</Text>
        <Title level={5}>{booking.duration} mins</Title>
      </div>

      <div>
        <Text type="secondary">Note from student</Text>
        <Title level={5}>{booking.notes.join(", ")}</Title>
      </div>

      <div>
        <Text type="secondary">Created:</Text>
        <Text>{dayjs(booking.createdAt).format("MMM D, YYYY [WAT]")}</Text>
      </div>
    </Card>
  );
};

export default BookingDetails;
