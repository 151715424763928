import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetTutorsQuery = ({
  payload = {},
}: {
  payload?: Record<string, any>;
}) => {
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  const cleanedPayload = Object.fromEntries(
    Object.entries(payload).filter(
      ([, value]) => value !== "" && value !== null && value !== undefined
    )
  );

  return useQuery({
    queryKey: ["getTutors", cleanedPayload],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");

      const response = await axiosInstance.get("users/tutors/", {
        headers: { Authorization: `Bearer ${token}` },
        params: { ...cleanedPayload },
      });

      return response.data;
    },
    onSuccess: (data) => {
      console.log("Success Getting Tutors ==>", data);
    },
    onError: (error) => {
      console.error("Failed Getting Tutors ==>", error);
    },
    enabled: !!token,
  });
};
