/* eslint-disable react/display-name */
import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const withAdminAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    const stringifyToken = localStorage.getItem("W2ST_ADMIN_TOKEN");

    useEffect(() => {
      if (!stringifyToken) {
        navigate("/");
        message.error("Unauthorized, proceed to sign in");
      }
    }, [navigate, stringifyToken]);

    return <WrappedComponent {...props} />;
  };
};

export default withAdminAuth;
