import React, { ReactNode, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import withStudentAuth from "../hoc/withStudentAuth";
import { useGetStudentQuery } from "../hooks/api/Students/Auth/useGetStudentQuery";
import { message, Modal } from "antd";
import { useSendVerifyOTPMutation } from "../hooks/api/Students/Auth/useSendOTPMutation";
import { useVerifyOTPMutation } from "../hooks/api/Students/Auth/useVerifyOTPMutation";
import Button from "../components/Button";
import { Bell, Book, Calendar, Grid2X2, Menu, Settings, X } from "lucide-react";

interface MyComponentProps {
  children: ReactNode;
}

const StudentDashboard = ({ children }: MyComponentProps) => {
  const location = useLocation();

  const Logo = require("../assets/images/logo.png");

  const [level, setLevel] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [otp, setOTP] = useState("");
  const { data, isLoading } = useGetStudentQuery();
  const { mutateAsync, isLoading: isSendingOTP } = useSendVerifyOTPMutation();
  const { mutateAsync: verifyUser, isLoading: isVerifyingOTP } =
    useVerifyOTPMutation(otp);

  const [visibility, setVisibility] = useState(false);
  const navigate = useNavigate();

  const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
  };

  const activeLink = "text-primary700 bg-primary50 text-medium";
  const inActiveLink = "text-gray700";

  const getLinkClasses = (path: string) =>
    location.pathname === path
      ? "text-[#3F2ABA] font-semibold"
      : "text-gray-700 hover:text-gray-600";

  const handleInitialize = () => {
    mutateAsync();
    setLevel(1);
  };

  const handleVerify = () => {
    verifyUser();
  };

  const handleLogout = () => {
    localStorage.removeItem("W2ST_STUDENT_TOKEN");
    localStorage.removeItem("hasSeenWelcomeLoader");
    message.success("Logout successful");
    navigate("/student/signin");
  };

  return (
    <>
      <Modal
        width="400px"
        footer={null}
        closeIcon={false}
        open={data?.data?.isEmailVerified === false}
        onCancel={() => {
          setVisibility(false);
        }}
      >
        <h3 className="text-[20px] W2STMedium">Email Verification</h3>
        <p className="text-gray-600 mb-6">
          Proceed to verify your email address. An OTP been sent to your email
          for verfication purposes.
        </p>
        {level === 0 ? (
          <div className="block">
            <Button
              label="Proceed"
              isFormValid={true}
              isLoading={isSendingOTP}
              handleSubmit={() => handleInitialize()}
            />
          </div>
        ) : (
          <div>
            <label htmlFor="otp" className="font-gray mt-3 block">
              Email OTP
            </label>
            <input
              name="otp"
              type="text"
              placeholder=""
              value={otp}
              disabled={isVerifyingOTP}
              className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
              onChange={(e) => setOTP(e.target.value)}
            />
            <Button
              label="Proceed"
              isFormValid={true}
              isLoading={isVerifyingOTP}
              handleSubmit={() => handleVerify()}
            />
          </div>
        )}
      </Modal>

      <div className="md:block hidden overflow-y-hidden max-h-dvh">
        <div className="flex flex-col h-[100vh]">
          <div className="border-b-[1px] border-b-gray300 flex-none">
            <div className="container mx-auto flex justify-between items-center p-4 bg-white">
              <span className="flex gap-3 items-center">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="W2ST Logo"
                    className="h-[40px]"
                    onDragStart={handleDragStart}
                  />
                </Link>

                <span className="flex gap-3 ml-6 text-sm">
                  <Link
                    to="/student/overview"
                    className={`p-2 rounded-md flex gap-2 items-center ${
                      location?.pathname.includes("student/overview")
                        ? `${activeLink}`
                        : `${inActiveLink}`
                    }`}
                  >
                    {location?.pathname.includes("/student/overview") && (
                      <Grid2X2 />
                    )}
                    Overview
                  </Link>
                  <Link
                    to="/student/tutors"
                    className={`p-2 rounded-md flex gap-2 items-center ${
                      location?.pathname.includes("student/tutors")
                        ? `${activeLink}`
                        : `${inActiveLink}`
                    }`}
                  >
                    {location?.pathname.includes("/student/tutors") && <Book />}
                    Tutors
                  </Link>
                  <Link
                    to="/student/bookings"
                    className={`p-2 rounded-md flex gap-2 items-center ${
                      location?.pathname.includes("student/bookings")
                        ? `${activeLink}`
                        : `${inActiveLink}`
                    }`}
                  >
                    {location.pathname.includes("/student/bookings") && (
                      <Calendar />
                    )}
                    Bookings
                  </Link>
                </span>
              </span>
              <section className="flex gap-4 items-center text-gray500">
                <Link to="/student/settings" className="cursor-pointer">
                  <Settings
                    className={
                      location?.pathname.includes("/student/settings")
                        ? "text-primary600"
                        : "text-gray500"
                    }
                    style={{ fontSize: "20px" }}
                  />
                </Link>
                <Bell className=" text-gray500" style={{ fontSize: "20px" }} />
                {/* <img
                  src={""}
                  alt="avatar"
                  className="logo h-[40px] w-[40px] rounded-full bg-gray-300"
                /> */}
                <button
                  className="text-red-700 p-3 W2STMedium"
                  onClick={() => {
                    localStorage.removeItem("W2ST_STUDENT_TOKEN");
                    navigate("/student/signin");
                  }}
                >
                  Logout
                </button>
              </section>
            </div>
          </div>

          {/* Scrollable Content Section */}
          <div className="bg-gray50 grow overflow-y-scroll scrollbar-none">
            <div className="container mx-auto pt-10">
              {/* <p className="text-[10px]">{JSON.stringify(data?.data)}</p> */}
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden flex flex-col h-[100vh] relative pb-[30px]">
        {/* Navbar */}
        <div className="flex-none border-b-[1px] border-b-gray-300">
          <div className="container mx-auto flex justify-between items-center p-4 bg-white">
            <span className="flex gap-3 items-center">
              <img src={Logo} alt="W2ST Logo" className="h-[40px]" />
            </span>
            <button onClick={() => setIsOpen(true)}>
              <Menu size={30} />
            </button>
          </div>
        </div>

        <div className="grow container mx-auto md:px-4 overflow-y-scroll bg-gray-50 scrollbar-none">
          <div>{children}</div>
        </div>

        <div
          className={`fixed inset-0 bg-white z-50 transform transition-transform duration-300 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex flex-col h-full relative">
            <div className="absolute bottom-10 px-3 w-full">
              <button
                className="bg-red-600 p-3 rounded-md text-white W2STMedium w-full"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>

            <div className="flex justify-between items-center p-4 border-b">
              <span className="text-lg font-semibold">Menu</span>
              <button onClick={() => setIsOpen(false)}>
                <X size={30} />
              </button>
            </div>

            <nav className="flex flex-col gap-6 p-6 text-lg">
              <Link
                to="/student/overview"
                className={getLinkClasses("/student/overview")}
              >
                Overview
              </Link>
              <Link
                to="/student/tutors"
                className={getLinkClasses("/student/tutors")}
              >
                Tutors
              </Link>
              <Link
                to="/student/bookings"
                className={getLinkClasses("/student/bookings")}
              >
                Bookings
              </Link>
              <Link
                to="/tutor/profile"
                className={getLinkClasses("/tutor/profile")}
              >
                Profile
              </Link>
            </nav>
          </div>
        </div>
      </div>

      {/* <div className="md:hidden flex flex-col h-[100vh]">
        <div className="flex-none border-b-[1px] border-b-gray300">
          <div className="container mx-auto flex justify-between items-center p-4 bg-white">
            <span className="flex gap-3 items-center">
              <img src={Logo} alt="W2ST Logo" className="h-[40px]" />
            </span>
            <span className="rounded-md h-[40px] w-[40px] bg-[#3d3d3d]"></span>
          </div>
        </div>
        <div className="grow container mx-auto md:px-4 overflow-y-scroll bg-gray50">
          <div>{children}</div>
        </div>
        <div className="flex-none p-4 grid grid-cols-3 gap-2 mb-[30px]">
          <Link
            to="/student/overview"
            className={`p-2 rounded-md flex gap-2 items-center text-center text-tmd ${
              location.pathname === "/student/overview"
                ? "text-primary700 bg-primary50"
                : "text-gray700"
            }`}
          >
            <Grid2X2 />
            Overview
          </Link>
          <Link
            to="/student/tutors"
            className={`p-2 rounded-md flex gap-2 items-center text-tmd  ${
              location.pathname === "/student/tutors"
                ? "text-primary700 bg-primary50"
                : "text-gray700"
            }`}
          >
            <Book />
            Tutors
          </Link>
          <Link
            to="/student/bookings"
            className={`p-2 rounded-md flex gap-2 items-center text-tmd  text-center ${
              location.pathname === "/student/bookings"
                ? "text-primary700 bg-primary50"
                : "text-gray700"
            }`}
          >
            <Calendar />
            Bookings
          </Link>
        </div>
      </div> */}
    </>
  );
};

export default withStudentAuth(StudentDashboard);
