import React, { useState } from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { useGetSingleBookingsAdmin } from "../../../hooks/api/Admin/Bookings/useGetSingleBookingsAdmin";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import {
  Card,
  Row,
  Col,
  Descriptions,
  Tag,
  Divider,
  Drawer,
  Input,
  Button,
  Modal,
} from "antd";
import {
  BankOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { useGetBookingsMessagesAdmin } from "../../../hooks/api/Admin/Bookings/useGetBookingsMessagesAdmin";
import AdminChatBox from "./AdminChatBox";
import { useAdminSendConversationsMutation } from "../../../hooks/api/Admin/Bookings/useAdminSendConversationsMutation";
import Back from "../../../components/Back";
import { useIntiatePayout } from "../../../hooks/api/Admin/Stripe/useIntiatePayout";

type Props = {};

const AdminBookingDetails = (props: Props) => {
  const { id } = useParams();
  const [chatVisible, setChatVisible] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const { data, isLoading } = useGetSingleBookingsAdmin(`${id}`);
  const { data: messages, isLoading: isLoadingMessages } =
    useGetBookingsMessagesAdmin(`${id}`);

  const {
    student,
    tutor,
    subject,
    status,
    price,
    duration,
    notes,
    date,
    timeSlots,
    isPaidOut,
  } = data?.data || {};

  const renderStatusTag = (status: string) => {
    switch (status) {
      case "completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="green">
            Completed
          </Tag>
        );
      case "pending":
        return <Tag color="orange">Pending</Tag>;
      case "cancelled":
        return (
          <Tag icon={<CloseCircleOutlined />} color="red">
            Cancelled
          </Tag>
        );
      default:
        return <Tag color="blue">{status}</Tag>;
    }
  };

  const { mutateAsync: sendMessage, isLoading: isSendingMessage } =
    useAdminSendConversationsMutation({
      id: `${id}`,
      setMessageInput,
    });

  const handleSendMessage = () => {
    sendMessage({ message: messageInput, sender: "Admin", type: "text" });
  };

  const { mutate } = useIntiatePayout();

  const handleSubmit = () => {
    mutate(id);
  };
  const handleInitiatePayout = (id: any) => {
    console.log("ID ===>", id);
    Modal.confirm({
      title: "Are you sure?",
      content: "Kindly note that this is an irreversible process",
      onOk: () => handleSubmit(),
    });
  };

  return (
    <AdminDashboardLayout>
      <Back />
      <div className="flex justify-between items-center">
        <h3 className="W2STBold text-[20px] font-bold">Booking Details</h3>
        <span className="flex gap-2 items-center">
          <Button
            icon={<BankOutlined size={30} />}
            disabled={data?.data?.isPaidOut}
            onClick={handleInitiatePayout}
            className="rounded-md p-2 bg-primary700 text-white"
          >
            {data?.data?.isPaidOut ? "Payment Sent" : "Initiate Payout"}
          </Button>
          <Button
            icon={<MessageOutlined />}
            onClick={() => setChatVisible(true)}
            className="rounded-md p-2 bg-primary700 text-white"
          >
            View Chat
          </Button>
        </span>
      </div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="mt-5">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card title="Student Information" bordered>
                <Descriptions column={1}>
                  <Descriptions.Item label="Name">
                    {student
                      ? `${student.firstName} ${student.lastName}`
                      : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {student?.email || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone">
                    {student?.phoneNumber || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Last Login">
                    {student?.lastLogin
                      ? new Date(student.lastLogin).toLocaleString()
                      : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Sessions Booked">
                    {student?.sessionsBooked || "N/A"}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>

            <Col span={12}>
              <Card title="Tutor Information" bordered>
                <Descriptions column={1}>
                  <Descriptions.Item label="Name">
                    {tutor ? `${tutor.firstName} ${tutor.lastName}` : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Professional Title">
                    {tutor?.professionalTitle || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone">
                    {tutor?.phoneNumber || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Hourly Rate">
                    {tutor?.hourlyRate ? `$${tutor.hourlyRate}/hr` : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Last Login">
                    {tutor?.lastLogin
                      ? new Date(tutor.lastLogin).toLocaleString()
                      : "N/A"}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>

          <Divider />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card title="Booking Details" bordered>
                <Descriptions column={1}>
                  <Descriptions.Item label="Subject">
                    {subject || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date">
                    {date ? new Date(date).toLocaleDateString() : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Time Slots">
                    {timeSlots
                      ? timeSlots.map((slot: any, index: any) => (
                          <Tag key={index} color="blue">
                            {slot}
                          </Tag>
                        ))
                      : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    {renderStatusTag(status || "unknown")}
                  </Descriptions.Item>
                  <Descriptions.Item label="Price">
                    {price ? `$${price}` : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Duration">
                    {duration ? `${duration} minutes` : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Paid Out">
                    {isPaidOut ? (
                      <Tag color="green">Paid</Tag>
                    ) : (
                      <Tag color="red">Unpaid</Tag>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Additional Notes" bordered>
                {notes && notes.length > 0 ? (
                  notes.map((note: any, index: any) => (
                    <p key={index}>- {note}</p>
                  ))
                ) : (
                  <p>No additional notes</p>
                )}
              </Card>
            </Col>
          </Row>

          <Drawer
            title="Chat between Tutor and Student"
            placement="right"
            width={350}
            onClose={() => {
              setChatVisible(false);
            }}
            open={chatVisible}
          >
            <AdminChatBox id={`${id}`} />
            <div className="mt-4 flex gap-2">
              <Input
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                placeholder="Type a message..."
                disabled={isSendingMessage}
              />
              <Button
                type="primary"
                onClick={handleSendMessage}
                disabled={isSendingMessage}
              >
                Send
              </Button>
            </div>
          </Drawer>
        </div>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminBookingDetails;
