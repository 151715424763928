import { Calendar, Clock2 } from "lucide-react";
import { Button, Divider, Drawer } from "antd";
import clsx from "clsx";
import { useState } from "react";
import Input from "antd/es/input/Input";
import ChatBox from "./ChatBox";
import { useSendConversationsMutation } from "../../../hooks/api/Students/Conversations/useSendConversationsMutation";
import { useMarkAsCompletedMutation } from "../../../hooks/api/Students/Bookings/useMarkAsCompletedMutation";
import { MessageOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import AvailabilityBadge from "../Tutors/Components/AvailabilityBadge";

type Props = {
  id: string;
  subject?: any;
  tutorShipType?: any;
  date?: any;
  tutor: any;
  timeSlots?: any;
  status?: "confirmed" | "pending" | "cancelled" | string;
  price?: any;
  duration: any;
  notes?: any;
};

const STATUS_COLORS: Record<string, string> = {
  confirmed: "bg-green-100 text-green-700",
  pending: "bg-yellow-100 text-yellow-700",
  cancelled: "bg-red-100 text-red-700",
  default: "bg-gray-100 text-gray-700",
};

const SessionCard = ({
  id,
  subject,
  tutorShipType,
  date,
  timeSlots,
  tutor,
  status = "default",
  duration,
}: Props) => {
  const isSessionEnded = (date: string, duration: number) => {
    const sessionEndTime = new Date(date).getTime() + duration * 60000;
    return Date.now() >= sessionEndTime;
  };

  const [chatVisible, setChatVisible] = useState(false);
  const [selectedTutor, setSelectedTutor] = useState("");
  const [messageInput, setMessageInput] = useState("");

  const [selectedId, setSelectedId] = useState("");
  const { mutateAsync: sendMessage, isLoading: isSendingMessage } =
    useSendConversationsMutation({
      id: selectedId,
      setMessageInput,
    });

  const { mutateAsync } = useMarkAsCompletedMutation();

  const handleChatClick = (id: string) => {
    // if (!isChatEnabled(record.date)) {
    //   message.info("It'll be available 1 hour to the session");
    // } else {
    setSelectedId(id);
    setChatVisible(true);
    // }
  };

  const handleMarkAsCompleted = async (id: string) => {
    await mutateAsync({ id: id });
  };

  const handleSendMessage = () => {
    sendMessage({ message: messageInput, sender: "Student", type: "text" });
  };

  const formatTimeRange = (timeSlot: any) => {
    const [hour, minute] = timeSlot.split(":").map(Number);
    const nextHour = (hour + 1) % 24;

    const formatTime = (h: number, m: number) => {
      const period = h >= 12 ? "pm" : "am";
      const formattedHour = h % 12 === 0 ? 12 : h % 12;
      return `${formattedHour}:${m.toString().padStart(2, "0")}${period}`;
    };

    return `${formatTime(hour, minute)} - ${formatTime(nextHour, minute)}`;
  };

  return (
    <div className="relative p-6 rounded-lg border border-gray-200 bg-white shadow-sm">
      <div
        className={clsx(
          "absolute top-3 right-3 flex items-center gap-2 px-3 py-1 rounded-full text-[12px] font-semibold",
          STATUS_COLORS[status] || STATUS_COLORS.default
        )}
      >
        {status === "confirmed" && (
          <span className="h-2 w-2 bg-green-500 rounded-full animate-ping" />
        )}
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </div>
      <h3 className="text-lg W2STMedium capitalize md:mt-0 mt-[30px]">
        {subject} Session with{" "}
        <span className="text-purple-500">{tutor?.firstName}</span>
      </h3>
      <div className="flex md:flex-row flex-col md:gap-5 gap-2 mt-5">
        <span className="flex gap-1.5 items-center">
          <Calendar color="#344054" size={20} />
          <span className="text-gray-500">
            {new Date(date).toLocaleDateString("en-US", {
              weekday: "short",
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </span>
        </span>
        <span className="flex gap-1.5 items-center">
          <Clock2 color="#344054" size={20} />
          <span className="text-gray-500">{formatTimeRange(timeSlots[0])}</span>
          (
          <Moment fromNow interval={0} className="text-gray-500">
            {date}
          </Moment>
          )
        </span>
      </div>
      <AvailabilityBadge availabilityType={tutorShipType} />
      <Divider />
      <div className="flex gap-2 mt-4">
        <Button
          onClick={() => {
            handleMarkAsCompleted(id);
          }}
          type="primary"
          disabled={!isSessionEnded(date, duration) || status === "completed"}
        >
          Mark As Completed
        </Button>
        <Button
          type="primary"
          icon={<MessageOutlined />}
          disabled={status !== "confirmed"}
          onClick={() => {
            handleChatClick(id);
            setSelectedTutor(tutor?.firstName);
          }}
        >
          Chat Tutor
        </Button>
      </div>
      <Drawer
        title={`Chat with ${tutor?.firstName}`}
        placement="right"
        width={350}
        onClose={() => {
          setChatVisible(false);
          setSelectedTutor("");
        }}
        open={chatVisible}
      >
        <ChatBox id={selectedId} />
        <div className="mt-4 flex gap-2">
          <Input
            value={messageInput}
            size="large"
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="Type a message..."
            disabled={isSendingMessage}
          />
          <Button
            type="primary"
            size="large"
            onClick={handleSendMessage}
            disabled={isSendingMessage}
          >
            Send
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default SessionCard;
