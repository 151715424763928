import React, { useState } from "react";
import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
import { useGetTutorQuery } from "../../../hooks/api/Tutors/Auth/useGetTutorQuery";
import Loader from "../../../components/Loader";
import Card from "../../../components/Card";
import ProfileCard from "../../../components/Tutor/ProfileCard";
import Options from "../../Admin/Tutors/components/Options";
import { Button, Empty, Modal, Select } from "antd";
import EducationCard from "../../Admin/Tutors/components/EducationCard";
import ExperienceCard from "../../Admin/Tutors/components/ExperienceCard";
import { Edit, PlusCircle } from "lucide-react";
import { BriefcaseBusiness } from "lucide-react";
import { LocationIcon } from "../../../components/Icon/LocationIcon";
import { VirtualIcon } from "../../../components/Icon/VirtualIcon";
import EditEducation from "./components/EditEducation";
import EditWork from "./components/EditWork";
import { useUpdateTutorProfile } from "../../../hooks/api/Tutors/UpdateProfile/useUpdateTutorProfile";
import AvailabilityBadge from "../../Students/Tutors/Components/AvailabilityBadge";

const initialState = { status: false, active: "" };

const TutorProfile = () => {
  const [active, setActive] = useState(initialState);
  const { data, isLoading } = useGetTutorQuery();
  const [availabilityType, setAvailabilityType] = useState(
    data?.data?.availabilityType
  );
  const [availabilityModal, setAvailabilityModal] = useState(false);

  const { Option } = Select;

  const { mutate, isLoading: loadingTutor } = useUpdateTutorProfile();

  const handleAvailabilityChange = (value: any) => {
    setAvailabilityType(value);
  };

  const resetForm = () => {
    setActive(initialState);
  };

  return (
    <TutorDashboardLayout>
      {isLoading && <Loader />}
      {!isLoading && data && (
        <section className="mt-10 md:flex block gap-4">
          <section className="md:w-[30%]">
            {/* <TutorProfilePreviewCard data={data} /> */}
            <ProfileCard />
            <Options data={data?.data} />
          </section>
          <section className="md:w-[70%]">
            <Card width="12" style={{ marginBottom: "10px" }}>
              <div className="flex justify-between items-center">
                <h1 className="text-[20px] W2STBold text-gray700 mb-3">
                  {data?.data?.firstName + " " + data?.data?.lastName}
                </h1>
              </div>
              <div className="mt-2">
                <div className="flex gap-3 items-center">
                  <BriefcaseBusiness size={20} />
                  <span className="text-gray500 text-sm">
                    {data && data?.data?.professionalTitle}
                  </span>
                </div>
              </div>
              <div className="mt-5 bg-white rounded-lg">
                <div>
                  <div className="mt-5 bg-white rounded-lg">
                    <div className="flex justify-between items-center">
                      <h3 className="W2STMedium text-gray900">Available for</h3>
                      <button
                        className="text-primary600 flex gap-2 items-center cursor-pointer"
                        onClick={() => {
                          setAvailabilityModal(true);
                        }}
                      >
                        <Edit size={20} /> Edit Availability
                      </button>
                    </div>

                    <AvailabilityBadge
                      availabilityType={data && data?.data?.availabilityType}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <p className="text-gray700 text-sm font-medium">
                  Hourly rate
                  {/* <Tooltip
                    placement="topLeft"
                    title={"Tutor can set their fee"}
                  >
                    (Non binding)
                  </Tooltip> */}
                </p>
                <h3 className="W2STMedium text-md text-gray900">
                  ${data && data?.data?.hourlyRate}/hr
                </h3>
              </div>
            </Card>
            {data?.data?.education && (
              <Card width="12" style={{ marginTop: "10px" }}>
                <div className="flex justify-between items-center">
                  <h1 className="text-xl W2STMedium text-gray700 mb-3">
                    Education
                  </h1>
                  <button
                    className="text-primary600 flex gap-2 items-center cursor-pointer"
                    onClick={() => {
                      setActive({ status: true, active: "education" });
                    }}
                  >
                    <PlusCircle size={20} /> Add Education{" "}
                  </button>
                </div>
                {data &&
                data?.data?.education &&
                data?.data?.education.length > 0 ? (
                  data?.data?.education.map(
                    ({ college, degree, enrolled, graduated, _id }: any) => (
                      <div key={_id}>
                        <EducationCard
                          id={_id}
                          degree={degree}
                          college={college}
                          enrolled={enrolled}
                          graduated={graduated}
                          educationCount={data?.data?.education.length}
                        />
                      </div>
                    )
                  )
                ) : (
                  <Empty />
                )}
              </Card>
            )}
            <Card width="12" style={{ marginTop: "10px" }}>
              <div className="flex justify-between items-center">
                <h1 className="text-xl W2STBold text-gray700 mb-3">
                  Work Experience
                </h1>
                <button
                  className="text-primary600 flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    setActive({ status: true, active: "workExperience" });
                  }}
                >
                  <PlusCircle size={20} /> Add Work Experience
                </button>
              </div>
              <div>
                {data &&
                data?.data?.workExperience &&
                data?.data?.workExperience.length > 0 ? (
                  data?.data?.workExperience.map(
                    ({ title, company, startDate, endDate, _id }: any) => (
                      <div key={_id}>
                        <ExperienceCard
                          id={_id}
                          title={title}
                          endDate={endDate}
                          company={company}
                          startDate={startDate}
                          educationCount={data?.data?.workExperience.length}
                        />
                      </div>
                    )
                  )
                ) : (
                  <Empty />
                )}
              </div>
            </Card>
            <Modal
              width="400px"
              footer={null}
              open={availabilityModal}
              onCancel={() => setAvailabilityModal(false)}
              title="Edit Availability"
            >
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  Availability Type {data?.data?.availabilityType}
                </label>
                <Select
                  value={availabilityType}
                  onChange={handleAvailabilityChange}
                  className="w-full"
                  size="large"
                >
                  <Option value="Virtual">Virtual Tutoring</Option>
                  <Option value="One-on-one">One-on-One Tutoring</Option>
                  <Option value="Both">Both</Option>
                </Select>
                <Button
                  type="primary"
                  className="mt-8 w-full"
                  size="large"
                  loading={loadingTutor}
                  disabled={!availabilityType}
                  onClick={() => {
                    mutate(availabilityType, {
                      onSuccess: () => {
                        setAvailabilityModal(false);
                      },
                    });
                  }}
                >
                  Continue
                </Button>
              </div>
            </Modal>
            <Modal
              width="600px"
              height="700px"
              footer={null}
              open={active?.status}
              onCancel={() => setActive(initialState)}
              title={
                active?.active === "education"
                  ? "Add Education"
                  : "Add Work Experience"
              }
            >
              {active?.active === "education" ? (
                <EditEducation resetForm={resetForm} />
              ) : (
                <EditWork resetForm={resetForm} />
              )}
            </Modal>
          </section>
        </section>
      )}

      {/* <div className="md:flex block md:gap-2">
        <section className="md:w-8/12 w-full">
          <section className="p-4 bg-white rounded-lg my-4">
            <div className="pb-5 border-b-[1px] border-gray200">
              <h3 className="text-2xl W2STBold">
                Welcome
                {tutor && tutor?.completed !== false
                  ? ''
                  : `, ${tutor?.firstname}`}
              </h3>
              <p>
                You have{' '}
                <span className="W2STBold underline text-primary700">
                  2 upcoming sessions
                </span>
              </p>
            </div>
            <ProfileCompletness />

            <h3 className="text-gray900 mb-3 mt-6 text-lg">Profile overview</h3>
            <div className="flex gap-2">
              <div className="p-4 bg-gray25 rounded-lg w-full border-[1px] border-gray100">
                <h3 className="text-gray500">Total session time</h3>
                <p className="W2STBold md:text-3xl text-lg text-gray900">
                  1,210 Mins
                </p>
              </div>
              <div className="p-4 bg-gray25 rounded-lg w-full border-[1px] border-gray100">
                <h3 className="text-gray500">Average rating</h3>
                <p className="W2STBold md:text-3xl text-lg text-gray900">
                  4.5/5
                </p>
              </div>
            </div>
          </section>
          <Card width="12">
            <section className="flex justify-between items-center ">
              <h3 className="text-gray900 text-lg">Recent Sessions</h3>
              <span className="text-primary600">View all</span>
            </section>
            <section className="mt-5">
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
            </section>
          </Card>
        </section>
        <section className="md:w-4/12 w-full">
          <section className="md:p-4 w-full md:mt-0 mt-4">
            <ProfileCard />
            <div className="p-4 bg-white rounded-lg">
              <h3 className="W2STBold font-2xl text-gray900">Payout</h3>
              <PayoutCard amount="1,210" date="July 23, 2023" status="paid" />
              <PayoutCard amount="210" date="July 31, 2023" status="pending" />
            </div>
            <AvailabilityCard />
            <BlogCard />
          </section>
        </section>
      </div> */}
    </TutorDashboardLayout>
  );
};

export default TutorProfile;
