import { Modal } from "antd";
import { useCheckStripeStatus } from "../../../hooks/api/Tutors/Stripe/useCheckStripeStatus";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti-boom";

const TutorProfileVerification = () => {
  const [status, setStatus] = useState<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const navigate = useNavigate();

  useCheckStripeStatus(setStatus);

  useEffect(() => {
    if (status) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
        navigate("/tutor/overview");
      }, 6000);
    }
  }, [status, navigate]);

  const logo = require("../../../assets/images/logo.png");

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-tr from-[#0F0068] to-[#9747FF]">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center w-96">
        <img src={logo} alt="Logo" className="mx-auto mb-4 h-12" />
        <h2 className="text-lg font-semibold text-gray-800">
          Verification in Progress
        </h2>
        <p className="text-gray-600 mt-2">
          Please be patient while we complete the process.
        </p>
      </div>

      {/* {showConfetti && status && ( */}
      <Confetti
        mode="fall"
        particleCount={100}
        colors={["#ff577f", "#ff884b", "#ffd384", "#fff9b0"]}
      />
      {/* )} */}
    </div>
  );
};

export default TutorProfileVerification;
