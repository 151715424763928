import { useInfiniteQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetInifiniteConversation = ({ id }: { id: string }) => {
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useInfiniteQuery({
    queryKey: ["getConversationsQueryStudent", id],
    queryFn: async ({ pageParam = 1 }) => {
      if (!token) throw new Error("Authorization token is missing");

      const response = await axiosInstance.get(
        `/conversations/bookings/${id}/messages/user?page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.data?.meta?.hasNextPage
        ? lastPage.data.meta.nextPage
        : undefined;
    },
    enabled: !!token,
  });
};
