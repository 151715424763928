import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

export const useIntiatePayout = () => {
  return useMutation(
    (id: any) => {
      const token = localStorage.getItem("W2ST_ADMIN_TOKEN");

      return axiosInstance
        .post(
          `admins/bookings/${id}/payout`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: async (data) => {
        message.success(data?.message);
        queryClient.invalidateQueries("useGetBookingsAdmin");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Failed... Please try again."
        );
      },
    }
  );
};
