import React, { useState } from "react";
import { useGetStudentsonAdminQuery } from "../../../hooks/api/Admin/Students/useGetStudentsonAdminQuery";
import { Checkbox, Table } from "antd";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { Dot } from "lucide-react";

const Students = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const { data, isLoading } = useGetStudentsonAdminQuery({
    search,
    isDeleted,
    isAccountVerified,
    isEmailVerified,
    page,
  });

  const navigate = useNavigate();

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Email Verified",
      dataIndex: "isEmailVerified",
      key: "isEmailVerified",
      render: (isEmailVerified: boolean) =>
        isEmailVerified ? (
          <span className="inline-flex items-center space-x-1 text-[#027A48] p-1 text-[12px] rounded-md bg-[#ECFDF3]">
            <span className="h-[7px] w-[7px] rounded-full bg-[#027A48]"></span>
            <span className="">Yes</span>
          </span>
        ) : (
          <span className="inline-flex items-center space-x-1 text-[#B42318] p-1 text-[12px] rounded-md bg-[#FEF3F2]">
            <span className="h-[7px] w-[7px] rounded-full bg-[#B42318]"></span>
            <span className="">No</span>
          </span>
        ),
    },
    // {
    //   title: "Account Verified",
    //   dataIndex: "isAccountVerified",
    //   key: "isAccountVerified",
    //   render: (isAccountVerified: boolean) =>
    //     isAccountVerified ? (
    //       <span className="inline-flex items-center space-x-1 text-[#027A48] p-1 text-[12px] rounded-md bg-[#ECFDF3]">
    //         <GoDotFill className="" />
    //         <span className="">Verified</span>
    //       </span>
    //     ) : (
    //       <span className="inline-flex items-center space-x-1 text-[#B42318] p-1 text-[12px] rounded-md bg-[#FEF3F2]">
    //         <GoDotFill className="" />
    //         <span className="">Unverified</span>
    //       </span>
    //     ),
    // },
    {
      title: "Joined",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => (
        <div>
          <Moment format="DD/MM/YYYY - h:mm a">{createdAt}</Moment>
          <br />
          <Moment fromNow className="text-gray-500">
            {createdAt}
          </Moment>
        </div>
      ),
    },
  ];

  const handleRowClick = (record: any) => {
    navigate(`/admin/students/${record.id}`);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: data?.data?.meta?.totalDocs || 0,
    current: data?.data?.meta?.page || 1,
    pageSize: data?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };
  return (
    <AdminDashboardLayout>
      <h3 className="W2STBold text-[20px]">
        Students ({data?.data?.meta?.totalDocs || 0})
      </h3>

      <div className="flex justify-end py-4 gap-4">
        <div className="flex items-center gap-1">
          <Checkbox
            value={isDeleted}
            onChange={() => {
              setIsDeleted(!isDeleted);
            }}
          />
          <span>Is Deleted</span>
        </div>
        <div className="flex items-center gap-1">
          <Checkbox
            value={isAccountVerified}
            onChange={() => {
              setIsAccountVerified(!isAccountVerified);
            }}
          />
          <span>Verified Account</span>
        </div>
        <div className="flex items-center gap-1">
          <Checkbox
            value={isEmailVerified}
            onChange={() => {
              setIsEmailVerified(!isEmailVerified);
            }}
          />
          <span>Verified Email</span>
        </div>
        <input
          name="search"
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e?.target?.value)}
          className={"p-[10px] rounded-md border-[1px] border-[#00000033]"}
        />
      </div>

      <Table
        dataSource={data?.data?.docs}
        columns={columns}
        rowKey="id"
        loading={isLoading}
        scroll={{ y: "60vh" }}
        className="cursor-pointer"
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={{ pageSize: 10 }}
      />
    </AdminDashboardLayout>
  );
};

export default Students;
