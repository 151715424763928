import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
import Card from "../../../components/Card";
import SessionCard from "../../../components/SessionCard";
import ProfileCard from "../../../components/Tutor/ProfileCard";
import AvailabilityCard from "../../../components/AvailabilityCard";
import BlogCard from "../../../components/BlogCard";
import { useGetTutorQuery } from "../../../hooks/api/Tutors/Auth/useGetTutorQuery";
import { Link } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import { useGetBookingsTutor } from "../../../hooks/api/Tutors/Bookings/useGetBookingsTutor";
import Moment from "react-moment";
import { Calendar } from "lucide-react";
import VerificationProgress from "./VerificationProgress";

const TutorOverview = () => {
  const { data: tutor } = useGetTutorQuery();
  const { data } = useGetBookingsTutor();

  const formatSessionTime = (minutes: number = 0): string => {
    if (minutes >= 1440) {
      const days = Math.round(minutes / 1440);
      return `${days} Day${days > 1 ? "s" : ""}`;
    } else if (minutes >= 60) {
      const hours = Math.round(minutes / 60);
      return `${hours} Hour${hours > 1 ? "s" : ""}`;
    } else {
      return `${minutes} Minute${minutes === 1 ? "" : "s"}`;
    }
  };

  return (
    <TutorDashboardLayout>
      <div className="md:flex block md:gap-2">
        <section className="md:w-8/12 w-full">
          <section className="p-4 bg-white rounded-lg my-4">
            <h3 className="text-[20px] W2STMedium">
              Welcome
              {tutor?.data?.firstName ? `, ${tutor.data.firstName}` : null}
            </h3>
            {/* <p>
                You have{" "}
                <span className="W2STBold underline text-primary700">
                  2 upcoming sessions
                </span>
              </p> */}
            {/* {JSON.stringify(tutor)} */}
            {tutor?.data?.isStripeOnboarded !== true && (
              <VerificationProgress tutor={tutor} />
            )}
            <h3 className="text-gray900 mb-3 mt-6 text-lg">Profile overview</h3>
            <div className="flex gap-2">
              <div className="p-4 bg-gray25 rounded-lg w-full border-[1px] border-gray100">
                <h3 className="text-gray500">Total session time</h3>
                <div className="W2STBold md:text-3xl text-lg text-gray900">
                  {formatSessionTime(tutor?.data?.totalSessionTime ?? 0)}{" "}
                  {tutor?.data?.totalSessionTime > 0 && (
                    <span className="ml-2 W2STLight text-[14px]">
                      ({tutor?.data?.totalSessionTime} Mins)
                    </span>
                  )}
                  {/* <span className="ml-2 W2STLight text-[14px]">
                    ({tutor?.data?.totalSessionTime} Mins)
                  </span> */}
                </div>
              </div>
              <div className="p-4 bg-gray25 rounded-lg w-full border-[1px] border-gray100">
                <h3 className="text-gray500">Average rating (coming soon)</h3>
                <p className="W2STBold md:text-3xl text-lg text-gray900">0</p>
              </div>
            </div>
          </section>
          <section className="mt-5">
            <Card width="12">
              <section className="flex justify-between items-center">
                <h3 className="text-gray900 text-lg">Recent Sessions</h3>
                <Link to="/tutor/bookings" className="text-primary600">
                  View all
                </Link>
              </section>
              <section className="mt-5">
                {data?.data?.meta?.totalDocs < 1 && (
                  <EmptyState
                    title="No recent sessions found"
                    desc="Your activity will appear here once you start a session"
                  />
                )}

                {data?.data?.docs
                  .slice(0, 3)
                  .map(
                    ({
                      subject,
                      tutorShipType,
                      duration,
                      student,
                      date,
                    }: any) => (
                      <div className="flex gap-3 mb-6">
                        <span className="h-[60px] w-[60px] bg-primary100 text-primary600 rounded-full flex items-center justify-center">
                          <Calendar />
                        </span>
                        <div className="flex-1">
                          <p className="text-gray900 W2STMedium">
                            {student?.firstName}
                          </p>
                          <span className="text-gray800 capitalize">
                            {subject} | {tutorShipType} session | {duration}{" "}
                            mins
                          </span>
                          <div className="text-gray500 text-[14px]">
                            <Moment format="DD/MM/YY hh:mm a" interval={0}>
                              {date}
                            </Moment>{" "}
                            - (
                            <Moment interval={0} fromNow>
                              {date}
                            </Moment>
                            )
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </section>
            </Card>
          </section>{" "}
        </section>
        <section className="md:w-4/12 w-full">
          <section className="md:p-4 w-full md:mt-0 mt-4">
            <ProfileCard />
            <AvailabilityCard data={tutor} />
            {/* <div className="p-4 bg-white rounded-lg">
              <h3 className="W2STBold font-2xl text-gray900">Payout</h3>
              <PayoutCard amount="1,210" date="July 23, 2023" status="paid" />
              <PayoutCard amount="210" date="July 31, 2023" status="pending" />
            </div> */}
            {/* <BlogCard /> */}
          </section>
        </section>
      </div>
    </TutorDashboardLayout>
  );
};

export default TutorOverview;
