import { Trash2Icon } from "lucide-react";
import React, { useState } from "react";
import Moment from "react-moment";
import { useRemoveWorkExperience } from "../../../../hooks/api/Tutors/UpdateProfile/useRemoveWorkExperience";
import { Modal } from "antd";

const ExperienceCard = ({
  title,
  company,
  startDate,
  endDate,
  id,
  educationCount,
}: any) => {
  const [active, setActive] = useState(false);

  const { mutate } = useRemoveWorkExperience();

  return (
    <div className="mb-[30px]">
      <p className="text-primary900 mb-1 W2STMedium">{title}</p>
      <p className="text-gray700 mb-1">{company}</p>
      <div className="text-gray700">
        <Moment format="YYYY" interval={0}>
          {startDate}
        </Moment>
        {" - "}
        {endDate === null ? (
          "Present"
        ) : (
          <Moment format="YYYY" interval={0}>
            {endDate}
          </Moment>
        )}
      </div>
      {educationCount > 1 && (
        <div className="mt-3">
          <button
            onClick={() => setActive(true)}
            className="flex gap-2 items-center text-red-700 text-[12px] cursor-pointer rounded-md"
          >
            <Trash2Icon size={15} />
            <span>Delete</span>
          </button>
        </div>
      )}

      <Modal
        width="400px"
        footer={null}
        open={active}
        onCancel={() => setActive(false)}
        title={`Delete Expereience from ${company}`}
      >
        <p className="text-gray700">
          Confirm that you'd like to delete this work experience. Note that this
          process is irreversible
        </p>
        <div className="flex gap-2 mt-4">
          <button
            onClick={() => {
              mutate(id, {
                onSuccess: () => {
                  setActive(false);
                },
              });
            }}
            className="p-3 rounded-md bg-red-600 text-white flex-1"
          >
            Yes
          </button>
          <button
            onClick={() => setActive(false)}
            className="p-3 rounded-md bg-primary100 text-primary700 flex-1"
          >
            No
          </button>
        </div>
      </Modal>

      {/* <div className="flex gap-5 mt-3">
        <span className="flex gap-2 items-center text-primary600 cursor-pointer">
          <AiOutlineEdit className="text-2xl" />
          <span>Edit</span>
        </span>
        <span className="flex gap-2 items-center text-error500 cursor-pointer">
          <FaRegTrashCan />
          <span>Delete</span>
        </span>
      </div> */}
    </div>
  );
};

export default ExperienceCard;
