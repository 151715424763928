import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

interface GetBookingsParams {
  page: number;
  search: string;
  status: string;
  startDate: string;
  endDate: string;
}

export const useGetBookingsAdmin = ({
  page,
  search,
  status,
  startDate,
  endDate,
}: GetBookingsParams) => {
  const token = localStorage.getItem("W2ST_ADMIN_TOKEN");

  return useQuery({
    queryKey: ["useGetBookingsAdmin", search, status, page, startDate, endDate],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");

      const params = {
        ...(search && { search }),
        ...(status && { status }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
        page,
      };
      const response = await axiosInstance.get(`admins/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      return response.data;
    },
    onSuccess: (data) => {},
    onError: (error: unknown) => {},
    enabled: !!token,
  });
};
