import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const logo = require("../assets/images/logo.png");

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-tr from-[#0F0068] to-[#9747FF]">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center w-96">
        <img src={logo} alt="Logo" className="mx-auto mb-4 h-12" />
        <h2 className="text-[18px] W2STBold text-gray-800">
          Oops! Something went terribly wrong.{" "}
        </h2>
        <p className="text-xl mb-6">
          We can't seem to find the page you're looking
          <br /> for. Don't worry, we can get you back on track!
        </p>
        <div className="flex justify-center gap-2 items-center">
          <button
            onClick={handleGoHome}
            className="px-6 py-3 bg-primary500 text-white rounded-lg W2STMedium text-lg transition transform hover:scale-105 active:scale-95 focus:outline-none"
          >
            Return to Home
          </button>
          <button
            onClick={() => navigate(-1)}
            className="px-6 py-3 border-[1px] border-primary500 text-black rounded-lg font-medium text-lg"
          >
            Go back
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
