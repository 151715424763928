import { Input, Select, Form, TimePicker, Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";

const { Option } = Select;
const SearchFilters = ({
  handleSearch,
  setPayload,
  payload,
  subjects,
  daysOfWeek,
}: any) => {
  return (
    <Form layout="vertical" className="md:p-5">
      <Form.Item label="Search Name">
        <Input
          placeholder="Search name"
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
          className="p-2"
        />
      </Form.Item>
      <Divider />
      <Form.Item label="Select Subject">
        <Select
          placeholder="Choose a subject"
          className="w-full"
          onChange={(value) => setPayload({ ...payload, subject: value })}
          value={payload.subject || undefined}
        >
          {subjects?.data?.docs.map(({ subject, id }: any) => (
            <Option key={id} value={subject} className="uppercase p-2">
              {subject}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select Day">
        <Select
          placeholder="Choose a day"
          className="w-full"
          onChange={(day) => setPayload({ ...payload, days: day })}
          value={payload.days || undefined}
        >
          {daysOfWeek.map((day: string) => (
            <Option key={day} value={day} className="uppercase">
              {day}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select Time Range">
        <TimePicker.RangePicker
          className="w-full"
          format="HH:mm"
          onChange={(
            dates: [Dayjs | null, Dayjs | null] | null,
            dateStrings: [string, string]
          ) => {
            if (dates && dates[0] && dates[1]) {
              setPayload({
                ...payload,
                from: dates[0].format("HH:mm"),
                to: dates[1].format("HH:mm"),
              });
            } else {
              setPayload({ ...payload, from: undefined, to: undefined });
            }
          }}
          value={
            payload.from && payload.to
              ? [dayjs(payload.from, "HH:mm"), dayjs(payload.to, "HH:mm")]
              : undefined
          }
        />
      </Form.Item>{" "}
    </Form>
  );
};

export default SearchFilters;
