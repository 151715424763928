// import { Menu, Dropdown, message } from "antd";
// import { useNavigate } from "react-router-dom";
// import { UserOutlined } from "@ant-design/icons";

// const TutorLogoutButton = () => {
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     localStorage.removeItem("W2ST_TUTOR_TOKEN");
//     localStorage.removeItem("hasSeenWelcomeLoader");
//     message.success("Logout successful");
//     navigate("/tutor/signin");
//   };

//   const menu = (
//     <Menu>
//       <Menu.Item key="logout"  onClick={handleLogout} icon={<UserOutlined />}>
//         Logout
//       </Menu.Item>
//     </Menu>
//   );

//   return (
//     <Dropdown overlay={menu} trigger={["hover"]} placement="bottomRight">
//       <img src={Avatar} alt="User Avatar" className="h-[40px] cursor-pointer" />
//     </Dropdown>
//   );
// };

// export default TutorLogoutButton;

import { Menu, Dropdown, message } from "antd";
import { DownCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useGetTutorQuery } from "../hooks/api/Tutors/Auth/useGetTutorQuery";

const TutorLogoutButton = () => {
  const navigate = useNavigate();
  const Avatar = require("../assets/images/avatar.png");
  const { data: tutor } = useGetTutorQuery();

  const handleLogout = () => {
    localStorage.removeItem("W2ST_TUTOR_TOKEN");
    localStorage.removeItem("hasSeenWelcomeLoader");
    message.success("Logout successful");
    navigate("/tutor/signin");
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="logout"
        onClick={handleLogout}
        icon={<LogoutOutlined size={20} />}
        style={{ color: "white", backgroundColor: "red", fontWeight: "bold" }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["hover"]} placement="bottomRight">
      <div className="flex items-center gap-2 cursor-pointer">
        <img
          src={tutor?.data?.profilePicture}
          alt="User Avatar"
          className="h-[30px] w-[30px] rounded-full"
        />
        <DownCircleOutlined size={20} className="text-gray-500" />
      </div>
    </Dropdown>
  );
};

export default TutorLogoutButton;
