import React from "react";
import { LocationIcon } from "../../../../components/Icon/LocationIcon";
import { VirtualIcon } from "../../../../components/Icon/VirtualIcon";

interface AvailabilityBadgeProps {
  availabilityType: string;
}

const AvailabilityBadge: React.FC<AvailabilityBadgeProps> = ({
  availabilityType,
}) => {
  return (
    <div className="mt-3 text-[12px] flex flex-nowrap overflow-x-auto scrollbar-none gap-1 whitespace-nowrap">
      {availabilityType === "Virtual" && (
        <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 flex items-center gap-2">
          <VirtualIcon /> <span>Virtual Tutoring</span>
        </span>
      )}

      {availabilityType === "One-on-one" && (
        <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 flex items-center gap-2">
          <LocationIcon />
          <span>One-on-One Tutoring</span>
        </span>
      )}

      {availabilityType === "Both" && (
        <>
          <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 flex items-center gap-2">
            <LocationIcon />
            <span>One-on-One Tutoring</span>
          </span>
          <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 flex items-center gap-2">
            <VirtualIcon /> <span>Virtual Tutoring</span>
          </span>
        </>
      )}
    </div>
  );
};

export default AvailabilityBadge;
