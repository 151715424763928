import { Empty } from "antd";
import Loader from "../../../components/Loader";
import { useEffect, useMemo, useRef } from "react";
import { useGetInifiniteConversationAdmin } from "../../../hooks/api/Admin/Bookings/useGetInifiniteConversationAdmin";

const AdminChatBox = ({ id }: { id: string }) => {
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useGetInifiniteConversationAdmin({
      id,
    });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const messages = useMemo(
    () => data?.pages.flatMap((page) => page.data.docs) || [],
    [data]
  );

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex flex-col space-y-3 p-2 h-[80vh] overflow-y-auto bg-[#1E1B30] border rounded-lg scrollbar-none text-white">
      {isLoading && <Loader />}
      {!isLoading && messages.length === 0 && (
        <Empty description="">
          <span className="text-white">No messages yet</span>
        </Empty>
      )}
      <div className="overflow-y-auto min-h-[60vh] p-4">
        {messages
          .slice()
          .reverse()
          .map(({ id, message, sender, deliveredAt }) => {
            const formattedDate = deliveredAt
              ? new Date(deliveredAt).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                  month: "short",
                  day: "numeric",
                })
              : "";

            const isTutor = sender === "Tutor";
            const isStudent = sender === "Student";
            const isAdmin = sender === "Admin";

            return (
              <div
                key={id}
                className={`flex ${
                  isTutor ? "justify-start" : isStudent ? "justify-end" : ""
                } ${isAdmin ? "bg-black text-white rounded-lg" : ""} mb-3`}
              >
                <div
                  className={`rounded-lg p-3 text-sm shadow-md ${
                    isAdmin ? "w-full bg-black text-white" : "max-w-[70%]"
                  } ${isTutor ? "bg-[#6A4C93] text-white" : ""} 
         ${isStudent ? "bg-[#9B5DE5] text-white self-end" : ""}`}
                >
                  <p className="capitalize">{message}</p>
                  {formattedDate && (
                    <p className="text-gray-500 mt-1 text-right text-[14px]">
                      {formattedDate}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default AdminChatBox;
