import React, { useState } from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { Button, Checkbox, message, Modal, Table } from "antd";
import Moment from "react-moment";
import { useGetSubjectsQuery } from "../../../hooks/api/Admin/Courses/useGetSubjectsQuery";
import { useAddSubjectMutation } from "../../../hooks/api/Admin/Courses/useAddSubjectMutation";
import { useDeleteSubjectMutation } from "../../../hooks/api/Admin/Courses/useDeleteSubjectMutation";
import { CircleX, PlusCircle } from "lucide-react";

const AdminSubjects = () => {
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [subject, setSubject] = useState("");

  const { data: subjects, isLoading } = useGetSubjectsQuery({ page });
  const { mutateAsync } = useAddSubjectMutation();
  const { mutateAsync: deleteSubject } = useDeleteSubjectMutation();

  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (subject: string) => (
        <span className="capitalize">{subject}</span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => (
        <div>
          <Moment format={"DD/MM/YYYY"}>{createdAt}</Moment>
          <br />
          <Moment format={"h:mm a"}>{createdAt}</Moment>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: string, record: any) => (
        <button
          className="bg-red-500 text-[12px] text-white rounded-md p-2"
          onClick={() => {
            setSelectedSubject(record);
            setDeleteModalOpen(true);
          }}
        >
          Delete
        </button>
      ),
    },
  ];

  const handleSubmit = async () => {
    try {
      await mutateAsync({ subject });
      setOpen(false);
      setSubject("");
    } catch (error) {
      console.error("Error adding subject:", error);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: subjects?.data?.meta?.totalDocs || 0,
    current: subjects?.data?.meta?.page || 1,
    pageSize: subjects?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };

  const handleDeleteConfirm = async () => {
    const subject = selectedSubject?.subject;
    try {
      await deleteSubject({
        subject,
      });
      setDeleteModalOpen(false);
      setSelectedSubject(null);
    } catch (error) {
      message.error("Error deleting subject");
    }
  };

  return (
    <AdminDashboardLayout>
      <div className="flex justify-between items-center mb-3">
        <h3 className="W2STBold text-[20px]">
          Courses ({subjects?.data?.meta?.totalDocs || 0})
        </h3>
        <button
          className="w2st-button text-tmd"
          onClick={() => {
            setOpen(true);
          }}
        >
          Add New Course
        </button>
      </div>

      <Table
        dataSource={subjects?.data?.docs}
        columns={columns}
        rowKey="id"
        loading={isLoading}
        className="cursor-pointer"
        scroll={{ y: "60vh" }}
        pagination={paginationConfig}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        footer={null}
        closable={false}
        width="400px"
      >
        <div className="flex justify-between items-center mb-3">
          <h3 className="W2STBold font-tlg font-bold">Add a Subject</h3>
          <CircleX
            size={20}
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setSubject("");
            }}
          />
        </div>
        <input
          name="subject"
          type="subject"
          value={subject}
          placeholder="Type Subject name here"
          className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100 capitalize"
          onChange={(e) => setSubject(e.target.value)}
        />
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={!subject}
          className={`px-4 block mx-auto w-full  text-white py-5 rounded-md  mt-4 ${
            !subject
              ? "bg-primary300 cursor-not-allowed"
              : "bg-primary800 cursor-pointer shadow-md"
          }`}
        >
          Submit
        </button>
      </Modal>

      <Modal
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onOk={handleDeleteConfirm}
        title="Confirm Delete"
        okText="Delete"
        cancelText="Cancel"
        className="max-w-[400px]"
      >
        <p>Are you sure you want to delete the subject?</p>
      </Modal>
    </AdminDashboardLayout>
  );
};

export default AdminSubjects;
