import React from "react";
import { Form, Input, Button, DatePicker, Space, Card } from "antd";
import moment from "moment";
import { useAddEducation } from "../../../../hooks/api/Tutors/UpdateProfile/useAddEducation";
import { useAddWorkExperience } from "../../../../hooks/api/Tutors/UpdateProfile/useAddWorkExperience";

const EditWork = ({ resetForm }: any) => {
  const [form] = Form.useForm();
  const { mutate, isLoading } = useAddWorkExperience();

  const onFinish = (values: any) => {
    const formattedValues = {
      ...values,
      startDate: values.startDate
        ? moment(values.startDate).format("YYYY-MM-DD")
        : null,
      endDate: values.endDate
        ? moment(values.endDate).format("YYYY-MM-DD")
        : null,
    };

    mutate(formattedValues, {
      onSuccess: () => {
        form.resetFields();
        resetForm();
      },
    });
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Space
        direction="vertical"
        style={{ display: "flex", width: "100%", padding: 0 }}
      >
        <Card size="small">
          <Form.Item name="title" label="Job Title">
            <Input placeholder="Enter Job Title" size="large" />
          </Form.Item>

          <Form.Item name="company" label="Company">
            <Input placeholder="Enter Company Name" size="large" />
          </Form.Item>

          <div className="flex gap-2">
            <Form.Item name="startDate" label="Start Date" className="flex-1">
              <DatePicker style={{ width: "100%" }} size="large" />
            </Form.Item>

            <Form.Item name="endDate" label="End Date" className="flex-1">
              <DatePicker style={{ width: "100%" }} size="large" />
            </Form.Item>
          </div>

          <Form.Item name="description" label="Job Description">
            <Input.TextArea
              placeholder="Enter Job Description"
              size="large"
              rows={4}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Card>
      </Space>
    </Form>
  );
};

export default EditWork;
