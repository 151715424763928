import React, { useState } from "react";
import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
import { useGetBookingsTutor } from "../../../hooks/api/Tutors/Bookings/useGetBookingsTutor";
import { Button, Drawer, Input, Modal, Table, Tag } from "antd";
import { useConfirmTutorBooking } from "../../../hooks/api/Tutors/Bookings/useConfirmTutorBooking";
import TutorShipTypeTag from "../../../components/TutorshipTypeTag";
import StatusTag from "../../../components/StatusTag";
import { useGetSingleBookingQuery } from "../../../hooks/api/Students/Bookings/useGetSingleBookingQuery";
import Loader from "../../../components/Loader";
import BookingDetails from "./BookingDetails";
import TutorChatBox from "./TutorChatBox";
import TutorSessionCard from "./TutorSessionCard";
import { useSendConversationsMutation } from "../../../hooks/api/Tutors/Conversation/useSendConversationsMutation";
import EmptyState from "../../../components/EmptyState";

const TutorBookings = () => {
  const { data } = useGetBookingsTutor();

  const [chatVisible, setChatVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<any>(null);

  const [trigger, setTrigger] = useState(false);

  const [visible, setVisible] = useState("");

  const closeModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const { isLoading } = useConfirmTutorBooking({
    id: selectedBooking?.id ?? "",
    trigger: !!selectedBooking && trigger,
    closeModal: closeModal,
  });

  // const handleConfirmClick = (record: any) => {
  //   setSelectedBooking(record);
  //   setIsModalVisible(true);
  //   setVisible("confirm");
  // };

  // const handleSeeMore = (record: any) => {
  //   setSelectedBooking(record);
  //   setIsModalVisible(true);
  //   setVisible("more");
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedBooking(null);
    setVisible("");
  };

  // const handleChatClick = (record: any) => {
  //   // if (!isChatEnabled(record.date)) {
  //   //   message.info("It'll be available 1 hour to the session");
  //   // } else {
  //   setSelectedId(record.id);
  //   setChatVisible(true);
  //   // }
  // };

  const [messageInput, setMessageInput] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [student, setStudent] = useState("");

  const { mutateAsync: sendMessage, isLoading: isSendingMessage } =
    useSendConversationsMutation({
      id: selectedId,
      setMessageInput,
    });
  const handleSendMessage = () => {
    sendMessage({ message: messageInput, sender: "Tutor", type: "text" });
  };

  const { data: details, isLoading: isDetailsLoading } =
    useGetSingleBookingQuery({ id: selectedId ?? "" });

  return (
    <TutorDashboardLayout>
      <div className="flex justify-between items-center">
        <h3 className="W2STMedium text-[20px] my-4">
          Bookings ({data?.data?.meta?.totalDocs || 0})
        </h3>
      </div>

      <div>
        {data?.data?.docs.length === 0 && (
          <EmptyState
            title="There are no bookings yet."
            desc="Booking details would be updated when a student books you"
          />
        )}
      </div>

      <div className="grid md:grid-cols-2 grid-cols-1 gap-[10px]">
        {data?.data?.docs?.map(
          ({
            id,
            subject,
            tutorShipType,
            date,
            timeSlots,
            tutor,
            status,
            price,
            notes,
            duration,
            student,
          }: any) => (
            <div key={notes} className="">
              <TutorSessionCard
                id={id}
                subject={subject}
                tutorShipType={tutorShipType}
                date={date}
                tutor={tutor}
                duration={duration}
                timeSlots={timeSlots}
                status={status}
                price={price}
                notes={notes}
                student={student}
              />
            </div>
          )
        )}
      </div>

      {/* <Table dataSource={data?.data?.docs} columns={columns} rowKey="id" /> */}
      <Modal
        title="Booking Confirmation"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          visible === "confirm" && (
            <Button
              variant="filled"
              key="confirm"
              disabled={isLoading || selectedBooking?.status === "confirmed"}
              onClick={() => setTrigger(true)}
            >
              Confirm Booking
            </Button>
          ),
        ].filter(Boolean)}
      >
        {selectedBooking && visible === "confirm" && (
          <div className="mt-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Subject:</strong>
                  <span className="capitalize text-gray-700">
                    {" "}
                    {selectedBooking.subject}
                  </span>
                </p>
              </div>

              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Tutorship Type:</strong>{" "}
                  <TutorShipTypeTag type={selectedBooking.tutorShipType} />
                </p>
              </div>

              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Booking ID:</strong>{" "}
                  {selectedBooking.id}
                </p>
              </div>

              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Date:</strong>
                  {new Date(selectedBooking.date).toLocaleString()}
                </p>
              </div>

              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Time Slots:</strong>
                  {selectedBooking.timeSlots.join(", ")}
                </p>
              </div>

              <div>
                <p className="text-gray-600 flex items-center">
                  <strong className="text-gray-800 mr-3">Status:</strong>
                  <StatusTag status={selectedBooking.status} />
                </p>
              </div>

              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Price:</strong>
                  <span className="text-green-600 font-semibold">
                    {" "}
                    ${selectedBooking.price}
                  </span>
                </p>
              </div>

              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Duration:</strong>{" "}
                  {selectedBooking.duration} mins
                </p>
              </div>

              <div className="col-span-2">
                <p className="text-gray-600">
                  <strong className="text-gray-800">Notes:</strong>{" "}
                  {selectedBooking.notes.join(", ")}
                </p>
              </div>
            </div>
          </div>
        )}
        {selectedBooking && visible === "more" && (
          <div>
            {isDetailsLoading && <Loader />}
            {!isDetailsLoading && details && (
              <div>
                <BookingDetails booking={details?.data} />
              </div>
            )}
          </div>
        )}
      </Modal>
      <Drawer
        title={`Chat with ${student}`}
        placement="right"
        width={350}
        onClose={() => {
          setChatVisible(false);
          setStudent("");
        }}
        open={chatVisible}
      >
        <TutorChatBox id={selectedId} />
        <div className="mt-4 flex gap-2">
          <Input
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="Type a message..."
            disabled={isSendingMessage}
          />
          <Button
            type="primary"
            onClick={handleSendMessage}
            disabled={isSendingMessage}
          >
            Send
          </Button>
        </div>
      </Drawer>
    </TutorDashboardLayout>
  );
};

export default TutorBookings;
