import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetBookingsMessagesAdmin = (id: string) => {
  const token = localStorage.getItem("W2ST_ADMIN_TOKEN");

  return useQuery({
    queryKey: ["useGetBookingsMessagesAdmin", id],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");

      const response = await axiosInstance.get(`admins/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onSuccess: (data) => {},
    onError: (error: unknown) => {},
    enabled: !!token,
  });
};
