import React from "react";
import {
  LucideUser,
  LucideGraduationCap,
  Calendar,
  BookOpen,
} from "lucide-react";
import { Card, Spin } from "antd";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { useGetBalanceQuery } from "../../../hooks/api/Admin/Stripe/useGetBalanceQuery";
import { useGetBookingsAdmin } from "../../../hooks/api/Admin/Bookings/useGetBookingsAdmin";
import { useGetStudentsonAdminQuery } from "../../../hooks/api/Admin/Students/useGetStudentsonAdminQuery";
import { useGetTutorsonAdminQuery } from "../../../hooks/api/Admin/Tutors/useGetTutorsonAdminQuery";
import { useGetSubjectsQuery } from "../../../hooks/api/Admin/Courses/useGetSubjectsQuery";

const AdminOverview = () => {
  const { data: stripeBalance } = useGetBalanceQuery();
  const { data: bookings, isLoading: isLoadingBookings } = useGetBookingsAdmin({
    page: 1,
    search: "",
    status: "completed",
    startDate: "",
    endDate: "",
  });
  const { data: students, isLoading: isLoadingStudents } =
    useGetStudentsonAdminQuery({ page: 1 });

  const { data: tutors, isLoading: isLoadingTutors } = useGetTutorsonAdminQuery(
    { page: 1 }
  );

  const { data: subjects, isLoading } = useGetSubjectsQuery({ page: 1 });

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency.toUpperCase(),
    }).format(amount / 100);
  };

  return (
    <AdminDashboardLayout>
      <div className="flex flex-col p-4 space-y-6">
        <h1 className="text-2xl font-semibold">Dashboard Overview</h1>

        <Card className="p-4 shadow-md">
          <h3 className="text-lg font-semibold mb-2">Stripe Balance</h3>
          {stripeBalance ? (
            <p className="text-gray-700">
              Available:{" "}
              {formatCurrency(
                stripeBalance.available[0]?.amount,
                stripeBalance.available[0]?.currency
              )}
            </p>
          ) : (
            <Spin />
          )}
        </Card>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          <Card className="p-4 shadow-md">
            <div className="flex items-center space-x-4">
              <LucideUser className="h-8 w-8 text-blue-600" />
              <h2 className="text-lg font-semibold">Students</h2>
            </div>
            {isLoadingStudents ? (
              <Spin />
            ) : (
              <p>
                Total Students:{" "}
                <strong>{students?.data?.meta?.totalDocs ?? 0}</strong>
              </p>
            )}
          </Card>

          <Card className="p-4 shadow-md">
            <div className="flex items-center space-x-4">
              <LucideGraduationCap className="h-8 w-8 text-green-600" />
              <h2 className="text-lg font-semibold">Tutors</h2>
            </div>
            {isLoadingTutors ? (
              <Spin />
            ) : (
              <p>
                Total Tutors:{" "}
                <strong>{tutors?.data?.meta?.totalDocs ?? 0}</strong>
              </p>
            )}
          </Card>

          <Card className="p-4 shadow-md">
            <div className="flex items-center space-x-4">
              <Calendar className="h-8 w-8 text-red-400" />
              <h2 className="text-lg font-semibold">Bookings</h2>
            </div>
            {isLoadingBookings ? (
              <Spin />
            ) : (
              <p>
                Completed Bookings:{" "}
                <strong>{bookings?.data?.meta?.totalDocs ?? 0}</strong>
              </p>
            )}
          </Card>

          <Card className="p-4 shadow-md">
            <div className="flex items-center space-x-4">
              <BookOpen className="h-8 w-8 text-yellow-400" />
              <h2 className="text-lg font-semibold">Courses</h2>
            </div>
            {isLoadingBookings ? (
              <Spin />
            ) : (
              <p>
                Total Courses:{" "}
                <strong>{subjects?.data?.meta?.totalDocs ?? 0}</strong>
              </p>
            )}
          </Card>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminOverview;
