import React, { useState } from "react";
import { useGetTutorQuery } from "../../../../hooks/api/Students/Tutors/useGetTutorQuery";
import { message, Select } from "antd";
import { useCreatePaymentIntentMutation } from "../../../../hooks/api/Students/Bookings/useCreatePaymentIntentMutation";
import Loader from "../../../../components/Loader";
import SecondaryButton from "../../../../components/SecondaryButton";
import { LoadingOutlined } from "@ant-design/icons";
import { useGetTutorAvailabilityQuery } from "../../../../hooks/api/Students/Bookings/useGetTutorAvailabilityQuery";

const initialState = {
  tutorId: "",
  subject: "",
  tutorShipType: "",
  date: "",
  startTime: "",
  endTime: "",
  notes: "",
};

const TutorBookingForm = ({
  id,
  setVisible,
}: {
  id: string;
  setVisible: any;
}) => {
  const [formData, setFormData] = useState({
    tutorId: id,
    subject: "",
    tutorShipType: "",
    date: "",
    startTime: "",
    endTime: "",
    notes: "",
  });

  const { data: tutor, isLoading } = useGetTutorQuery({ id });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectTimeSlot = (
    day: string,
    startTime: string,
    endTime: string
  ) => {
    const today = new Date();
    const dayIndex = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ].indexOf(day);
    const currentDayIndex = today.getDay();

    let offset = dayIndex - currentDayIndex;
    if (offset <= 0) {
      offset += 7;
    }

    const selectedDate = new Date(today);
    selectedDate.setDate(today.getDate() + offset);

    setFormData({
      ...formData,
      date: selectedDate.toISOString(),
      startTime,
      endTime,
    });
  };

  const handleCancelTimeSlot = () => {
    setFormData((prev) => ({
      ...prev,
      date: "",
      startTime: "",
      endTime: "",
    }));
  };

  const { mutateAsync, isLoading: isPaying } = useCreatePaymentIntentMutation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.subject || !formData.date || !formData.startTime) {
      message.info("Please complete all required fields.");
      return;
    }
    mutateAsync(formData, {
      onSuccess: () => {
        setFormData(initialState);
        setVisible(false);
      },
    });
  };

  const { Option } = Select;

  const { data: wtf } = useGetTutorAvailabilityQuery({
    tutorId: id,
    month: "2025-01",
  });

  return (
    <div>
      {isLoading && !tutor && <Loader />}
      <form
        onSubmit={handleSubmit}
        className="space-y-4 p-4 border rounded shadow mt-3"
      >
        <div>
          {/* <p>{JSON.stringify(wtf?.data)}</p> */}
          <label className="block text-sm font-medium mb-2">Subject</label>
          <Select
            placeholder="Select Status"
            onChange={(value) => setFormData({ ...formData, subject: value })}
            size="large"
            className="w-full mb-4"
            value={formData?.subject}
          >
            <Option value={""} disabled className="capitalize">
              Select option
            </Option>
            {tutor.data.areaOfExpertise.map((subject: string, index: any) => (
              <Option key={index} value={subject} className="capitalize">
                {subject}
              </Option>
            ))}
          </Select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">
            Tutorship Type
          </label>
          {/* <select
            name="tutorShipType"
            value={formData.tutorShipType}
            onChange={handleChange}
            className="border border-gray-300 rounded-md p-4 block mt-3 outline-none w-full"
          >
            <option>Select Option</option>
            {tutor?.data?.availabilityType === "One-on-one" && (
              <option value="One-on-one">One-on-One Tutoring</option>
            )}
            {tutor?.data?.availabilityType === "Virtual" && (
              <option value="Virtual">Virtual Tutoring</option>
            )}
            {tutor?.data?.availabilityType === "Both" && (
              <>
                <option value="Virtual">Virtual Tutoring</option>
                <option value="One-on-one">One-on-One Tutoring</option>
              </>
            )}
          </select> */}
          <Select
            placeholder="Select Status"
            onChange={(value) =>
              setFormData({ ...formData, tutorShipType: value })
            }
            size="large"
            className="w-full mb-4"
            value={formData?.tutorShipType}
          >
            <Option value={""} disabled className="capitalize">
              Select option
            </Option>
            {tutor?.data?.availabilityType === "One-on-one" && (
              <Option value="One-on-one">One-on-One Tutoring</Option>
            )}
            {tutor?.data?.availabilityType === "Virtual" && (
              <Option value="Virtual">Virtual Tutoring</Option>
            )}
            {tutor?.data?.availabilityType === "Both" && (
              <>
                <Option value="Virtual">Virtual Tutoring</Option>
                <Option value="One-on-one">One-on-One Tutoring</Option>
              </>
            )}
          </Select>
        </div>

        <div className="bg-black text-white p-3">
          {JSON.stringify(formData?.startTime)} -{" "}
          {JSON.stringify(formData?.endTime)}
        </div>

        {formData.startTime && formData.endTime ? (
          <div>
            <div className="text-lg mb-4">
              <h3 className="font-semibold mb-2">Selected Time Slot:</h3>
              {formData.startTime} - {formData.endTime} on <br />
              {new Date(formData.date)
                .toLocaleDateString("en-US", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })
                .replace(/(\d+)/, (match) => {
                  const num = parseInt(match);
                  const suffix = ["th", "st", "nd", "rd"][
                    (num > 3 && num < 21) || num % 10 > 3 ? 0 : num % 10
                  ];
                  return `${match}${suffix}`;
                })}
            </div>
            <button
              type="button"
              onClick={handleCancelTimeSlot}
              className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
            >
              Cancel Selection
            </button>
          </div>
        ) : (
          <div>
            <h3 className="text-lg font-semibold mb-4">Available Time Slots</h3>
            <div className="mt-6 overflow-y-scroll">
              {tutor?.data?.weeklyAvailability?.length > 0 ? (
                tutor.data.weeklyAvailability.map(
                  ({ day, timeSlots }: { day: string; timeSlots: any }) => (
                    <div className="flex mb-3" key={day}>
                      <div className="w-4/12 font-medium">{day}</div>
                      <div className="w-8/12">
                        {timeSlots?.length > 0 ? (
                          timeSlots.map(
                            ({ startTime, endTime }: any, index: number) => (
                              <div
                                key={`${startTime}-${index}`}
                                className="mb-3 flex justify-between items-center"
                              >
                                <span className="text-sm">
                                  {startTime} - {endTime}
                                </span>
                                <button
                                  onClick={() =>
                                    handleSelectTimeSlot(
                                      day,
                                      startTime,
                                      endTime
                                    )
                                  }
                                  className="bg-green-500 text-white text-sm px-3 py-1 rounded hover:bg-green-600"
                                >
                                  Select
                                </button>
                              </div>
                            )
                          )
                        ) : (
                          <span className="text-sm text-gray-500">
                            No availability
                          </span>
                        )}
                      </div>
                    </div>
                  )
                )
              ) : (
                <p>No available time slots.</p>
              )}
            </div>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium">Notes</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            placeholder="Anything you want the tutor to know ahead of time?"
            className="resize-none border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full h-[150px]"
          />
        </div>
        <div>
          <span className="block text-sm font-medium">Hourly Rate</span>$
          {JSON.stringify(tutor.data?.hourlyRate)}
        </div>
        {/* <SecondaryButton title={"Submit"} loading={isPaying} /> */}

        <button
          // onClick={handleClick}
          className={`text-white px-[27px] w-full py-[10px] rounded-md bg-primary900 W2STMedium ${
            isPaying ? "opacity-50" : ""
          }`}
          disabled={isPaying}
        >
          {isPaying ? <LoadingOutlined /> : "Book Tutor"}
        </button>
        <p className="text-gray-500">
          Note: You will be redirected to Stripe to complete the payment for the
          session.
        </p>
      </form>
    </div>
  );
};

export default TutorBookingForm;
