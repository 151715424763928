import { Calendar, Clock2 } from "lucide-react";
import { Button, Divider, Drawer, Modal } from "antd";
import clsx from "clsx";
import { useState } from "react";
import Input from "antd/es/input/Input";
import TutorChatBox from "./TutorChatBox";
import { useGetSingleBookingQuery } from "../../../hooks/api/Students/Bookings/useGetSingleBookingQuery";
import { useSendConversationsMutation } from "../../../hooks/api/Tutors/Conversation/useSendConversationsMutation";
import BookingDetails from "./BookingDetails";
import { useConfirmTutorBooking } from "../../../hooks/api/Tutors/Bookings/useConfirmTutorBooking";
import Loader from "../../../components/Loader";
import AvailabilityBadge from "../../Students/Tutors/Components/AvailabilityBadge";
import { MessageOutlined } from "@ant-design/icons";
import BookingDetailsTable from "./BookingTable";
import Moment from "react-moment";

type Props = {
  id: string;
  subject?: any;
  tutorShipType?: any;
  date?: any;
  tutor: any;
  timeSlots?: any;
  status?: "confirmed" | "pending" | "cancelled" | string;
  price?: any;
  duration: any;
  notes?: any;
  student?: any;
};

const STATUS_COLORS: Record<string, string> = {
  confirmed: "bg-green-100 text-green-700",
  pending: "bg-yellow-100 text-yellow-700",
  cancelled: "bg-red-100 text-red-700",
  default: "bg-gray-100 text-gray-700",
};

const TutorSessionCard = ({
  id,
  subject,
  tutorShipType,
  date,
  timeSlots,
  tutor,
  status = "default",
  student,
  duration,
  price,
  notes,
}: Props) => {
  const isSessionEnded = (date: string, duration: number) => {
    const sessionEndTime = new Date(date).getTime() + duration * 60000;
    return Date.now() >= sessionEndTime;
  };

  const hasSessionEnded = (startTime: string, duration: number): boolean => {
    const sessionEnd = new Date(startTime).getTime() + duration * 60000;
    return Date.now() >= sessionEnd;
  };

  const [chatVisible, setChatVisible] = useState(false);
  const [selectedTutor, setSelectedTutor] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<any>(null);

  const [visible, setVisible] = useState("");

  const [selectedId, setSelectedId] = useState("");

  const { mutateAsync: sendMessage, isLoading: isSendingMessage } =
    useSendConversationsMutation({
      id: selectedId,
      setMessageInput,
    });

  const handleChatClick = (id: string) => {
    setSelectedId(id);
    setChatVisible(true);
  };

  const handleConfirmClick = (record: any) => {
    setSelectedBooking(record);
    setIsModalVisible(true);
    setVisible("confirm");
  };

  const handleSendMessage = () => {
    sendMessage({ message: messageInput, sender: "Tutor", type: "text" });
  };

  const [trigger, setTrigger] = useState(false);

  const closeModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const { isLoading } = useConfirmTutorBooking({
    id: selectedBooking?.id ?? "",
    trigger: !!selectedBooking && trigger,
    closeModal: closeModal,
  });

  const { data: details, isLoading: isDetailsLoading } =
    useGetSingleBookingQuery({ id: selectedId ?? "" });

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedBooking(null);
    setVisible("");
  };

  const formatTimeRange = (timeSlot: any) => {
    const [hour, minute] = timeSlot.split(":").map(Number);
    const nextHour = (hour + 1) % 24;

    const formatTime = (h: number, m: number) => {
      const period = h >= 12 ? "pm" : "am";
      const formattedHour = h % 12 === 0 ? 12 : h % 12;
      return `${formattedHour}:${m.toString().padStart(2, "0")}${period}`;
    };

    return `${formatTime(hour, minute)} - ${formatTime(nextHour, minute)}`;
  };

  const isCurrentTimePastSlot = (timeSlots: string[]): boolean => {
    const currentTime = Date.now();

    // Parse the first time slot from the array
    const [hour, minute] = timeSlots[0].split(":").map(Number);
    const currentTimeSlot = new Date();
    currentTimeSlot.setHours(hour, minute, 0, 0);

    return currentTime >= currentTimeSlot.getTime();
  };
  return (
    <div className="relative p-6 rounded-lg border border-gray-200 bg-white shadow-sm">
      <div
        className={clsx(
          "absolute top-3 right-3 flex items-center gap-2 px-3 py-1 rounded-full text-[12px] font-semibold",
          STATUS_COLORS[status] || STATUS_COLORS.default
        )}
      >
        {status === "confirmed" && (
          <span className="h-2 w-2 bg-green-500 rounded-full animate-ping" />
        )}
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </div>
      <h3 className="text-lg W2STMedium capitalize md:mt-0 mt-[30px]">
        {subject} Session with{" "}
        <span className="text-purple-500">{tutor?.firstName}</span>
      </h3>
      <div className="flex md:flex-row flex-col md:gap-5 gap-2 mt-5">
        <span className="flex gap-1.5 items-center">
          <Calendar color="#344054" size={20} />
          <span className="text-gray-500">
            {new Date(date).toLocaleDateString("en-US", {
              weekday: "short",
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </span>
        </span>
        <span className="flex gap-1.5 items-center">
          <Clock2 color="#344054" size={20} />
          <span className="text-gray-500">{formatTimeRange(timeSlots[0])}</span>
          (
          <Moment fromNow interval={0} className="text-gray-500">
            {date}
          </Moment>
          )
        </span>
      </div>
      <AvailabilityBadge availabilityType={tutorShipType} />
      <Divider />
      <div className="flex gap-2 mt-4">
        {status === "pending" && (
          <Button
            onClick={() =>
              handleConfirmClick({
                id,
                subject,
                tutorShipType,
                date,
                timeSlots,
                tutor,
                price,
                notes,
                duration,
                status,
              })
            }
            type="primary"
          >
            Confirm Booking
          </Button>
        )}
        <Button
          type="primary"
          icon={<MessageOutlined />}
          disabled={status !== "confirmed"}
          onClick={() => {
            handleChatClick(id);
            setSelectedTutor(tutor?.firstName);
          }}
        >
          Chat Student
        </Button>
      </div>
      <Modal
        title="Booking Confirmation"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          visible === "confirm" && (
            <Button
              type="primary"
              key="confirm"
              disabled={isLoading || selectedBooking?.status === "confirmed"}
              onClick={() => setTrigger(true)}
            >
              Confirm Booking
            </Button>
          ),
        ].filter(Boolean)}
      >
        {selectedBooking && visible === "confirm" && (
          <BookingDetailsTable selectedBooking={selectedBooking} />
        )}
        {selectedBooking && visible === "more" && (
          <div>
            {isDetailsLoading && <Loader />}
            {!isDetailsLoading && details && (
              <div>
                <BookingDetails booking={details?.data} />
              </div>
            )}
          </div>
        )}
      </Modal>

      <Drawer
        title={`Chat with ${student?.firstName}`}
        placement="right"
        width={350}
        onClose={() => {
          setChatVisible(false);
          setSelectedTutor("");
        }}
        open={chatVisible}
      >
        <TutorChatBox id={selectedId} />
        <div className="mt-4 flex gap-2">
          <Input
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="Type a message..."
            disabled={isSendingMessage}
          />
          <Button
            type="primary"
            onClick={handleSendMessage}
            disabled={isSendingMessage}
            style={{ padding: "20px" }}
          >
            Send
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default TutorSessionCard;
