import React, { ReactNode } from "react";

const EmptyState = ({
  title,
  className,
  desc,
  children,
}: {
  title: string;
  className?: string;
  desc?: string;
  children?: ReactNode;
}) => {
  const empty = require("../assets/images/empty.png");
  return (
    <div
      className={`bg-white rounded-md py-[50px] mb-3 flex flex-col items-center justify-center mt-5 ${className}`}
    >
      <img src={empty} alt="" className="h-[80px] w-[80px] mb-3" />
      <h3 className="W2STBold">{title}</h3>
      <p className="text-center text-gray700 mt-1 mb-3">{desc}</p>
      {children}
      {/* <button className="rounded-md bg-primary50 text-primary700 px-[67px] py-[10px] font-medium">
        text
      </button> */}
    </div>
  );
};

export default EmptyState;
