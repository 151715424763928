import { Tag } from "antd";
import React from "react";

export interface TutorShipTypeTagProps {
  type: "Virtual" | "One-on-one" | "Both";
}

const TutorShipTypeTag: React.FC<TutorShipTypeTagProps> = ({ type }) => {
  const getColor = (type: string) => {
    switch (type) {
      case "Virtual":
        return "#4180F6";
      case "One-on-one":
        return "#B780F7";
      case "Both":
        return "blue";
      default:
        return "gray";
    }
  };

  return <Tag color={getColor(type)}>{type}</Tag>;
};

export default TutorShipTypeTag;
