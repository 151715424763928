import React, { useState } from "react";
import { Input, Button } from "antd";
import { useLevel2Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel2Onboarding";

// const { Option } = Select;

const LevelTwo = () => {
  type ProfileLinks = {
    facebook: string;
    linkedIn: string;
    x: string;
    personalWebsite: string;
  };

  const [payload, setPayload] = useState<{
    preferredLanguage: string;
    country: string;
    hourlyRate: string;
    profileLinks: ProfileLinks;
  }>({
    preferredLanguage: "English",
    country: "United States of America",
    hourlyRate: "20",
    profileLinks: {
      facebook: "",
      linkedIn: "",
      x: "",
      personalWebsite: "",
    },
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (["facebook", "linkedIn", "x", "personalWebsite"].includes(name)) {
      setPayload((prev) => ({
        ...prev,
        profileLinks: {
          ...prev.profileLinks,
          [name]: value,
        },
      }));
    } else if (name === "hourlyRate") {
      const validatedRate = Math.min(Number(value), 200);
      setPayload((prev) => ({
        ...prev,
        hourlyRate: validatedRate.toString(),
      }));
    } else {
      setPayload((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const { mutateAsync } = useLevel2Onboarding();

  const handleSubmit = () => {
    mutateAsync(payload);
  };

  const isSubmitDisabled =
    !payload.country || !payload.hourlyRate || !payload.preferredLanguage;

  return (
    <div className="w-full my-4">
      {/* <label htmlFor="hourlyRate" className="text-gray700 W2STMedium block">
        Hourly Rate
      </label>
      <Select
        size="large"
        onChange={(value) =>
          setPayload((prev) => ({ ...prev, hourlyRate: value }))
        }
        value={payload?.hourlyRate}
        className="w-full mt-3"
        placeholder="Select Option"
      >
        {Array.from({ length: 7 }, (_, index) => 5 * (index + 1)).map(
          (value) => (
            <Option key={value} value={value}>
              ${value}
            </Option>
          )
        )}
      </Select> */}

      <label className="text-gray700 W2STMedium mt-4 block">
        Profile Links (Optional)
      </label>
      <p className="text-gray500">
        Kindly note that the provided social networks would only be used for
        background checks.
      </p>

      {(
        [
          "facebook",
          "linkedIn",
          "x",
          "personalWebsite",
        ] as (keyof ProfileLinks)[]
      ).map((platform) => (
        <Input
          key={platform}
          name={platform}
          type="url"
          value={payload.profileLinks[platform]}
          onChange={handleChange}
          placeholder={`https://${platform}.com/username`}
          className="w-full mt-3"
          size="large"
        />
      ))}

      {/* Submit Button */}
      <Button
        type="primary"
        block
        size="large"
        className="mt-8"
        onClick={handleSubmit}
        disabled={isSubmitDisabled}
      >
        Continue
      </Button>
      <Button
        type="text"
        block
        size="large"
        className="mt-8"
        onClick={handleSubmit}
        disabled={isSubmitDisabled}
      >
        Skip
      </Button>

      {/* <p>{JSON.stringify(payload)}</p> */}
    </div>
  );
};

export default LevelTwo;
