import { useMutation } from "react-query";
import axios from "axios";
import { message } from "antd";

const baseURL = process.env.REACT_APP_BASE_URL;

export const useResetTutorPassword = () => {
  return useMutation(
    ({ email }: any) =>
      axios
        .post(`${baseURL}/tutors/reset-password`, { email: email })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message ||
            "Reset password failed. Please try again."
        );
      },
    }
  );
};
