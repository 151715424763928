import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

export const useToggleAvailability = () => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useMutation(
    () =>
      axiosInstance
        .patch(
          "tutors/toggle-availability",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        queryClient.invalidateQueries("gettutor");
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
