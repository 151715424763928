import React, { useState } from "react";
import { Button, Image, message, Modal, Select, Upload } from "antd";
import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { useLevel1Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel1Onboarding";
import { qualifications } from "../qualifications";
import { useGetSubjectsQuery } from "../../../../hooks/api/Admin/Courses/useGetSubjectsQuery";
import axios from "axios";
import { getToken } from "../../../../utils/getToken";

const LevelOne = () => {
  const [payload, setPayload] = useState({
    professionalTitle: "",
    profilePicture: "",
    areaOfExpertise: "",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setPayload({ ...payload, [name]: value });
  };

  const { Option } = Select;

  const { mutateAsync } = useLevel1Onboarding();
  const page = 1;
  const { data } = useGetSubjectsQuery({ page });

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload((prev) => ({ ...prev, profilePicture: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleFileChange = async (e: any) => {
    const selectedFile = e.target.files?.[0] || null;
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };

  const handleSubmit = () => {
    mutateAsync(payload);
  };
  const token = getToken();

  return (
    <div>
      <div className="w-full mt-4">
        {payload?.profilePicture ? (
          <div className="flex flex-col items-center gap-2">
            <Image
              src={payload.profilePicture}
              alt="Profile"
              height={140}
              style={{ borderRadius: "8px" }}
            />
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => setIsModalVisible(true)}
              className="mt-3"
            >
              Preview Image
            </Button>
          </div>
        ) : (
          <Upload
            accept="image/*"
            showUploadList={false}
            beforeUpload={(file) => {
              handleFileChange({ target: { files: [file] } });
              return false; // Prevent automatic upload
            }}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        )}{" "}
        <Modal
          title="Image Preview"
          open={isModalVisible}
          footer={null}
          onCancel={() => setIsModalVisible(false)}
        >
          <img
            src={payload?.profilePicture}
            alt="avatar preview"
            style={{ width: "100%" }}
          />
        </Modal>
        <p className="mt-3">Professional Title</p>
        <Select
          onChange={(value) =>
            handleChange({ target: { name: "professionalTitle", value } })
          }
          value={payload?.professionalTitle || undefined}
          placeholder="Select Option"
          className="w-full mt-2"
          size="large"
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {qualifications?.map(({ degree, professional_title }) => (
            <Option value={degree} key={degree}>
              {professional_title}
            </Option>
          ))}
        </Select>
        <p className="mt-3">Select area of expertise</p>
        <Select
          mode="multiple"
          maxCount={4}
          onChange={(value) =>
            handleChange({ target: { name: "areaOfExpertise", value } })
          }
          value={payload?.areaOfExpertise || undefined}
          placeholder="Select Option"
          className="w-full mt-2 capitalize"
          size="large"
        >
          <Option value="" disabled>
            Select option
          </Option>
          {data?.data?.docs?.map(({ subject }: { subject: string }) => (
            <Option key={subject} value={subject} className="capitalize">
              {subject}
            </Option>
          ))}
        </Select>
        <p className="mt-3 text-gray500">Select maximum of 4</p>
      </div>

      <div className="w-full my-4">
        <label htmlFor="areaOfExpertise" className="text-gray700"></label>
      </div>
      <button
        disabled={
          !payload?.areaOfExpertise ||
          !payload?.professionalTitle ||
          !payload?.profilePicture
        }
        className={`mt-8 w-full py-3 mb-4 bg-primary900 rounded-md text-white text-md float-right ${
          !payload?.areaOfExpertise ||
          !payload?.professionalTitle ||
          !payload?.profilePicture
            ? "opacity-50"
            : ""
        }`}
        onClick={handleSubmit}
      >
        Continue
      </button>
    </div>
  );
};

export default LevelOne;
