import React, { useState } from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { useGetBookingsAdmin } from "../../../hooks/api/Admin/Bookings/useGetBookingsAdmin";
import { useGetTutorsonAdminQuery } from "../../../hooks/api/Admin/Tutors/useGetTutorsonAdminQuery";
import Loader from "../../../components/Loader";
import { Checkbox, DatePicker, Input, Select, Table, Tag } from "antd";
import StatusTag from "../../../components/StatusTag";
import TutorShipTypeTag from "../../../components/TutorshipTypeTag";
import { RefreshCcw } from "lucide-react";
import dayjs, { Dayjs } from "dayjs";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import AdminBookingSessionCard from "./AdminBookingSessionCard";
import { duration } from "moment";

const AdminBookings = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const { data, isLoading: isLoadingBookings } = useGetBookingsAdmin({
    page,
    search,
    status,
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  });

  const { data: tutors, isLoading } = useGetTutorsonAdminQuery({
    search: search,
    page: page,
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const { Option } = Select;

  return (
    <AdminDashboardLayout>
      <div>
        <h3 className="W2STBold text-[20px]">
          Bookings ({data?.data?.meta?.totalDocs || 0})
        </h3>
        <div className="flex justify-end py-4 gap-4">
          <DatePicker.RangePicker
            className="w-[200px]"
            onChange={(
              dates: [Dayjs | null, Dayjs | null] | null,
              dateStrings: [string, string]
            ) => {
              if (dates && dates[0] && dates[1]) {
                setStartDate(dates[0]);
                setEndDate(dates[1]);
              } else {
                setStartDate(null);
                setEndDate(null);
              }
            }}
            value={startDate && endDate ? [startDate, endDate] : null}
          />
          <Select
            placeholder="Select Status"
            onChange={(value) => setStatus(value)}
            style={{ width: 200 }}
            size="large"
            value={status}
          >
            <Option value="">All Bookings</Option>
            <Option value="pending">Pending</Option>
            <Option value="cancelled">Cancelled</Option>
            <Option value="confirmed">Confirmed</Option>
            <Option value="completed">Completed</Option>
          </Select>
          <Input
            name="search"
            type="text"
            size="large"
            placeholder="Search subject"
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            className={"rounded-md border-[1px] border-[#00000033] w-[300px]"}
          />
          <button
            className="flex items-center gap-2 bg-gray-300 rounded-md px-3"
            onClick={() => {
              setStatus("");
              setSearch("");
              setStartDate(null);
              setEndDate(null);
            }}
          >
            <RefreshCcw size={15} />
            <span>Reset</span>
          </button>
        </div>
        {isLoading && !data && <Loader />}
        <div className="grid md:grid-cols-2 grid-cols-1 gap-[10px]">
          {!isLoading &&
            data &&
            data?.data?.docs &&
            data?.data?.docs.map(
              ({
                id,
                subject,
                tutor,
                timeSlots,
                date,
                status,
                price,
                duration,
                notes,
                tutorShipType,
                student,
                isPaidOut,
              }: any) => (
                <div key={id}>
                  <AdminBookingSessionCard
                    id={id}
                    subject={subject}
                    tutor={tutor}
                    duration={duration}
                    date={date}
                    timeSlots={timeSlots}
                    tutorShipType={tutorShipType}
                    status={status}
                    price={price}
                    notes={notes}
                    student={student}
                    isPaidOut={isPaidOut}
                  />
                </div>
              )
            )}
        </div>
        {/* {JSON.stringify(data?.data?.docs)} */}
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminBookings;
