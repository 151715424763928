import { message, Switch, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useGetTutorQuery } from "../../hooks/api/Tutors/Auth/useGetTutorQuery";
import { Copy, Info, Mail, Phone } from "lucide-react";
import { useToggleAvailability } from "../../hooks/api/Tutors/Availability/useToggleAvailability";

const ProfileCard = ({ hideAvailability }: { hideAvailability?: boolean }) => {
  const Twitter = require("../../assets/images/twitter.png");
  const LinkedIn = require("../../assets/images/linkedin.png");
  const Web = require("../../assets/images/web.png");
  const Facebook = require("../../assets/socials/fb.png");

  const location = useLocation();
  const { data } = useGetTutorQuery();
  const tutorData = data?.data || {};
  const profileLinks = tutorData.profileLinks || {};

  const handleCopyClick = async () => {
    if (!tutorData.id) return;
    try {
      await navigator.clipboard.writeText(
        `https://w2st.net/tutors/${tutorData.id}`
      );
      message.success("Link Copied");
    } catch (err) {
      console.error("Error copying to clipboard:", err);
    }
  };

  const { mutate, isLoading } = useToggleAvailability();
  const handleToggle = () => mutate();

  const hasLinks = Object.values(profileLinks).some((link) => link);

  return (
    <div className="p-6 bg-white rounded-lg mb-3">
      <div className="grid place-content-center">
        <img
          src={tutorData.profilePicture || ""}
          alt="tutor avatar"
          className="bg-gray200 rounded-full h-[180px] w-[180px] border-1 border"
        />
      </div>

      {!hideAvailability && tutorData.isAvailable !== undefined && (
        <div className="flex justify-center gap-2 items-center mt-3">
          <h3 className="W2STNormal flex items-center gap-2">
            <Tooltip title="Use this toggle to control your visibility to students.">
              <Info size={15} />
            </Tooltip>
            Availability
          </h3>
          <Switch
            loading={isLoading}
            checked={tutorData.isAvailable}
            onChange={handleToggle}
            checkedChildren="On"
            unCheckedChildren="Off"
          />
        </div>
      )}

      <div className="grid place-content-center mt-4">
        <span
          className={`flex items-center gap-2 p-2 rounded-full text-xs ${
            tutorData.isAvailable
              ? "text-indigo700 bg-indigo50"
              : "text-red-700 bg-red-200"
          }`}
        >
          <span
            className={`h-[8px] w-[8px] rounded-full ${
              tutorData.isAvailable ? "bg-indigo700" : "bg-red-700"
            }`}
          ></span>
          <span className="text-[12px]">
            {tutorData.isAvailable
              ? "Available for immediate booking"
              : "Unavailable for immediate booking"}
          </span>
        </span>
      </div>

      <div className="w-[50%] mx-auto flex justify-between mt-3">
        {hasLinks ? (
          <div className="flex justify-between w-full">
            {profileLinks.x && (
              <a href={profileLinks.x} target="_blank" rel="noreferrer">
                <img
                  src={Twitter}
                  alt="Twitter"
                  className="h-[30px] w-[30px]"
                />
              </a>
            )}
            {profileLinks.linkedIn && (
              <a href={profileLinks.linkedIn} target="_blank" rel="noreferrer">
                <img
                  src={LinkedIn}
                  alt="LinkedIn"
                  className="h-[30px] w-[30px]"
                />
              </a>
            )}
            {profileLinks.facebook && (
              <a href={profileLinks.facebook} target="_blank" rel="noreferrer">
                <img src={Facebook} alt="Facebook" className="h-4 w-4" />
              </a>
            )}
            {profileLinks.personalWebsite && (
              <a
                href={profileLinks.personalWebsite}
                target="_blank"
                rel="noreferrer"
              >
                <img src={Web} alt="Website" className="h-[30px] w-[30px]" />
              </a>
            )}
          </div>
        ) : (
          <p className="text-gray-500 text-sm text-center">
            No social media links
          </p>
        )}
      </div>

      <div className="flex items-center gap-2 text-gray700 text-sm mt-3">
        <Mail size={20} className="text-gray700" />
        <span>{tutorData.email || "N/A"}</span>
      </div>
      <div className="flex items-center gap-2 text-gray700 text-sm mt-3">
        <Phone size={20} className="text-gray700" />
        <span>{tutorData.phoneNumber || "N/A"}</span>
      </div>

      {location.pathname !== "/tutor/profile" && tutorData.id && (
        <div className="mt-3 border-[1px] border-gray300 rounded-md p-2 flex items-center justify-between gap-3">
          <Link
            to={`https://w2st.net/tutors/${tutorData.id}`}
            target="_blank"
            className="text-gray500 flex-1 overflow-x-scroll"
          >
            https://w2st.net/tutors/{tutorData.id}
          </Link>
          <div className="w-[20px] cursor-pointer" onClick={handleCopyClick}>
            <Copy />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
