import React, { ReactNode, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutSideLink from "../components/LayoutSideLink";
import withTutorAuth from "../hoc/withTutorAuth";
import WelcomeLoader from "../components/WelcomeLoader";
import { Input, message, Modal } from "antd";
import { useSendVerifyOTPMutation } from "../hooks/api/Tutors/Auth/useSendVerifyOTPMutation";
import { useVerifyOTPMutation } from "../hooks/api/Tutors/Auth/useVerifyOTPMutation";
// import { useGetTutorsQuery } from "../hooks/api/Students/Tutors/useGetTutorsQuery";
import { useGetTutorQuery } from "../hooks/api/Tutors/Auth/useGetTutorQuery";
import { useGetConnectedAccount } from "../hooks/api/Tutors/Stripe/useGetConnectedAccount";
import Button from "../components/Button";
import {
  Bell,
  Briefcase,
  Calendar,
  Clock,
  Clock2,
  Layers,
  Menu,
  MessageCircleQuestion,
  Settings,
  X,
} from "lucide-react";
import TutorLogoutButton from "./TutorLogoutButton";

const TutorDashboardLayout = ({ children }: { children: ReactNode }) => {
  const Logo = require("../assets/images/logo.png");
  const navigate = useNavigate();
  // const { data: tutor } = useGetTutorsQuery();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const [otp, setOTP] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [level, setLevel] = useState(0);

  const { mutateAsync, isLoading: isSendingOTP } = useSendVerifyOTPMutation();
  const { mutateAsync: verifyOTP, isLoading: isVerifying } =
    useVerifyOTPMutation(otp);

  const handleInitialize = () => {
    mutateAsync(); // Pass only `data` if that's the expected argument
    setLevel(1); // Execute on success
    // }  (error) {
    //   console.error("Error occurred:", error); // Handle errors if needed
    // }
  };

  const handleVerify = () => {
    verifyOTP();
  };

  const { data: me } = useGetTutorQuery();

  const {} = useGetConnectedAccount({
    trigger: me?.data?.connectedAccountId === "" ? true : false,
  });

  useEffect(() => {
    if (
      me?.data?.isEmailVerified === false &&
      me?.data?.onBoardingProgress >= 4
    ) {
      setVisibility(true);
    }
  }, [me?.data?.isEmailVerified, me?.data?.onBoardingProgress]);

  const getLinkClasses = (path: string) =>
    location.pathname === path
      ? "text-[#3F2ABA] font-semibold"
      : "text-gray-700 hover:text-gray-600";

  const handleLogout = () => {
    localStorage.removeItem("W2ST_TUTOR_TOKEN");
    localStorage.removeItem("hasSeenWelcomeLoader");
    message.success("Logout successful");
    navigate("/tutor/signin");
  };

  return (
    <>
      <div className="md:flex hidden flex-col max-h-dvh">
        {/* {me?.data?.isEmailVerified && me?.data?.isAccountVerified && (
          <div className="flex items-center justify-between p-3 rounded-md mb-3 bg-primary100">
            <span>
              <h3 className="text-[20px] W2STMedium">
                Link your account for payout{" "}
              </h3>
              <p className="max-w-[250px]">
                This is setup to process your payout after tutoring sessions{" "}
              </p>
            </span>
            <button
              onClick={() => setVerify(true)}
              className="text-center px-5 py-3 rounded-md text-white text-md bg-primary500"
            >
              Proceed
            </button>
          </div>
        )} */}
        <WelcomeLoader />
        <Modal
          width="400px"
          footer={null}
          closeIcon={false}
          open={
            me?.data?.isEmailVerified === false &&
            me?.data?.onBoardingProgress >= 4
          }
          onCancel={() => setVisibility(false)}
        >
          <div className="">
            <h3 className="text-[20px] W2STMedium">Email Verification</h3>
            <p className="text-gray-600 mb-6">
              Proceed to verify your email address. An OTP has been sent to your
              email for verification purposes.
            </p>

            {level === 0 ? (
              <div className="flex justify-center">
                <Button
                  label="Proceed"
                  isFormValid={true}
                  isLoading={isSendingOTP}
                  handleSubmit={() => handleInitialize()}
                />
              </div>
            ) : (
              <div>
                <p className="text-gray-700 font-medium mb-2">Email OTP</p>
                <Input
                  name="otp"
                  type="text"
                  maxLength={6}
                  placeholder="Enter OTP"
                  value={otp}
                  disabled={isVerifying}
                  className="w-full py-3 px-4 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                  onChange={(e) => setOTP(e.target.value.replace(/\D/g, ""))}
                />
                <div className="flex justify-center">
                  <Button
                    label="Proceed"
                    isFormValid={true}
                    isLoading={isVerifying}
                    handleSubmit={() => handleVerify()}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal>
        <div className="flex-none">
          <div className="flex justify-between items-center border-b-[1px] bg-bottom-[#E4E7EC] p-4 bg-white">
            <Link to="/">
              <img src={Logo} alt="W2ST Logo" className="logo h-[40px]" />
            </Link>
            <section className="flex gap-4 items-center">
              <Bell style={{ fontSize: "20px" }} />
              <TutorLogoutButton />
            </section>
          </div>
        </div>
        <div className="grow">
          <div className="flex gap-[20px] bg-[#F9FAFB]">
            <div className="w-2/12 border-r-[1px] bg-white p-4 md:block hidden">
              <section className="">
                <nav>
                  <ul>
                    <LayoutSideLink to="/tutor/overview" title="Overview">
                      <Layers style={{ fontSize: "20px" }} />
                    </LayoutSideLink>
                    <LayoutSideLink to="/tutor/bookings" title="Bookings">
                      <Clock2 style={{ fontSize: "20px" }} />
                    </LayoutSideLink>
                    <LayoutSideLink
                      to="/tutor/availability"
                      title="Availability"
                    >
                      <Calendar style={{ fontSize: "20px" }} />
                    </LayoutSideLink>
                    <LayoutSideLink to="/tutor/profile" title="Profile">
                      <Briefcase style={{ fontSize: "20px" }} />
                    </LayoutSideLink>
                  </ul>
                </nav>
                <span className="absolute bottom-0 left-0 border-t-[1px] border-[#E4E7EC] w-2/12 px-4 -ml-[3px]">
                  <ul>
                    <LayoutSideLink to="mailto:info@w2st.net" title="Support">
                      <MessageCircleQuestion style={{ fontSize: "20px" }} />
                    </LayoutSideLink>
                    <LayoutSideLink to="#" title="Setting">
                      <Settings style={{ fontSize: "20px" }} />
                    </LayoutSideLink>
                  </ul>
                </span>
              </section>
            </div>
            {/* <div className="w-10/12 p-4 bg-[#F9FAFB]"> */}
            <div className="md:w-10/12 w-full h-[90vh] md:pr-4 px-4 overflow-y-scroll scrollbar-none">
              <div style={{ overflowY: "scroll", paddingBottom: "100px" }}>
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden block flex-none bg-black h-[70px]">
          Lorem ipsum dolor sit amet consectetur adipisicing
        </div>
      </div>
      {/* <div className="md:hidden block">{children}</div> */}
      <div className="md:hidden flex flex-col h-[100vh] relative pb-[30px]">
        {/* Navbar */}
        <div className="flex-none border-b-[1px] border-b-gray-300">
          <div className="container mx-auto flex justify-between items-center p-4 bg-white">
            <span className="flex gap-3 items-center">
              <img src={Logo} alt="W2ST Logo" className="h-[40px]" />
            </span>
            <button onClick={() => setIsOpen(true)}>
              <Menu size={30} />
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="grow container mx-auto md:px-4 overflow-y-scroll bg-gray-50">
          <div>{children}</div>
        </div>

        <div
          className={`fixed inset-0 bg-white z-50 transform transition-transform duration-300 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex flex-col h-full relative">
            <div className="absolute bottom-10 px-3 w-full">
              <button
                className="bg-red-600 p-3 rounded-md text-white W2STMedium w-full"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
            <div className="flex justify-between items-center p-4 border-b">
              <span className="text-lg font-semibold">Menu</span>
              <button onClick={() => setIsOpen(false)}>
                <X size={30} />
              </button>
            </div>

            <nav className="flex flex-col gap-6 p-6 text-lg">
              <Link
                to="/tutor/overview"
                className={getLinkClasses("/tutor/overview")}
              >
                Overview
              </Link>
              <Link
                to="/tutor/bookings"
                className={getLinkClasses("/tutor/bookings")}
              >
                Bookings
              </Link>
              <Link
                to="/tutor/availability"
                className={getLinkClasses("/tutor/availability")}
              >
                Availability
              </Link>
              <Link
                to="/tutor/profile"
                className={getLinkClasses("/tutor/profile")}
              >
                Profile
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTutorAuth(TutorDashboardLayout);
