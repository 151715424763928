import { message } from "antd";
import { Copy, Mail, Phone } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import DisplaySocials from "../../../../components/DisplaySocials";

const ProfileCard = (data: any) => {
  const Twitter = require("../../../../assets/images/twitter.png");
  const LinkedIn = require("../../../../assets/images/linkedin.png");
  const Web = require("../../../../assets/images/web.png");
  const pfp = require("../../../../assets/images/pfp.jpg");

  const location = useLocation();

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://w2st.net/tutors/${data?.data?.id}`
      );
      message.success("Link Copied");
    } catch (err) {
      console.error("Error copying to clipboard:", err);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg mb-3">
      <div className="grid place-content-center">
        <img
          src={
            data?.data?.data.profilePicture
              ? data?.data?.data.profilePicture
              : pfp
          }
          alt=""
          className="rounded-full h-[180px] w-[180px] text-center"
        />
      </div>

      <div className="grid place-content-center mt-4">
        <span
          className={`flex items-center gap-2 p-2 rounded-full text-xs ${
            data?.data?.data?.isAvailable
              ? "text-indigo700 bg-indigo50"
              : "text-red-700 bg-red-200"
          }`}
        >
          <span
            className={`h-[8px] w-[8px] rounded-full ${
              data?.data?.data?.isAvailable ? "bg-indigo700" : "bg-red-700"
            }`}
          ></span>
          <span className="text-[12px]">
            {data?.data?.data?.isAvailable
              ? "Available for immediate booking"
              : "Unavailable for immediate booking"}
          </span>
        </span>
      </div>
      <DisplaySocials data={data} />

      <div className="flex items-center gap-2 text-gray700 text-sm mt-3">
        <Mail size={20} className="text-gray700" />
        <span>{data?.data?.data?.email}</span>
      </div>
      <div className="flex items-center gap-2 text-gray700 mt-3 text-sm">
        <Phone size={20} className="text-gray700" />
        <span>{data?.data?.data?.phoneNumber}</span>
      </div>
      {location.pathname === "/tutor/profile" ? (
        ""
      ) : (
        <div className="mt-3 border-[1px] border-gray300 rounded-md p-2 flex items-center justify-between gap-3">
          <Link
            to={`https://w2st.net/tutors/${data?.data?.data?.id}`}
            target="_blank"
            className="text-gray500 flex-1 overflow-x-scroll"
          >
            https://w2st.net/tutors/{data?.data?.data?.id}
          </Link>
          <div className="w-[20px] cursor-pointer" onClick={handleCopyClick}>
            <Copy size={20} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
