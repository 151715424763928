import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

interface Education {
  college: string;
  majors: string;
  degree: string;
  enrolled: string;
  graduated: string | null;
}

export const useAddEducation = () => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useMutation(
    (payload: Education) =>
      axiosInstance
        .patch("tutors/add-education", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        queryClient.invalidateQueries("gettutor");
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
