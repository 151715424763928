import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

export const useMarkAsCompletedMutation = () => {
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useMutation(
    async ({ id }: { id: string }) => {
      if (!id) message.error("ERROR");
      const res = await axiosInstance.patch(
        `bookings/${id}/mark-completed`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        message.success(data?.message);
        queryClient.invalidateQueries(["getBookingsQuery"]);
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Process Failed. Please try again."
        );
      },
    }
  );
};
