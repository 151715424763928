import React, { useState } from "react";
import { Select, Button } from "antd";
import { useLevel4Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel4Onboarding";

const { Option } = Select;

const LevelFour: React.FC = () => {
  const [payload, setPayload] = useState({
    availabilityType: "",
    isAvailable: true,
  });

  const handleAvailabilityChange = (value: string) => {
    setPayload((prev) => ({
      ...prev,
      availabilityType: value,
    }));
  };

  const { mutateAsync } = useLevel4Onboarding();

  const handleSubmit = async () => {
    try {
      await mutateAsync(payload);
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  return (
    <div className="mt-10">
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700 mb-3">
          Availability Type
        </label>
        <Select
          value={payload.availabilityType}
          onChange={handleAvailabilityChange}
          className="w-full"
          size="large"
        >
          <Option value="" disabled>
            Select option
          </Option>
          <Option value="Virtual">Virtual Tutoring</Option>
          <Option value="One-on-one">One-on-One Tutoring</Option>
          <Option value="Both">Both</Option>
        </Select>
        <Button
          type="primary"
          className="mt-8 w-full"
          size="large"
          disabled={!payload.availabilityType || !payload.isAvailable}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default LevelFour;
