import React, { useState } from "react";
import AuthLayout from "../../../../layout/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import { useLoginStudent } from "../../../../hooks/api/Students/Auth/useLoginStudent";
import Button from "../../../../components/Button";

const StudentSignin = () => {
  const [payload, setPayload] = useState({ email: "", password: "" });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const navigate = useNavigate();

  const { mutateAsync: signin, isLoading } = useLoginStudent();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await signin(payload);
      navigate("/student/overview");
    } catch (error) {
      console.error("Sign-in failed:", error);
    }
  };

  const isFormValid =
    payload.email.trim() !== "" && payload.password.trim() !== "";

  return (
    <AuthLayout
      heading="Find the Best Tutor."
      desc="Use our customized tutoring services to arrange in-person or online classes with the best tutors in the country."
    >
      <div className="md:w-8/12 w-11/12 mx-auto overflow-y-scroll">
        <div className="mx-auto">
          <h3 className="text-gray900 text-txs W2STMedium mb-3">Sign in</h3>
          <div className="w-full mb-5">
            <label htmlFor="email" className="text-gray700">
              Email
            </label>
            <input
              name="email"
              type="email"
              placeholder="Enter your email"
              className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
              value={payload.email}
              disabled={isLoading}
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <label htmlFor="password" className="text-gray700">
              Password
            </label>
            <div className="relative">
              <input
                name="password"
                type={passwordVisibility ? "text" : "password"}
                placeholder="Enter your password"
                className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
                value={payload.password}
                disabled={isLoading}
                onChange={handleChange}
              />
              <span
                className="absolute right-6 top-[18px] flex text-gray400 items-center text-sm cursor-pointer"
                onClick={() => setPasswordVisibility(!passwordVisibility)}
              >
                {passwordVisibility ? <OpenEyes /> : <CloseEyes />}
              </span>
            </div>
            <div className="text-right mt-2">
              <Link to="/student/reset" className="text-gray500">
                <span className="text-primary700 W2STMedium">
                  Forgot Password?
                </span>
              </Link>
            </div>
          </div>
          <Button
            label="Sign In"
            isFormValid={isFormValid}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
          />
          {/* <button
            className="block text-center mt-4 py-5 w-full mb-4 rounded-md text-white text-md bg-primary500"
            disabled={!isFormValid || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Submitting..." : "Sign In"}
          </button> */}
          <p className="mt-10 text-gray500">
            By continuing, you agree to the Terms of Use, Privacy Policy, and
            Community Standards of Way2sucesstutors.
          </p>
          <div className="text-center mt-10">
            <Link to="/student/signup" className="text-gray500">
              Do not have an account?{" "}
              <span className="text-primary700 W2STMedium">Signup</span>
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default StudentSignin;

export const OpenEyes = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  );
};

export const CloseEyes = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  );
};
