import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import StatusTag from "../../../components/StatusTag";
import TutorShipTypeTag from "../../../components/TutorshipTypeTag";
import Moment from "react-moment";

interface Booking {
  key: string;
  label: string;
  value: React.ReactNode;
}

const BookingDetailsTable: React.FC<{ selectedBooking: any }> = ({
  selectedBooking,
}) => {
  const columns: ColumnsType<Booking> = [
    { title: "Field", dataIndex: "label", key: "label" },
    { title: "Details", dataIndex: "value", key: "value" },
  ];

  const data: Booking[] = [
    { key: "subject", label: "Subject", value: selectedBooking.subject },
    {
      key: "tutorshipType",
      label: "Tutorship Type",
      value: <TutorShipTypeTag type={selectedBooking.tutorShipType} />,
    },
    { key: "bookingId", label: "Booking ID", value: selectedBooking.id },
    {
      key: "date",
      label: "Date",
      value: (
        <>
          <Moment format="DD/MM/YYYY">{selectedBooking.date}</Moment> -{""}
          <Moment format="h:mm A">{selectedBooking.date}</Moment>
          <br />
          <Moment fromNow className="text-gray-500">
            {selectedBooking.date}
          </Moment>
        </>
      ),
    },
    {
      key: "timeSlots",
      label: "Time Slots",
      value: selectedBooking.timeSlots.join(", "),
    },
    {
      key: "status",
      label: "Status",
      value: <StatusTag status={selectedBooking.status} />,
    },
    {
      key: "price",
      label: "Price",
      value: (
        <span className="text-green-600 font-semibold">
          ${selectedBooking.price}
        </span>
      ),
    },
    {
      key: "duration",
      label: "Duration",
      value: `${selectedBooking.duration} mins`,
    },
    { key: "notes", label: "Notes", value: selectedBooking.notes || "N/A" },
  ];

  return (
    <Table
      columns={columns}
      rowClassName="capitalize"
      dataSource={data}
      pagination={false}
      bordered
    />
  );
};

export default BookingDetailsTable;
